import React from 'react';
import { Link } from 'react-router-dom';
import { apiURL } from '../../../utils/config';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import './styles.scss';

const DataMatchingNoFiles = () => {
  const logo = useSelector((state) => state.config.config?.images?.logo_1);
  const loading = useSelector((state) => state.files.postLoading);

  return (
    <>
      {loading ? (
        <div className={loading ? 'main__spinner' : ''}>
          <Spin spinning size="large" />
        </div>
      ) : (
        <div className="no-files">
          <div className="no-files__body">
            <img src={apiURL + logo} alt="" />
            <h1>Please Upload Your Files !</h1>
            <Link to="/dashboard">Upload Files?</Link>
          </div>
        </div>
      )}
    </>
  );
};

export default DataMatchingNoFiles;
