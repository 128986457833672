import React, { useEffect, useState } from 'react';
import DollarIcon from '../../assets/credits/dollar.svg';
import PriceCard from '../../Components/common/PriceCard/PriceCard';
import Stamp from '../../assets/add-more-credits/stamp.png';
import Icon from '../../assets/add-more-credits/icon.svg';
import Lock from '../../assets/add-more-credits/lock.svg';
import Phone from '../../assets/add-more-credits/phone.svg';
import StripeModal from '../../Components/common/StripeModal/StripeModal';
import { getPrices } from '../../store/actions/paymentActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import CallendlyList from '../../Components/common/Callendly/CallendlyList';
import './styles.scss';

const AddMoreCredits = () => {
  const dispatch = useDispatch();
  const prices = useSelector((state) => state?.payment.prices);
  const loading = useSelector((state) => state?.payment.loading);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalSkips, setTotalSkips] = useState(0);
  const [cart, setCart] = useState([]);
  const [defaultCart, setDefaultCart] = useState([]);

  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  const checkoutHandler = () => {
    if (cart.length) setVisible(true);
  };

  return (
    <div className="credits-page">
      {!loading ? (
        <div className="credits-page__body">
          <div className="credits-page__body-item">
            <h1>
              Choose the best package <img src={DollarIcon} alt="dollar" />
            </h1>
            <p className="credits-page__sub-title">Pricing made simple & competitive to save you money!</p>
            <div className="credits-page__cards">
              {prices.map(({ id, price, per_hit, skips, type, discount_id }, index) => (
                <PriceCard
                  cartActive={cart.length}
                  discount_id={discount_id}
                  id={id}
                  key={index}
                  price={price}
                  perHit={per_hit}
                  skips={skips}
                  type={type}
                  setTotalOrder={setTotalOrder}
                  setTotalSkips={setTotalSkips}
                  setArray={setCart}
                  setDefaultCart={setDefaultCart}
                />
              ))}
            </div>
            <div className="substrate">
              <p>THE MORE YOU BUY, THE CHEAPER IT GETS.</p>
            </div>
            <div className="credits-page__description">
              <p>Are your searches normally much higher in volume? If you’re purchasing more than 100,000 skips, we have enterprise pricing just for you!</p>
            </div>

            <div className="landing__price-section-footer">
              <div className="landing__price-section-footer-total">
                <p>{totalSkips} Skips</p>
                <p>Total ${totalOrder}.00</p>
              </div>
              <p className="landing__price-section-footer-checkout" onClick={checkoutHandler}>
                Go To Checkout
              </p>
            </div>
          </div>

          <div className="credits-page__body-item">
            <div className="enterprise_card">
              <img className="icon" src={Icon} alt="icon" />
              <h3 className="enterprise_card-title">
                ENTERPRISE
                <p className="m-b-10">Pricing</p>
                <span>Pricing For High-Volume Searches</span>
              </h3>

              <div className="enterprise_card-skips">
                <h3>100,000 + </h3>
                <p>Skips</p>
              </div>

              <div className="enterprise__body">
                <div className="enterprise__body-inner">
                  <img src={Lock} alt="lock" />
                  <p>Schedule a call for enterprise</p>
                  <button onClick={() => setShow(true)}>
                    Schedule now
                    <img src={Phone} alt="Phone" />
                  </button>
                  <span>Never too late to save money</span>
                </div>
              </div>
            </div>

            <div className="enterprise__footer">
              <img src={Stamp} alt="stamp" />
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-container">
          <Spin spinning={loading} size={'large'} className="m-auto" />
        </div>
      )}
      <StripeModal show={visible} setShow={setVisible} cart={cart} setCart={setCart} defaultCart={defaultCart} setDefaultCart={setDefaultCart} />
      <CallendlyList show={show} setShow={setShow} />
    </div>
  );
};

export default AddMoreCredits;
