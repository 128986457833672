import { useMemo } from 'react'

import { ITEMS_PER_PAGE, SIBLING_COUNT } from '../constants';
import { makeRange } from '../functions';

export const DOTS = '...';

export const usePagination = ({totalCount, currentPageIndex, itemsPerPage = ITEMS_PER_PAGE}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / itemsPerPage)
    const totalPageNumbers = SIBLING_COUNT + 5

    if (totalPageNumbers >= totalPageCount) {
      return makeRange(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPageIndex - SIBLING_COUNT, 1)
    const rightSiblingIndex = Math.min(
      currentPageIndex + SIBLING_COUNT,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > SIBLING_COUNT + 1
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - SIBLING_COUNT

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 4 + SIBLING_COUNT * SIBLING_COUNT
      let leftRange = makeRange(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 4 + SIBLING_COUNT * SIBLING_COUNT
      let rightRange = makeRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      )
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = makeRange(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
  }, [totalCount, currentPageIndex, itemsPerPage])

  return paginationRange
}
