import React, { useContext } from 'react';
import { NavLink, Route, useRouteMatch } from 'react-router-dom';
import { SUPER_ADMIN_ROUTE_PATHS } from '../../utils/constants';
import ListWL from '../../Components/admin/ListWl/ListWL';
import CreateWl from '../../Components/admin/CreateWL/CreateWL';
import EditWl from '../../Components/admin/EditWL/EditWl';
import RoleNavigation from '../../Components/admin/Navigation/RoleNavigation';
import SummarySuperAdmin from '../../Components/admin/SummarySuperAdmin/SummarySuperAdmin';
import { AppContext } from '../../appContext';

const SuperAdmin = () => {
  const { url } = useRouteMatch();
  const { isUserSuperAdmin } = useContext(AppContext);
  return (
    <section className="admin-panel">
      <RoleNavigation />

      <div className="admin-panel__main-container">
        <>
          {isUserSuperAdmin && (
            <nav className="admin-panel__navigation">
              {SUPER_ADMIN_ROUTE_PATHS.map((route, index) => {
                return (
                  <NavLink
                    className={`admin-panel__navigation-link`}
                    activeClassName="admin-panel__navigation-link--active"
                    exact={route.exact}
                    to={`${url}${route.url}`}
                    style={{ zIndex: SUPER_ADMIN_ROUTE_PATHS.length - index }}
                    key={index}
                  >
                    {route.label}
                  </NavLink>
                );
              })}
            </nav>
          )}
          <div className="admin-panel__content-container">
            <div className="white-label">
              <div className="white-label__content">
                <Route path="/super-admin" exact component={ListWL} />
                {isUserSuperAdmin && (
                  <>
                    <Route exact path="/super-admin/new" component={CreateWl} />
                    <Route exact path="/super-admin/white-label/edit/:id" component={EditWl} />
                    <Route exact path="/super-admin/summary" component={SummarySuperAdmin} />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    </section>
  );
};

export default SuperAdmin;
