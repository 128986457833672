import React, { useState } from 'react';
import { Modal, Select, Spin } from 'antd';
import AccountManagmentField from '../../../Pages/AccountManagement/Components/Field/AccountManagmentField';
import { postInvites } from '../../../store/actions/invitesActions';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../App';
import './styles.scss';

const InviteModal = ({ show, setShow, id ,setPaginationPage}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.invites.loading);

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');

  const inviteHandler = async () => {
    if (email.trim().length < 5 || !email.includes('@')) {
      showNotification('error', 'Email Must contain "@" and be at least 5 characters', 'Error');
    } else if (!role) {
      showNotification('error', 'You must choose a role', 'Error');
    } else {
      const response = await dispatch(postInvites(id, { email, role }));
      if (response) {
        setShow(false);
        setEmail('');
        setPaginationPage(1)
      }
    }
  };

  return (
    <Modal visible={show} onCancel={() => setShow(false)} footer={null} title="Invite New Employee" className="invite-modal">
      <Spin spinning={loading} size="large">
        <AccountManagmentField placeholder="Email" width="100%" value={email} setValue={setEmail} />
        <Select style={{ width: '100%' }} value={role} onChange={(value) => setRole(value)}>
          <Select.Option value={'admin'} key="ROLE_ADMIN">
            Admin
          </Select.Option>
          <Select.Option value={'basic'} key="ROLE_BASIC_ADMIN">
            Basic
          </Select.Option>
        </Select>
        <div className="invite-modal__buttons">
          <button onClick={() => setShow(false)}>Cancel</button>
          <button onClick={inviteHandler}>Submit</button>
        </div>
      </Spin>
    </Modal>
  );
};

export default InviteModal;
