import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import { BgColorsOutlined } from '@ant-design/icons';
import rgbHex from 'rgb-hex';
import hexRgb from 'hex-rgb';
import Helper from '../Helper/Helper';
import './styles.scss';

const ColorPicker = ({ color = '#000', setColor, title, disabled, text, images }) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShow(false));
  const colorLocal = hexRgb(color);

  return (
    <div className="color-picker__inner">
      <div className="color-picker" style={{ background: color }} onClick={() => !disabled && setShow(true)}>
        <h2>{title}</h2>
        <>
          {show && (
            <div ref={ref} className="color-picker-item">
              <SketchPicker
                color={{
                  r: colorLocal.red,
                  g: colorLocal.green,
                  b: colorLocal.blue,
                  a: colorLocal.alpha,
                }}
                onChangeComplete={(color) => setColor('#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))}
                width={220}
              />
            </div>
          )}
          <button onClick={() => setShow(!show)} className={` color-picker-btn`} disabled={disabled}>
            <BgColorsOutlined />
          </button>
        </>
      </div>
      <Helper text={text} title={title} tooltipWidth="100%" images={images} />
    </div>
  );
};

export default ColorPicker;
