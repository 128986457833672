import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../utils/hooks/useOnClickOutside';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import './styles.scss';

const DiscountHelper = ({ cards }) => {
  const [active, setActive] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setActive(false));

  return (
    <>
      <span className="discount-helper" onClick={() => setActive(!active)}>
        <QuestionCircleOutlined className="discount-helper__icon" />
      </span>

      {active && (
        <div className="discount-helper__modal" ref={ref}>
          <div className="discount-helper-cards">
            {cards.map((card) => (
              <div key={card.id} className="discount-helper-card">
                <h4>Skips: {card.skips} </h4>
                <h4>Discount percent: {card.discount_percent}%</h4>
              </div>
            ))}
          </div>

          <button className="discount-helper-button" onClick={() => setActive(false)}>
            <CloseOutlined />
          </button>
        </div>
      )}
    </>
  );
};

export default DiscountHelper;
