import React from 'react';

const ArrowRight = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width={30} height={10} viewBox="0 0 20.01 11.21" fill="var(--gradient-2)">
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <path className="cls-1" d="M18.89,4.48H1.09a1.13,1.13,0,0,0,0,2.25h17.8a1.13,1.13,0,0,0,0-2.25Z" />
            <path
              className="cls-1"
              d="M18.09,4.81,14.16,8.74l-.55.56a1.13,1.13,0,0,0,0,1.59,1.15,1.15,0,0,0,1.59,0L19.12,7l.56-.56a1.13,1.13,0,0,0,0-1.59,1.15,1.15,0,0,0-1.59,0Z"
            />
            <path className="cls-1" d="M19.68,4.81,15.76.88,15.2.32a1.15,1.15,0,0,0-1.59,0,1.14,1.14,0,0,0,0,1.6l3.92,3.92.56.56a1.12,1.12,0,0,0,1.59-1.59Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ArrowRight;
