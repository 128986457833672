import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSkiptraceStatistics } from '../../../../store/actions/whiteLabelActions';
import Navigation from '../Navigation/Navigation';
import DatePickers from '../DatePickers/DatePickers';
import { Spin, Table } from 'antd';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { AppContext } from '../../../../appContext';
import { PaginationPanelComponent } from '../../../common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../../utils/constants';
import { downloadOriginal, downloadUpdated } from '../../../../store/actions/fileActions';
import DatePickerTable from '../../DatePickerTable/DatePickerTable';
import _ from 'lodash';
import NoResult from '../../../common/NoResult/NoResult';

const Skiptrace = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const skiptrace = useSelector((state) => state.whiteLabel.skiptrace);
  const pages = useSelector((state) => state.whiteLabel.skiptrace_pages);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const width = useResizeWindows();
  const [startDate, setStartDate] = useState('2022-08-01');
  const [endDate, setEndDate] = useState('2022-10-01');
  const [page, setPage] = useState(1);
  const { collapsed, isUserSuperAdmin } = useContext(AppContext);

  const columns = useMemo(() => {
    const column = [
      {
        title: 'Searched File Name',
        width: 200,
        dataIndex: 'searched_file_name',
        key: 'searched_file_name',
        fixed: 'left',
      },
      {
        title: 'Sheet Name',
        width: 150,
        dataIndex: 'sheet_name',
        key: 'sheet_name',
        fixed: 'left',
      },
      {
        title: 'Type of Search',
        width: 120,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Number of Rows in File',
        width: 150,
        dataIndex: 'total_records',
        key: 'total_records',
      },
      {
        title: 'Total Hits',
        width: 100,
        dataIndex: 'total_hits',
        key: 'total_hits',
      },
      {
        title: 'Hit Percentage',
        width: 100,
        dataIndex: 'hit_percentage',
        key: 'hit_percentage',
      },
      {
        title: 'Skips Prior to Search',
        width: 120,
        dataIndex: 'skips_prior_search',
        key: 'skips_prior_search',
      },
      {
        title: 'Skips Client Was Charged',
        width: 100,
        dataIndex: 'skips_client_was_charged',
        key: 'skips_client_was_charged',
      },
      {
        title: 'Skips Remaining After Search',
        width: 150,
        dataIndex: 'skips_remaining_after_search',
        key: 'skips_remaining_after_search',
      },
      {
        title: 'How many seconds did the search take?',
        width: 100,
        dataIndex: 'search_time',
        key: 'search_time',
      },
    ];

    if (isUserSuperAdmin) {
      const adminColumn = [
        {
          title: 'Skiptrace Original File Download Link',
          width: 150,
          dataIndex: 'original',
          key: 'original',
          render: (_, record) => (
            <>
              {isUserSuperAdmin && (
                <button
                  className="white-label-table__csv"
                  style={{ height: '25px' }}
                  onClick={() =>
                    dispatch(
                      downloadOriginal(
                        {
                          file_id: record.file_id,
                          user_id: record.user_id,
                          type: record.type,
                        },
                        record.searched_file_name
                      )
                    )
                  }
                >
                  Download
                </button>
              )}
            </>
          ),
        },
        {
          title: 'Skiptrace Results File Download Link',
          width: 150,
          dataIndex: 'results',
          key: 'results',
          render: (_, record) => (
            <>
              {isUserSuperAdmin && (
                <button
                  className="white-label-table__csv"
                  style={{ height: '25px' }}
                  onClick={() => dispatch(downloadUpdated({ file_id: record.file_id, user_id: record.user_id, type: record.type }, record.searched_file_name))}
                >
                  Download
                </button>
              )}
            </>
          ),
        },
        {
          title: 'Update expiration date',
          width: 200,
          dataIndex: 'date',
          key: 'date',
          render: (_, record) => <DatePickerTable data={record} />,
        },
      ];
      adminColumn.forEach((item) => {
        column.push(item);
      });
    }

    return column;
  }, [isUserSuperAdmin, dispatch]);

  const startDateHandler = (date, dateString) => {
    setStartDate(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setEndDate(dateString);
  };
  useEffect(() => {
    dispatch(getSkiptraceStatistics(id, startDate, endDate, false, page));
  }, [dispatch, startDate, endDate, id, page]);

  const tableWidth = useMemo(() => {
    return width < 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 420}px`;
  }, [width, collapsed]);

  return (
    <Spin spinning={loading}>
      <div className="white-label-table">
        <Navigation id={id} />
        {!loading && (
          <>
            {_.isEmpty(skiptrace) ? (
              <NoResult text="Skiptrace history is empty" />
            ) : (
              <>
                <DatePickers
                  startDateHandler={startDateHandler}
                  endDateHandler={endDateHandler}
                  request={getSkiptraceStatistics}
                  id={id}
                  startDate={startDate}
                  endDate={endDate}
                  dispatch={dispatch}
                />

                <Table
                  loading={loading}
                  pagination={false}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: '380px',
                    borderTop: '1px solid var(--main-color)',
                  }}
                  columns={columns}
                  dataSource={skiptrace}
                  scroll={{
                    x: '1000px',
                    y: '350px',
                  }}
                />
              </>
            )}
          </>
        )}

        <div className="m-t-auto m-l-auto m-r-10">
          <PaginationPanelComponent pagintationPage={page} setPaginationPage={setPage} totalCount={pages * ITEMS_PER_PAGE} itemsPerPage={ITEMS_PER_PAGE} />
        </div>
      </div>
    </Spin>
  );
};

export default Skiptrace;
