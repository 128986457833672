import React from 'react';

export const HighLight = (props) => {
  const { filter, str, type } = props;
  if (!filter) return str;
  const regexp = new RegExp(filter, 'ig');
  const matchValue = str.match(regexp);
  if (matchValue) {
    return str.split(regexp).map((s, index, array) => {
      if (index < array.length - 1) {
        const c = matchValue.shift();
        return (
          <>
            {s}
            <span style={{ background: type ? 'var(--main-color)' : 'rgba(204,204,204,0.8)', color: 'white' }}>{c}</span>
          </>
        );
      }
      return s;
    });
  } else return str;
};
