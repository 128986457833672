import { Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../App';
import { editEmail } from '../../../store/actions/userActions';
import TextField from '../TextField/TextField';
import './styles.scss';

const ChangeEmailModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile);
  const [firstPassword, setFirstPassword] = useState('');
  const [editEmailData, setEditEmailData] = useState(profile ? profile.email : '');

  const changeEmail = async () => {
    const response = await dispatch(editEmail({ email: editEmailData, password: firstPassword }));
    if (response) {
      setFirstPassword('');
      setShow(false);
    } else {
      showNotification('error', 'Passwords do not match', 'Error');
    }
  };

  const closeModal = () => {
    setShow(false);
    setEditEmailData(profile.email);
    setFirstPassword('');
  };

  return (
    <Modal visible={show} footer={null} width="600px" onCancel={closeModal} destroyOnClose>
      <div className="edit-email__container">
        <h2 className="change_email-title">Change Email</h2>
        <input disabled type="text" className="edit__field edit__email" value={editEmailData} onChange={(e) => setEditEmailData(e.target.value)} placeholder="Email" />
        <TextField type="password" placeholder="Password" value={firstPassword} setState={setFirstPassword} />

        <div className="m-t-30 flex flex-align-items-center flex-justify-content-around">
          <button style={{ width: '40%' }} className="m-r-15" onClick={closeModal}>
            Cancel
          </button>
          <button style={{ width: '40%' }} className="m-r-15" onClick={changeEmail}>
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeEmailModal;
