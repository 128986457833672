import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import PasswordField from './PasswordField';
import { useDispatch, useSelector } from 'react-redux';
import { editPassword } from '../../../store/actions/userActions';
import './styles.scss';

const ChangePasswordModal = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.user?.loading);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);

  const changePassword = () => {
    if (password.length > 5 && newPassword > 5 && confirmPassword > 5 && newPassword === confirmPassword) {
      setError(false);
      dispatch(
        editPassword({
          password: { first: newPassword, second: confirmPassword },
          oldPassword: password,
        })
      );
    } else setError(true);
  };

  return (
    <Modal bodyStyle={{ padding: '22px 44px' }} visible={show} onCancel={() => setShow(false)} footer={null} width={600}>
      <Spin spinning={loading} size={'large'}>
        <div className="edit-password">
          <h3 className="change-password__title">Change Password</h3>
          <PasswordField error={error} value={password} setValue={setPassword} placeholder="Old Password" className="m-t-50" />
          <PasswordField error={error} value={newPassword} setValue={setNewPassword} placeholder="New Password" />
          <PasswordField error={error} value={confirmPassword} setValue={setConfirmPassword} placeholder="Confirm Password" />
          <div className="button-block">
            <button onClick={() => setShow(false)}>Back</button>
            <button onClick={changePassword}>Save</button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ChangePasswordModal;
