import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';
import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  BAN_CURRENT_USER_REQUEST,
  BAN_CURRENT_USER_SUCCESS,
  CREATE_FAQS_FAILURE,
  CREATE_FAQS_REQUEST,
  CREATE_FAQS_SUCCESS,
  DELETE_FAQS_FAILURE,
  DELETE_FAQS_REQUEST,
  DELETE_FAQS_SUCCESS,
  EDIT_FAQS_FAILURE,
  EDIT_FAQS_REQUEST,
  EDIT_FAQS_SUCCESS,
  GET_ADMIN_ALL_FAQS_FAILURE,
  GET_ADMIN_ALL_FAQS_REQUEST,
  GET_ADMIN_ALL_FAQS_SUCCESS,
  RESET_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  BAN_CURRENT_USER_FAILURE,
  UNBAN_CURRENT_USER_REQUEST,
  UNBAN_CURRENT_USER_SUCCESS,
  UNBAN_CURRENT_USER_FAILURE,
  EDIT_PRICE_CARD_REQUEST,
  EDIT_PRICE_CARD_SUCCESS,
  EDIT_PRICE_CARD_FAILURE,
  GET_ACTIVE_DISCOUNTS_REQUEST,
  GET_ACTIVE_DISCOUNTS_SUCCESS,
  GET_ACTIVE_DISCOUNTS_FAILURE,
  GET_INACTIVE_DISCOUNTS_REQUEST,
  GET_INACTIVE_DISCOUNTS_SUCCESS,
  GET_INACTIVE_DISCOUNTS_FAILURE,
  GET_SINGLE_DISCOUNTS_REQUEST,
  GET_SINGLE_DISCOUNTS_SUCCESS,
  GET_SINGLE_DISCOUNTS_FAILURE,
  EDIT_DISCOUNTS_REQUEST,
  EDIT_DISCOUNTS_SUCCESS,
  EDIT_DISCOUNTS_FAILURE,
  GET_USERS_DISCOUNT_REQUEST,
  GET_USERS_DISCOUNT_SUCCESS,
  GET_USERS_DISCOUNT_FAILURE,
  GET_WL_PRICES_FAILURE,
  GET_WL_PRICES_REQUEST,
  GET_WL_PRICES_SUCCESS,
  GET_BUSINESS_FILES_REQUEST,
  GET_BUSINESS_FILES_SUCCESS,
  GET_BUSINESS_FILES_FAILURE,
  GET_PERSON_FILES_REQUEST,
  GET_PERSON_FILES_SUCCESS,
  GET_PERSON_FILES_FAILURE,
} from '../actionsTypes';

const getAllUsersRequest = () => ({ type: GET_ALL_USERS_REQUEST });
const getAllUsersSuccess = (users) => ({ type: GET_ALL_USERS_SUCCESS, users });
const getAllUsersFailure = (error) => ({ type: GET_ALL_USERS_FAILURE, error });

const banCurrentUserRequest = () => ({ type: BAN_CURRENT_USER_REQUEST });
const banCurrentUserSuccess = (user) => ({ type: BAN_CURRENT_USER_SUCCESS, user });
const banCurrentUserFailure = (error) => ({ type: BAN_CURRENT_USER_FAILURE, error });

const unbanCurrentUserRequest = () => ({ type: UNBAN_CURRENT_USER_REQUEST });
const unbanCurrentUserSuccess = (user) => ({ type: UNBAN_CURRENT_USER_SUCCESS, user });
const unbanCurrentUserFailure = (error) => ({ type: UNBAN_CURRENT_USER_FAILURE, error });

const resetCurrentUserRequest = () => ({ type: RESET_USER_PASSWORD_REQUEST });
const resetCurrentUserSuccess = (user) => ({ type: RESET_USER_PASSWORD_SUCCESS, user });
const resetCurrentUserFailure = (error) => ({ type: RESET_USER_PASSWORD_FAILURE, error });

const getUserRequest = () => ({ type: GET_USER_REQUEST });
const getUserSuccess = (user) => ({ type: GET_USER_SUCCESS, user });
const getUserFailure = (error) => ({ type: GET_USER_FAILURE, error });
const getAllFaqsRequest = () => ({ type: GET_ADMIN_ALL_FAQS_REQUEST });
const getAllFaqsSuccess = (faqs, pages) => ({ type: GET_ADMIN_ALL_FAQS_SUCCESS, faqs, pages });
const getAllFaqsFailure = (error) => ({ type: GET_ADMIN_ALL_FAQS_FAILURE, error });

const createFaqsRequest = () => ({ type: CREATE_FAQS_REQUEST });
const createFaqsSuccess = () => ({ type: CREATE_FAQS_SUCCESS });
const createFaqsFailure = (error) => ({ type: CREATE_FAQS_FAILURE, error });

const editFaqsRequest = () => ({ type: EDIT_FAQS_REQUEST });
const editFaqsSuccess = () => ({ type: EDIT_FAQS_SUCCESS });
const editFaqsFailure = (error) => ({ type: EDIT_FAQS_FAILURE, error });

const deleteFaqsRequest = () => ({ type: DELETE_FAQS_REQUEST });
const deleteFaqsSuccess = () => ({ type: DELETE_FAQS_SUCCESS });
const deleteFaqsFailure = (error) => ({ type: DELETE_FAQS_FAILURE, error });

const editPriceCardRequest = () => ({ type: EDIT_PRICE_CARD_REQUEST });
const editPriceCardSuccess = () => ({ type: EDIT_PRICE_CARD_SUCCESS });
const editPriceCardFailure = (error) => ({ type: EDIT_PRICE_CARD_FAILURE, error });

const getActiveDiscountsRequest = () => ({ type: GET_ACTIVE_DISCOUNTS_REQUEST });
const getActiveDiscountsSuccess = (activeDiscounts, activeDiscountsPages) => ({ type: GET_ACTIVE_DISCOUNTS_SUCCESS, activeDiscounts, activeDiscountsPages });
const getActiveDiscountsFailure = (error) => ({ type: GET_ACTIVE_DISCOUNTS_FAILURE, error });

const getInactiveDiscountsRequest = () => ({ type: GET_INACTIVE_DISCOUNTS_REQUEST });
const getInactiveDiscountsSuccess = (inactiveDiscounts, inactiveDiscountsPages) => ({
  type: GET_INACTIVE_DISCOUNTS_SUCCESS,
  inactiveDiscounts,
  inactiveDiscountsPages,
});
const getInactiveDiscountsFailure = (error) => ({ type: GET_INACTIVE_DISCOUNTS_FAILURE, error });

const getSingleDiscountsRequest = () => ({ type: GET_SINGLE_DISCOUNTS_REQUEST });
const getSingleDiscountsSuccess = (singleDiscount) => ({ type: GET_SINGLE_DISCOUNTS_SUCCESS, singleDiscount });
const getSingleDiscountsFailure = (error) => ({ type: GET_SINGLE_DISCOUNTS_FAILURE, error });

const editDiscountsRequest = () => ({ type: EDIT_DISCOUNTS_REQUEST });
const editDiscountsSuccess = () => ({ type: EDIT_DISCOUNTS_SUCCESS });
const editDiscountsFailure = (error) => ({ type: EDIT_DISCOUNTS_FAILURE, error });

const getBusinessFilesRequest = () => ({ type: GET_BUSINESS_FILES_REQUEST });
const getBusinessFilesSuccess = (businessFiles, businessFilesPages) => ({ type: GET_BUSINESS_FILES_SUCCESS, businessFiles, businessFilesPages });
const getBusinessFilesFailure = (error) => ({ type: GET_BUSINESS_FILES_FAILURE, error });

const getPersonFilesRequest = () => ({ type: GET_PERSON_FILES_REQUEST });
const getPersonFilesSuccess = (personFiles, personFilesPages) => ({ type: GET_PERSON_FILES_SUCCESS, personFiles, personFilesPages });
const getPersonFilesFailure = (error) => ({ type: GET_PERSON_FILES_FAILURE, error });

export const getUsersDiscountRequest = () => ({ type: GET_USERS_DISCOUNT_REQUEST });
export const getUsersDiscountSuccess = (discountUsers) => ({ type: GET_USERS_DISCOUNT_SUCCESS, discountUsers });
export const getUsersDiscountFailure = (error) => ({ type: GET_USERS_DISCOUNT_FAILURE, error });

const getWLPricesRequest = () => ({ type: GET_WL_PRICES_REQUEST });
const getWLPricesSuccess = (wlPrices) => ({ type: GET_WL_PRICES_SUCCESS, wlPrices });
const getWLPricesFailure = (error) => ({ type: GET_WL_PRICES_FAILURE, error });

export const getAllFaqs = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getAllFaqsRequest());
      const response = await axiosApi.get(`/admin/white-label/${id}/faqs?limit=50`);
      dispatch(getAllFaqsSuccess(response.data.data, response.data.pages));
    } catch (error) {
      dispatch(getAllFaqsFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const createFaqs = (id, faqsData) => {
  return async (dispatch) => {
    try {
      dispatch(createFaqsRequest());
      const response = await axiosApi.post(`/admin/white-label/${id}/faqs/new`, faqsData);
      dispatch(createFaqsSuccess(response.data.data));
      dispatch(getAllFaqs(id));

      return true;
    } catch (error) {
      dispatch(createFaqsFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');

      return false;
    }
  };
};

export const editFaqs = (wlID, id, faqsData) => {
  return async (dispatch) => {
    try {
      dispatch(editFaqsRequest());
      const response = await axiosApi.put(`/admin/white-label/${wlID}/faqs/edit/${id}`, faqsData);
      dispatch(editFaqsSuccess(response.data.data));
      dispatch(getAllFaqs(wlID));
    } catch (error) {
      dispatch(editFaqsFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const deleteFaqs = (wlID, id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteFaqsRequest());
      const response = await axiosApi.delete(`/admin/white-label/${wlID}/faqs/delete/${id}`);
      dispatch(deleteFaqsSuccess(response.data.data));
      dispatch(getAllFaqs(wlID));
    } catch (error) {
      dispatch(deleteFaqsFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const getUsersList = (wlID, page) => {
  return async (dispatch) => {
    try {
      dispatch(getAllUsersRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users?page=${page}`);
      const usersList = response.data.data;
      const pages = response.data.pages;
      dispatch(
        getAllUsersSuccess({
          usersList,
          pages,
        })
      );
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getAllUsersFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const banUser = (wlID, userId) => {
  return async (dispatch) => {
    try {
      dispatch(banCurrentUserRequest());
      const response = await axiosApi.delete(`/admin/white-label/${wlID}/users/delete/${userId}`);
      const user = response.data.data;
      dispatch(banCurrentUserSuccess(user));
    } catch (error) {
      showNotification('error', error.response.data?.detail, 'Error');
      dispatch(banCurrentUserFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const unbanUser = (wlID, userId) => {
  return async (dispatch) => {
    try {
      dispatch(unbanCurrentUserRequest());
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/restore/${userId}`);
      const user = response.data.data;
      dispatch(unbanCurrentUserSuccess(user));
    } catch (error) {
      showNotification('error', error.response.data?.detail, 'Error');
      dispatch(unbanCurrentUserFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const resetUserPassword = (wlID, userId) => {
  return async (dispatch) => {
    try {
      dispatch(resetCurrentUserRequest());
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/reset-password/${userId}`);
      const message = response.data.message;
      showNotification('success', message, 'Error');
      dispatch(resetCurrentUserSuccess());
    } catch (error) {
      showNotification('error', error?.response?.data?.[1]?.message, 'Error');
      dispatch(resetCurrentUserFailure(error?.response?.data?.[1]?.message));
    }
  };
};

export const getUserStatistic = (wlID, userId) => {
  return async (dispatch) => {
    try {
      dispatch(getUserRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users/show-statistic/${userId}`);
      dispatch(getUserSuccess(response.data.data));
    } catch (error) {
      showNotification('error', error.response.data?.detail, 'Error');
      dispatch(getUserFailure(error));
    }
  };
};

export const insertAfter = (wlID, position, id) => {
  return async (dispatch) => {
    try {
      await axiosApi.put(`/admin/white-label/${wlID}/faqs/insert-after/${id}`, { position });
      dispatch(getAllFaqs(wlID));
    } catch (error) {}
  };
};

export const insertBefore = (wlID, position, id) => {
  return async (dispatch) => {
    try {
      await axiosApi.put(`/admin/white-label/${wlID}/faqs/insert-before/${id}`, { position });
      dispatch(getAllFaqs(wlID));
    } catch (error) {}
  };
};

export const getSingleDiscount = (wlID, id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleDiscountsRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/discount/show/${id}`);
      const singleDiscount = response?.data?.data;
      dispatch(getSingleDiscountsSuccess(singleDiscount));
    } catch (error) {
      dispatch(getSingleDiscountsFailure(error));
    }
  };
};

export const getActiveDiscounts = (id, page = 1) => {
  return async (dispatch) => {
    try {
      dispatch(getActiveDiscountsRequest());
      const response = await axiosApi.get(`/admin/white-label/${id}/discount?page=${page}&active=${true}`);
      const activeDiscounts = response?.data?.data;
      const activeDiscountssPages = response?.data?.pages;
      dispatch(getActiveDiscountsSuccess(activeDiscounts, activeDiscountssPages));
    } catch (error) {
      dispatch(getActiveDiscountsFailure(error));
    }
  };
};

export const getInactiveDiscounts = (id, page = 1) => {
  return async (dispatch) => {
    try {
      dispatch(getInactiveDiscountsRequest());
      const response = await axiosApi.get(`/admin/white-label/${id}/discount?page=${page}&active=${false}`);
      const inactiveDiscounts = response?.data?.data;
      const inactiveDiscountsPages = response?.data?.pages;
      dispatch(getInactiveDiscountsSuccess(inactiveDiscounts, inactiveDiscountsPages));
    } catch (error) {
      dispatch(getInactiveDiscountsFailure(error));
    }
  };
};
export const createDiscount = (id, data, sendRequest = true) => {
  return async (dispatch) => {
    try {
      await axiosApi.post(`/admin/white-label/${id}/discount/new`, data);
      if (sendRequest) {
        dispatch(getActiveDiscounts(id));
        dispatch(getInactiveDiscounts(id));
      }

      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const editDiscount = (wlID, id, discountData) => {
  return async (dispatch) => {
    try {
      dispatch(editDiscountsRequest());
      await axiosApi.put(`/admin/white-label/${wlID}/discount/edit/${id}`, discountData);
      dispatch(editDiscountsSuccess());
      dispatch(getActiveDiscounts(wlID));
      return true;
    } catch (error) {
      dispatch(editDiscountsFailure(error));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const deleteDiscount = (wlID, id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.delete(`/admin/white-label/${wlID}/discount/remove/${id}`);
      const { message } = response.data;
      dispatch(getActiveDiscounts(wlID));
      dispatch(getInactiveDiscounts(wlID));
      showNotification('success', message);
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const disableDiscount = (wlID, id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.delete(`/admin/white-label/${wlID}/discount/delete/${id}`);
      const { message } = response.data;
      dispatch(getActiveDiscounts(wlID));
      dispatch(getInactiveDiscounts(wlID));
      showNotification('success', message);
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};
export const restoreDiscount = (wlID, id) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/discount/restore/${id}`);
      const { message } = response.data;
      dispatch(getActiveDiscounts(wlID));
      dispatch(getInactiveDiscounts(wlID));
      showNotification('success', message);
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const getUsersForDiscount = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getUsersDiscountRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users/get-list`);
      const discountUsers = response.data.data;
      dispatch(getUsersDiscountSuccess(discountUsers));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      dispatch(getUsersDiscountFailure(error));
    }
  };
};

export const getWLPrices = (wlID) => {
  return async (dispatch) => {
    try {
      dispatch(getWLPricesRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/price`);
      const wlPrices = response.data?.data;
      dispatch(getWLPricesSuccess(wlPrices));
    } catch (error) {
      dispatch(getWLPricesFailure(error));
    }
  };
};

export const editWLPriceCard = (wlID, cardId, data) => {
  return async (dispatch) => {
    try {
      dispatch(editPriceCardRequest());
      const response = await axiosApi.put(`/admin/white-label/${wlID}/price/edit/${cardId}`, data);
      const message = response.data.message;
      dispatch(editPriceCardSuccess());
      dispatch(getWLPrices(wlID));
      showNotification('success', message, 'OK');
      return true;
    } catch (error) {
      dispatch(editPriceCardFailure(error));
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const addBasicRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/add-role-basic-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};
export const removeBasicRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/remove-role-basic-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const addAdminRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/add-role-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};
export const removeAdminRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/remove-role-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const addMainAdminRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/add-role-main-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};
export const removeMainAdminRole = (wlID, userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/remove-role-main-admin/${userId}`);
      dispatch(getUsersList(wlID));
      const message = response.data.message;
      showNotification('success', message, 'OK');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
    }
  };
};

export const getBusinessFiles = (wlID, userId, page) => {
  return async (dispatch) => {
    try {
      dispatch(getBusinessFilesRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users/show-business-files/${userId}?page=${page}`);
      const businessFiles = response.data.data;
      const businessFilesPages = response.data.pages;

      dispatch(getBusinessFilesSuccess(businessFiles, businessFilesPages));
    } catch (error) {
      dispatch(getBusinessFilesFailure(error));
    }
  };
};

export const getPersonFiles = (wlID, userId, page) => {
  return async (dispatch) => {
    try {
      dispatch(getPersonFilesRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users/show-person-files/${userId}?page=${page}`);
      const personFiles = response.data.data;
      const personFilesPages = response.data.pages;
      dispatch(getPersonFilesSuccess(personFiles, personFilesPages));
    } catch (error) {
      dispatch(getPersonFilesFailure(error));
    }
  };
};

export const verifyUser = (wlID, userId, page) => {
  return async (dispatch) => {
    try {
      const response = await axiosApi.put(`/admin/white-label/${wlID}/users/verify/${userId}`);
      const message = response.data.message;
      showNotification('success', message, 'OK');
      dispatch(getUsersList(wlID, page));
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const changeUserPassword = (wlID, userId, password) => {
  return async () => {
    try {
      await axiosApi.put(`/admin/white-label/${wlID}/users/change-password/${userId}`, password);
      showNotification('success', 'Successfully changed password', 'OK');
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};

export const addSkips = (wlID, userId, skips) => {
  return async () => {
    try {
      await axiosApi.put(`/admin/white-label/${wlID}/users/purchase/${userId}`, skips);
      showNotification('success', `Successfully added ${skips.skips} skips`, 'OK');
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || ' ', 'Error');
      return false;
    }
  };
};
