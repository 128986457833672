import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './styles.scss';

const CustomScrollbar = ({ className = '', children, maxHeight = '100%', onScroll = (e) => e, autoHide = true }) => {
  const defaultOptions = {
    autoHide: true,
    clickOnTrack: false,
    style: {
      maxHeight: maxHeight,
      height: '100%',
      width: '100%',
    },
  };

  return (
    <SimpleBar {...defaultOptions} className={`custom-scrollbar ${className}`} onScroll={onScroll} autoHide={autoHide}>
      {children}
    </SimpleBar>
  );
};


export default CustomScrollbar;
