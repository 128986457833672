import React from 'react';
import './styles.scss';

const AccountManagmentField = ({ value, setValue, placeholder, className, width }) => {
  return (
    <input
      type="text"
      style={{ width }}
      className={`edit__field ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default AccountManagmentField;
