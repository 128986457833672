// USER ACTIONS

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const FORGOT_USER_REQUEST = 'FORGOT_USER_REQUEST';
export const FORGOT_USER_SUCCESS = 'FORGOT_USER_SUCCESS';
export const FORGOT_USER_FAILURE = 'FORGOT_USER_FAILURE';

export const RESET_USER_REQUEST = 'RESET_USER_REQUEST';
export const RESET_USER_SUCCESS = 'RESET_USER_SUCCESS';
export const RESET_USER_FAILURE = 'RESET_USER_FAILURE';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const EDIT_EMAIL_REQUEST = 'EDIT_EMAIL_REQUEST';
export const EDIT_EMAIL_SUCCESS = 'EDIT_EMAIL_SUCCESS';
export const EDIT_EMAIL_FAILURE = 'EDIT_EMAIL_FAILURE';

export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST';
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS';
export const EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE';

export const CREATE_AVATAR_REQUEST = 'CREATE_AVATAR_REQUEST';
export const CREATE_AVATAR_SUCCESS = 'CREATE_AVATAR_SUCCESS';
export const CREATE_AVATAR_FAILURE = 'CREATE_AVATAR_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

//FILE ACTIONS

export const POST_FILES_REQUEST = 'POST_FILES_REQUEST';
export const POST_FILES_SUCCESS = 'POST_FILES_SUCCESS';
export const POST_FILES_FAILURE = 'POST_FILES_FAILURE';

export const POST_SHEET_REQUEST = 'POST_SHEET_REQUEST';
export const POST_SHEET_SUCCESS = 'POST_SHEET_SUCCESS';
export const POST_SHEET_FAILURE = 'POST_SHEET_FAILURE';

export const POST_PROCESS_REQUEST = 'POST_PROCESS_REQUEST';
export const POST_PROCESS_SUCCESS = 'POST_PROCESS_SUCCESS';
export const POST_PROCESS_FAILURE = 'POST_PROCESS_FAILURE';

export const REMOVE_FILES = 'REMOVE_FILES';

export const EDIT_FILE_HEADER_REQUEST = 'EDIT_FILE_HEADER_REQUEST';
export const EDIT_FILE_HEADER_SUCCESS = 'EDIT_FILE_HEADER_SUCCESS';
export const EDIT_FILE_HEADER_FAILURE = 'EDIT_FILE_HEADER_FAILURE';

export const POST_BUSINESS_FILES_REQUEST = 'POST_BUSINESS_FILES_REQUEST';
export const POST_BUSINESS_FILES_SUCCESS = 'POST_BUSINESS_FILES_SUCCESS';
export const POST_BUSINESS_FILES_FAILURE = 'POST_BUSINESS_FILES_FAILURE';

export const POST_BUSINESS_SHEET_REQUEST = 'POST_BUSINESS_SHEET_REQUEST';
export const POST_BUSINESS_SHEET_SUCCESS = 'POST_BUSINESS_SHEET_SUCCESS';
export const POST_BUSINESS_SHEET_FAILURE = 'POST_BUSINESS_SHEET_FAILURE';

export const POST_BUSINESS_PROCESS_REQUEST = 'POST_BUSINESS_PROCESS_REQUEST';
export const POST_BUSINESS_PROCESS_SUCCESS = 'POST_BUSINESS_PROCESS_SUCCESS';
export const POST_BUSINESS_PROCESS_FAILURE = 'POST_BUSINESS_PROCESS_FAILURE';

export const REMOVE_BUSINESS_FILES = 'REMOVE_BUSINESS_FILES';

export const EDIT_BUSINESS_FILE_HEADER_REQUEST = 'EDIT_BUSINESS_FILE_HEADER_REQUEST';
export const EDIT_BUSINESS_FILE_HEADER_SUCCESS = 'EDIT_BUSINESS_FILE_HEADER_SUCCESS';
export const EDIT_BUSINESS_FILE_HEADER_FAILURE = 'EDIT_BUSINESS_FILE_HEADER_FAILURE';

//PROCESS ACTION

export const GET_PROCESS_REQUEST = 'GET_PROCESS_REQUEST';
export const GET_PROCESS_SUCCESS = 'GET_PROCESS_SUCCESS';
export const GET_PROCESS_FAILURE = 'GET_PROCESS_FAILURE';

export const GET_PROCESS_HISTORY_REQUEST = 'GET_PROCESS_HISTORY_REQUEST';
export const GET_PROCESS_HISTORY_SUCCESS = 'GET_PROCESS_HISTORY_SUCCESS';
export const GET_PROCESS_HISTORY_FAILURE = 'GET_PROCESS_HISTORY_FAILURE';

export const GET_SOLO_PROCESS_REQUEST = 'GET_SOLO_PROCESS_REQUEST';
export const GET_SOLO_PROCESS_SUCCESS = 'GET_SOLO_PROCESS_SUCCESS';
export const GET_SOLO_PROCESS_FAILURE = 'GET_SOLO_PROCESS_FAILURE';

export const REMOVE_IMPORT_HISTORY = 'REMOVE_IMPORT_HISTORY';
export const REMOVE_RESULTS_LIBRARY = 'REMOVE_RESULTS_LIBRARY';

//PAYMENT ACTION

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE';

export const PAYMENT_STRIPE_REQUEST = 'PAYMENT_STRIPE_REQUEST';
export const PAYMENT_STRIPE_SUCCESS = 'PAYMENT_STRIPE_SUCCESS';
export const PAYMENT_STRIPE_FAILURE = 'PAYMENT_STRIPE_FAILURE';

export const ACTIVE_DISCOUNT_REQUEST = 'ACTIVE_DISCOUNT_REQUEST';
export const ACTIVE_DISCOUNT_SUCCESS = 'ACTIVE_DISCOUNT_SUCCESS';
export const ACTIVE_DISCOUNT_FAILURE = 'ACTIVE_DISCOUNT_FAILURE';

export const GET_PRICES_REQUEST = 'GET_PRICES_REQUEST';
export const GET_PRICES_SUCCESS = 'GET_PRICES_SUCCESS';
export const GET_PRICES_FAILURE = 'GET_PRICES_FAILURE';
export const GET_WL_PRICES_REQUEST = 'GET_WL_PRICES_REQUEST';
export const GET_WL_PRICES_SUCCESS = 'GET_WL_PRICES_SUCCESS';
export const GET_WL_PRICES_FAILURE = 'GET_WL_PRICES_FAILURE';

export const GET_CARDS_REQUEST = 'GET_CARDS_REQUEST';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAILURE = 'GET_CARDS_FAILURE';

export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_FAILURE = 'CREATE_CARD_FAILURE';

export const GET_PRIMARY_CARD_REQUEST = 'GET_PRIMARY_CARD_REQUEST';
export const GET_PRIMARY_CARD_SUCCESS = 'GET_PRIMARY_CARD_SUCCESS';
export const GET_PRIMARY_CARD_FAILURE = 'GET_PRIMARY_CARD_FAILURE';

export const CHANGE_PRIMARY_CARD_REQUEST = 'CHANGE_PRIMARY_CARD_REQUEST';
export const CHANGE_PRIMARY_CARD_SUCCESS = 'CHANGE_PRIMARY_CARD_SUCCESS';
export const CHANGE_PRIMARY_CARD_FAILURE = 'CHANGE_PRIMARY_CARD_FAILURE';

export const GET_CARD_BILLING_DETAILS_REQUEST = 'GET_CARD_BILLING_DETAILS_REQUEST';
export const GET_CARD_BILLING_DETAILS_SUCCESS = 'GET_CARD_BILLING_DETAILS_SUCCESS';
export const GET_CARD_BILLING_DETAILS_FAILURE = 'GET_CARD_BILLING_DETAILS_FAILURE';

export const EDIT_CARD_BILLING_DETAILS_REQUEST = 'EDIT_CARD_BILLING_DETAILS_REQUEST';
export const EDIT_CARD_BILLING_DETAILS_SUCCESS = 'EDIT_CARD_BILLING_DETAILS_SUCCESS';
export const EDIT_CARD_BILLING_DETAILS_FAILURE = 'EDIT_CARD_BILLING_DETAILS_FAILURE';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

//CONFIG ACTION

export const GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';

export const DEFAULT_LOADING = 'DEFAULT_LOADING';

//ADMIN ACTIONS

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const BAN_CURRENT_USER_REQUEST = 'BAN_CURRENT_USER_REQUEST';
export const BAN_CURRENT_USER_SUCCESS = 'BAN_CURRENT_USER_SUCCESS';
export const BAN_CURRENT_USER_FAILURE = 'BAN_CURRENT_USER_FAILURE';

export const UNBAN_CURRENT_USER_REQUEST = 'UNBAN_CURRENT_USER_REQUEST';
export const UNBAN_CURRENT_USER_SUCCESS = 'UNBAN_CURRENT_USER_SUCCESS';
export const UNBAN_CURRENT_USER_FAILURE = 'UNBAN_CURRENT_USER_FAILURE';

export const RESET_USER_PASSWORD_REQUEST = 'RESET_USER_PASSWORD_REQUEST';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAILURE = 'RESET_USER_PASSWORD_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_ADMIN_ALL_FAQS_REQUEST = 'GET_ADMIN_ALL_FAQS_REQUEST';
export const GET_ADMIN_ALL_FAQS_SUCCESS = 'GET_ADMIN_ALL_FAQS_SUCCESS';
export const GET_ADMIN_ALL_FAQS_FAILURE = 'GET_ADMIN_ALL_FAQS_FAILURE';

export const CREATE_FAQS_REQUEST = 'CREATE_FAQS_REQUEST';
export const CREATE_FAQS_SUCCESS = 'CREATE_FAQS_SUCCESS';
export const CREATE_FAQS_FAILURE = 'CREATE_FAQS_FAILURE';

export const EDIT_FAQS_REQUEST = 'EDIT_FAQS_REQUEST';
export const EDIT_FAQS_SUCCESS = 'EDIT_FAQS_SUCCESS';
export const EDIT_FAQS_FAILURE = 'EDIT_FAQS_FAILURE';

export const DELETE_FAQS_REQUEST = 'DELETE_FAQS_REQUEST';
export const DELETE_FAQS_SUCCESS = 'DELETE_FAQS_SUCCESS';
export const DELETE_FAQS_FAILURE = 'DELETE_FAQS_FAILURE';

//LANDING ACTIONS

export const GET_ALL_FAQS_REQUEST = 'GET_ALL_FAQS_REQUEST';
export const GET_ALL_FAQS_SUCCESS = 'GET_ALL_FAQS_SUCCESS';
export const GET_ALL_FAQS_FAILURE = 'GET_ALL_FAQS_FAILURE';

export const POST_CONTACT_US_REQUEST = 'POST_CONTACT_US_REQUEST';
export const POST_CONTACT_US_SUCCESS = 'POST_CONTACT_US_SUCCESS';
export const POST_CONTACT_US_FAILURE = 'POST_CONTACT_US_FAILURE';

//PRICE CARDS

export const GET_LANDING_PRICES_REQUEST = 'GET_LANDING_PRICES_REQUEST';
export const GET_LANDING_PRICES_SUCCESS = 'GET_LANDING_PRICES_SUCCESS';
export const GET_LANDING_PRICES_FAILURE = 'GET_LANDING_PRICES_FAILURE';

export const EDIT_PRICE_CARD_REQUEST = 'EDIT_PRICE_CARDS_REQUEST';
export const EDIT_PRICE_CARD_SUCCESS = 'EDIT_PRICE_CARDS_SUCCESS';
export const EDIT_PRICE_CARD_FAILURE = 'EDIT_PRICE_CARDS_FAILURE';

// WL

export const GET_WL_REQUEST = 'GET_WL_REQUEST';
export const GET_WL_SUCCESS = 'GET_WL_SUCCESS';
export const GET_WL_FAILURE = 'GET_WL_FAILURE';

export const GET_SOLO_WL_REQUEST = 'GET_SOLO_WL_REQUEST';
export const GET_SOLO_WL_SUCCESS = 'GET_SOLO_WL_SUCCESS';
export const GET_SOLO_WL_FAILURE = 'GET_SOLO_WL_FAILURE';

export const CREATE_WL_REQUEST = 'CREATE_WL_REQUEST';
export const CREATE_WL_SUCCESS = 'CREATE_WL_SUCCESS';
export const CREATE_WL_FAILURE = 'CREATE_WL_FAILURE';

export const EDIT_WL_REQUEST = 'EDIT_WL_REQUEST';
export const EDIT_WL_SUCCESS = 'EDIT_WL_SUCCESS';
export const EDIT_WL_FAILURE = 'EDIT_WL_FAILURE';

export const ADD_IMAGE_WL_REQUEST = 'ADD_IMAGE_WL_REQUEST';
export const ADD_IMAGE_WL_SUCCESS = 'ADD_IMAGE_WL_SUCCESS';
export const ADD_IMAGE_WL_FAILURE = 'ADD_IMAGE_WL_FAILURE';

export const GET_PAYMENT_STATISTICS_REQUEST = 'GET_PAYMENT_STATISTICS_REQUEST';
export const GET_PAYMENT_STATISTICS_SUCCESS = 'GET_PAYMENT_STATISTICS_SUCCESS';
export const GET_PAYMENT_STATISTICS_FAILURE = 'GET_PAYMENT_STATISTICS_FAILURE';

export const GET_SUMMARY_STATISTICS_REQUEST = 'GET_SUMMARY_STATISTICS_REQUEST';
export const GET_SUMMARY_STATISTICS_SUCCESS = 'GET_SUMMARY_STATISTICS_SUCCESS';
export const GET_SUMMARY_STATISTICS_FAILURE = 'GET_SUMMARY_STATISTICS_FAILURE';

export const GET_SKIPTRACE_STATISTICS_REQUEST = 'GET_SKIPTRACE_STATISTICS_REQUEST';
export const GET_SKIPTRACE_STATISTICS_SUCCESS = 'GET_SKIPTRACE_STATISTICS_SUCCESS';
export const GET_SKIPTRACE_STATISTICS_FAILURE = 'GET_SKIPTRACE_STATISTICS_FAILURE';

// DISCOUNTS

export const GET_ACTIVE_DISCOUNTS_REQUEST = 'GET_ACTIVE_DISCOUNTS_REQUEST';
export const GET_ACTIVE_DISCOUNTS_SUCCESS = 'GET_ACTIVE_DISCOUNTS_SUCCESS';
export const GET_ACTIVE_DISCOUNTS_FAILURE = 'GET_ACTIVE_DISCOUNTS_FAILURE';

export const GET_INACTIVE_DISCOUNTS_REQUEST = 'GET_INACTIVE_DISCOUNTS_REQUEST';
export const GET_INACTIVE_DISCOUNTS_SUCCESS = 'GET_INACTIVE_DISCOUNTS_SUCCESS';
export const GET_INACTIVE_DISCOUNTS_FAILURE = 'GET_INACTIVE_DISCOUNTS_FAILURE';

export const GET_SINGLE_DISCOUNTS_REQUEST = 'GET_SINGLE_DISCOUNTS_REQUEST';
export const GET_SINGLE_DISCOUNTS_SUCCESS = 'GET_SINGLE_DISCOUNTS_SUCCESS';
export const GET_SINGLE_DISCOUNTS_FAILURE = 'GET_SINGLE_DISCOUNTS_FAILURE';

export const EDIT_DISCOUNTS_REQUEST = 'EDIT_DISCOUNTS_REQUEST';
export const EDIT_DISCOUNTS_SUCCESS = '  EDIT_DISCOUNTS_SUCCESS';
export const EDIT_DISCOUNTS_FAILURE = '  EDIT_DISCOUNTS_FAILURE';

export const GET_USERS_DISCOUNT_REQUEST = 'GET_USERS_DISCOUNT_REQUEST';
export const GET_USERS_DISCOUNT_SUCCESS = '  GET_USERS_DISCOUNT_SUCCESS';
export const GET_USERS_DISCOUNT_FAILURE = '  GET_USERS_DISCOUNT_FAILURE';

// FILES
export const GET_BUSINESS_FILES_REQUEST = 'GET_BUSINESS_FILES_REQUEST';
export const GET_BUSINESS_FILES_SUCCESS = 'GET_BUSINESS_FILES_SUCCESS';
export const GET_BUSINESS_FILES_FAILURE = 'GET_BUSINESS_FILES_FAILURE';

export const GET_PERSON_FILES_REQUEST = 'GET_PERSON_FILES_REQUEST';
export const GET_PERSON_FILES_SUCCESS = 'GET_PERSON_FILES_SUCCESS';
export const GET_PERSON_FILES_FAILURE = 'GET_PERSON_FILES_FAILURE';

// INVITES

export const GET_INVITES_REQUEST = 'GET_INVITES_REQUEST';
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS';
export const GET_INVITES_FAILURE = 'GET_INVITES_FAILURE';

export const POST_INVITES_REQUEST = 'POST_INVITES_REQUEST';
export const POST_INVITES_SUCCESS = 'POST_INVITES_SUCCESS';
export const POST_INVITES_FAILURE = 'POST_INVITES_FAILURE';

export const DELETE_INVITES_REQUEST = 'DELETE_INVITES_REQUEST';
export const DELETE_INVITES_SUCCESS = 'DELETE_INVITES_SUCCESS';
export const DELETE_INVITES_FAILURE = 'DELETE_INVITES_FAILURE';

export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';
