import React, { useContext, useEffect, useState } from 'react';
import Navigation from './Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvites, getInvites } from '../../../../store/actions/invitesActions';
import { Popover, Spin } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import InviteModal from '../../../../Components/admin/InviteModal/InviteModal';
import './styles.scss';
import { PaginationPanelComponent } from '../../../../Components/common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../../utils/constants';
import { AppContext } from '../../../../appContext';
import _ from 'lodash';
import NoResult from '../../../../Components/common/NoResult/NoResult';

const Invites = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const invites = useSelector((state) => state.invites.invites);
  const loading = useSelector((state) => state.invites.loading);
  const pages = useSelector((state) => state.invites.pages);
  const [show, setShow] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);
  const { isUserMainAdmin, isUserBasic } = useContext(AppContext);

  useEffect(() => {
    if (id) dispatch(getInvites(id, paginationPage));
  }, [dispatch, id, paginationPage]);

  const content = (userId) => {
    return (
      <div className="users-list__popover">
        <button onClick={() => dispatch(deleteInvites(id, userId))}>Delete Invite</button>
      </div>
    );
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="employees">
        <Navigation id={id} />
        {!isUserMainAdmin && !isUserBasic && (
          <button className="employees__invite" onClick={() => setShow(true)}>
            Invite New Employee
          </button>
        )}

        <div className="employees__container">
          {!loading && (
            <>
              {_.isEmpty(invites) ? (
                <NoResult text="No Invites" />
              ) : (
                <ul className="employees-list">
                  <li className="employees-list__item">
                    <h3>Email</h3>
                    <h3>Roles</h3>
                    <h3>Created Date</h3>
                    <h3>Actions</h3>
                  </li>

                  {invites.map((user, index) => (
                    <li className="employees-list__item" key={index}>
                      <span> {user.email}</span>
                      <span>{user.role}</span>
                      <span>{user.created_at}</span>
                      <span>
                        <Popover placement="topLeft" title={'Actions'} content={content(user.id)} trigger="click">
                          <MenuOutlined />
                        </Popover>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
        <PaginationPanelComponent
          pagintationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          totalCount={pages * ITEMS_PER_PAGE}
          itemsPerPage={ITEMS_PER_PAGE}
        />

        {show && <InviteModal show={show} setShow={setShow} id={id} setPaginationPage={setPaginationPage} />}
      </div>
    </Spin>
  );
};

export default Invites;
