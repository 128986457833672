import React, { useContext, useEffect, useRef, useState } from 'react';
import { Steps } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import Modal from 'antd/es/modal/Modal';
import history from '../../../utils/history';
import { getHandleBusinessProcess, getHandleProcess, removeBusinessFiles, removeFiles } from '../../../store/actions/fileActions';
import { userProfile } from '../../../store/actions/userActions';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { ERROR_MESSAGES } from '../../../utils/constants';
import GIF from '../../../assets/pop-up/logo.gif';
import { AppContext } from '../../../appContext';
import { wsURL } from '../../../utils/config';
import './styles.scss';

const ResultsModal = ({ show, setShow, type = 'people' }) => {
  const dispatch = useDispatch();
  const { setSideBarIsShown } = useContext(AppContext);
  const user = useSelector((state) => state.user.profile);
  const processId = useSelector((state) => state.files.processId);
  const files = useSelector((state) => state?.files.files);
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';

  const [steps, setSteps] = useState([
    { name: 'Searching', status: 'process' },
    { name: 'Organizing Data', status: 'wait' },
    { name: 'Completed', status: 'wait' },
  ]);

  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const ws = useRef(null);

  useEffect(() => {
    const effect = async () => {
      if (type === 'people')
        await dispatch(getHandleProcess(files)).then((response) => {
          if (ERROR_MESSAGES?.notEnoughtSkips?.toLowerCase().trim() === response?.toLowerCase()?.trim()) {
            setShow(false);
            setSideBarIsShown(true);
            ws.current.close();
          }
        });
      else
        await dispatch(getHandleBusinessProcess(files)).then((response) => {
          if (ERROR_MESSAGES?.notEnoughtSkips?.toLowerCase().trim() === response?.toLowerCase()?.trim()) {
            setShow(false);
            setSideBarIsShown(true);
            ws.current.close();
          }
        });
    };

    if (show && files.length) {
      effect().then((r) => r);
    }
  }, [dispatch, show, setShow, setSideBarIsShown, files, type]);

  useEffect(() => {
    if (show) {
      ws.current = new WebSocket(wsURL);
      ws.current.onopen = async () => {
        await ws.current.send(JSON.stringify({ user: user.id.toString() }));
      };

      ws.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        if (message.type === 'PROCESS_STATUS' || message.type === 'BUSINESS_PROCESS_STATUS') {
          if (message.percent > 0) {
            for (let i = 0; i < message.percent / 5; i++) {
              setTimeout(() => {
                setProgress((prev) => (prev + 5 <= message?.percent ? prev + 5 : prev));
              }, i * 300);
            }
          }

          setTimeout(() => {
            setSteps(message.data);
          }, 3000);

          if (message.data.filter((item) => item.status === 'finish').length === 3) {
            setTimeout(() => {
              if (type === 'people') history.push(`/library/${processId}`);
              else history.push(`/library/business/${processId}`);
              dispatch(removeFiles());
            }, 4000);
          }
        }

        if (message.skips) dispatch(userProfile());
      };

      return () => {
        ws.current.close();
      };
    }
  }, [ws, show, user, dispatch, processId, type]);

  const closeModal = () => {
    setShow(false);
    history.push('/dashboard');
    if (type === 'people') dispatch(removeFiles());
    else dispatch(removeBusinessFiles());
  };

  return (
    <Modal
      bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px' }}
      width={modalWidth}
      visible={show}
      onCancel={closeModal}
      className="result"
      footer={null}
    >
      <div className="result-modal">
        <div className="result-modal__header">
          <Steps current={step} onChange={(value) => setStep(value)}>
            {steps.map((item, index) => (
              <Steps.Step
                title={item.name}
                key={index}
                status={item.status === 'complete' ? 'finish' : item.status}
                icon={item.status === 'process' && <LoadingOutlined style={{ color: 'white' }} />}
              />
            ))}
          </Steps>
          <div className="result-modal__progressbar-container">
            <span>{`${progress}%`}</span>
            <div className="result-modal__progressbar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <div className="result-modal__body">
          <img src={GIF} alt="GIF" />
          <h2>It's RAINING RESULTS. HOLD TIGHT!</h2>
          <p>We're pulling data from over 320 million records that we have available!</p>
          <p>Please wait white we complete your search.This should only take a few minutes.</p>
        </div>
        <div className="result-modal__footer">
          <div className="result-modal__footer-inner">
            <p>
              <b>On the go? </b>Download your results in the{' '}
              <Link to="/dashboard" onClick={() => dispatch(removeFiles())}>
                dashboard
              </Link>{' '}
              or{' '}
              <Link to="/library" onClick={() => dispatch(removeFiles())}>
                results pages{' '}
              </Link>
              later. You will also receive an email with your downloadable files!
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResultsModal;
