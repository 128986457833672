import React, { useContext } from 'react';
import AdminNavigation from '../../Components/admin/Navigation/AdminNavigation';
import { AppContext } from '../../appContext';
import BasicNavigation from '../../Components/admin/Navigation/BasicNavigation';
import { useHistory } from 'react-router-dom';
import RoleNavigation from '../../Components/admin/Navigation/RoleNavigation';
import './styles.scss';

const AdminMainComponent = () => {
  const { isUserAdmin, isUserBasic, isUserMainAdmin } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  return (
    <section className="admin-panel">
      <RoleNavigation />
      <div className="admin-panel__main-container">
        {(isUserAdmin || isUserMainAdmin) && !pathname.includes('super-admin') && <AdminNavigation />}
        {!isUserAdmin && !isUserMainAdmin && isUserBasic && <BasicNavigation />}
      </div>
    </section>
  );
};

export default AdminMainComponent;
