import React from 'react';

const CloseIcon = ({ className }) => {
  return (
    <div className={`${className}`}>
      <svg
        fill="var(--main-color)"
        xmlns="http://www.w3.org/2000/svg"
        width={52}
        height={52}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 51.56 51.56"
      >
        <defs>
          <linearGradient id="SKIPCLOUD-ICON" x1="1.5" y1="25.78" x2="50.06" y2="25.78" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-1)" />
            <stop offset="1" stopColor="var(--color-2)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <circle className="cls-1" cx="25.78" cy="25.78" r="24.28" />
            <path
              stroke="#fff"
              strokeWidth={1}
              className="cls-2"
              d="M31.87,22.85V19.76a6,6,0,0,0-.48-2.37,6.11,6.11,0,0,0-3.24-3.24,6.09,6.09,0,0,0-4.74,0,6.09,6.09,0,0,0-3.72,5.61v3.09"
            />
            <path
              stroke="#fff"
              strokeWidth={1}
              className="cls-2"
              d="M18.39,23.63a9.75,9.75,0,0,0-1.34,5,9,9,0,0,0,8.73,9.29c4.82,0,8.73-4.17,8.73-9.31a9.76,9.76,0,0,0-1.33-4.95.87.87,0,0,0-.68-.43c-2.07-.14-4.34-.22-6.72-.22s-4.64.08-6.72.22A.87.87,0,0,0,18.39,23.63Z"
            />
            <circle stroke="#fff" strokeWidth={1} className="cls-2" cx="25.78" cy="29.62" r="1.62" />
            <line stroke="#fff" strokeWidth={1} className="cls-2" x1="25.78" y1="31.24" x2="25.78" y2="33.35" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CloseIcon;
