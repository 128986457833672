import React from 'react';
import { ReactComponent as StarIcon } from '../../assets/pop-up/star.svg';
import { ReactComponent as DollarIcon } from '../../assets/landing/dollar-price.svg';
import './styles.scss';

const PopularOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--popular`}>
      <StarIcon />
      <p>Popular option</p>
    </div>
  );
};

const BestOption = () => {
  return (
    <div className={`default-price-card-option default-price-card-option--best`}>
      <DollarIcon />
      <p>Best deal</p>
    </div>
  );
};

const DisplayedCards = ({ skips, price, perHit, type }) => {
  const TYPE_OPTION = {
    best_deal: <BestOption />,
    popular_option: <PopularOption />,
    default: null,
  };

  return (
    <div className="default-price-card">
      {TYPE_OPTION[type]}
      <header className="price-card__header">
        <h3 className="price-card__header-heading">
          <b>{new Intl.NumberFormat('en').format(skips)}</b>
          {` SKIPS`}
        </h3>
      </header>
      <div className="price-card__content-container">
        <p className={`${type !== 'default' ? '' : 'price-card__price-default'} price-card__price-value`}>
          ${price / 100}
          <sup>{price.toString().slice(-2)}</sup>
        </p>
        <p className="price-card__price-skip">${perHit} PER HIT</p>
      </div>
    </div>
  );
};

export default DisplayedCards;
