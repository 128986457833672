import React from 'react';
import { Link } from 'react-router-dom';
import { SignUpLink } from '../../Landing';

const Navigation = () => {
  const navigationRoutes = [
    {
      to: '/',
      title: 'Home',
    },
    {
      to: '/about-us',
      title: 'About us',
    },
    {
      to: '/pricing',
      title: 'Pricing',
    },
    {
      to: '/faq',
      title: 'faq',
    },
    {
      to: '/contact',
      title: 'Contact Us',
    },
  ];

  return (
    <>
      <span className="landing__header-decoration" />
      <nav className="landing__nav">
        <ul className="landing__nav-list">
          {navigationRoutes.map((route, index) => {
            return (
              <li className="landing__nav-item" key={index}>
                <Link to={route.to} className="landing__nav-link">
                  {route.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <span className="landing__header-decoration" />
      <div className="landing__login-container">
        <SignUpLink text={'Try skipcloud now!'} className="landing__signup-link" />
        <span>
          Already a member?
          <Link  draggable={false}  to={'/login'} className="landing__login-link">
            Log in
          </Link>
          now
        </span>
      </div>
    </>
  );
};

export default Navigation;
