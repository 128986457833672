import {
  GET_ALL_FAQS_FAILURE,
  GET_ALL_FAQS_REQUEST,
  GET_ALL_FAQS_SUCCESS,
  POST_CONTACT_US_FAILURE,
  POST_CONTACT_US_REQUEST,
  POST_CONTACT_US_SUCCESS,
  GET_LANDING_PRICES_REQUEST,
  GET_LANDING_PRICES_SUCCESS,
  GET_LANDING_PRICES_FAILURE,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  faqs: [],
  landingPrices: [],
  landingPricesLoading: false,
};

const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAQS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ALL_FAQS_SUCCESS:
      return { ...state, loading: false, error: null, faqs: action.faqs };
    case GET_ALL_FAQS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case POST_CONTACT_US_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_CONTACT_US_SUCCESS:
      return { ...state, loading: false, error: null };
    case POST_CONTACT_US_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_LANDING_PRICES_REQUEST:
      return { ...state, landingPricesLoading: true };
    case GET_LANDING_PRICES_SUCCESS:
      return { ...state, landingPricesLoading: false, landingPrices: action.landingPrices };
    case GET_LANDING_PRICES_FAILURE:
      return { ...state, landingPricesLoading: false };

    default:
      return { ...state };
  }
};

export default landingReducer;
