import { GET_CONFIG_FAILURE, GET_CONFIG_REQUEST, GET_CONFIG_SUCCESS, GET_STATE_FAILURE, GET_STATE_REQUEST, GET_STATE_SUCCESS } from '../actionsTypes';

const initialState = {
  config: {},
  states: [],
  loading: false,
  loadingStates: false,
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_REQUEST:
      return { ...state, loading: true };
    case GET_CONFIG_SUCCESS:
      return { ...state, loading: false, config: action.config, error: null };
    case GET_CONFIG_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_STATE_REQUEST:
      return { ...state, loadingStates: true, error: null };
    case GET_STATE_SUCCESS:
      return { ...state, loadingStates: false, states: action.states, error: null };
    case GET_STATE_FAILURE:
      return { ...state, loadingStates: false, error: action.error };
    default:
      return { ...state };
  }
};

export default configReducer;
