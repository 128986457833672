import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { createDiscount, getUsersForDiscount } from '../../../../../store/actions/adminActions';
import './styles.scss';
const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';
const CreateDiscount = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [userIds, setUserIds] = useState([]);
  const [priceIds, setPriceIds] = useState([]);
  const [availableFrom, setAvailableFrom] = useState(moment().format(dateFormat));
  const [availableUntil, setAvailableUntil] = useState(moment().format(dateFormat));
  const [percent, setPercent] = useState('');
  const [codeName, setCodeName] = useState('');
  const [usageLimit, setUsageLimit] = useState(null);
  const pricesList = useSelector((state) => state?.admin.wlPrices);
  const discountUsers = useSelector((state) => state.admin?.discountUsers);
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;

  const addDiscount = async () => {
    const response = await dispatch(
      createDiscount(currentWLId, { userIds, priceIds, availableFrom, availableUntil, percent: Number(percent), usageLimit, code: codeName, name: '' })
    );
    if (response) {
      closeModal();
      setUserIds('');
      setPriceIds('');
      setAvailableFrom('');
      setAvailableUntil('');
      setPercent('');
      setCodeName('');
      setUsageLimit('');
    }
  };

  useEffect(() => {
    if (currentWLId) dispatch(getUsersForDiscount(currentWLId));
  }, [dispatch, currentWLId]);

  return (
    <div className="modal__discount">
      <h2 style={{ textAlign: 'center' }} className="m-b-40">
        Add new code
      </h2>
      <div className="discount-input__container m-b-25">
        <Input
          placeholder="Code Name"
          style={{ width: '40%', marginRight: '10px' }}
          onChange={(e) => {
            setCodeName(e.target.value);
          }}
        />

        <Input
          placeholder="number of uses"
          style={{ width: '40%', marginLeft: '10px' }}
          onChange={(e) => {
            setUsageLimit(e.target.value);
          }}
        />
      </div>

      <div className="discount-input__container m-b-25">
        <DatePicker
          format={dateFormat}
          defaultValue={moment(availableFrom, dateFormat)}
          placeholder="Available From"
          onChange={(date, dateString) => setAvailableFrom(dateString)}
          style={{ width: '20%', marginRight: '10px' }}
        />
        <DatePicker
          format={dateFormat}
          defaultValue={moment(availableUntil, dateFormat)}
          placeholder="Available Until"
          onChange={(date, dateString) => setAvailableUntil(dateString)}
          style={{ width: '20%' }}
        />
      </div>

      <div className="discount-input__container m-b-25">
        <Input placeholder="Discount % rate" style={{ width: '40%', marginRight: '10px' }} onChange={(e) => setPercent(e.target.value)} />
        <Select
          mode="multiple"
          allowClear
          style={{
            width: '60%',
          }}
          placeholder="Select wich pricing packages"
          onChange={(e) => setPriceIds(e)}
        >
          {pricesList.map((elem) => {
            return <Option key={elem.id}>{elem.skips} skips</Option>;
          })}
        </Select>
      </div>

      <Select
        mode="multiple"
        style={{
          width: '100%',
        }}
        placeholder="Add Users Here"
        onChange={(e) => setUserIds(e)}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        className="m-b-25"
      >
        {discountUsers.map((elem) => {
          return <Option key={elem.id}>{elem.email}</Option>;
        })}
      </Select>

      <div style={{ textAlign: 'right' }}>
        <button className="discount__btn  cancel" onClick={closeModal}>
          cancel
        </button>
        <button className="discount__btn save" onClick={addDiscount}>
          save
        </button>
      </div>
    </div>
  );
};

export default CreateDiscount;
