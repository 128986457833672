import React from 'react'
import classnames from 'classnames'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { ITEMS_PER_PAGE } from '../../../utils/constants'
import { usePagination } from '../../../utils/hooks/usePagination'
import './style.scss';

export const PaginationPanelComponent = ({ pagintationPage, setPaginationPage, totalCount, className, itemsPerPage = ITEMS_PER_PAGE }) => {
  const paginationRange = usePagination({
    currentPageIndex: pagintationPage,
    totalCount,
    itemsPerPage
  })

  const changePaginationPage = (number) => {
    setPaginationPage(number)
  }

  if (paginationRange?.length <= 1) {
    return null
  }

  return (
    <div className={classnames(
      'pagination-container',
      'pagination',
      { [className]: className })}
    >
      <button
        type='button'
        className={classnames(
          'pagination-switcher',
          'pagination-switcher--edge'
        )}
        onClick={() => {changePaginationPage(pagintationPage -1)}}
        disabled={pagintationPage === 1}
      >
        <LeftOutlined />
      </button>

      <ul className='pagination__list'>
        {paginationRange.map((pageNumber, index) => {
          if (typeof pageNumber !== "number") {
            return (
              <li className="pagination__item" key={index}>
                <button
                  type='button'
                  className={classnames(
                    'pagination-switcher',
                    'pagination-switcher--dots'
                  )}
                  disabled={true}
                >
                  ...
                </button>
              </li>
            )
          }

          return (
            <li className='pagination__item' key={index}>
              <button
                type='button'
                className="pagination-switcher"
                onClick={() => {changePaginationPage(pageNumber)}}
                disabled={pagintationPage === pageNumber}
              >
                {pageNumber}
              </button>
              
            </li>
          );
        })}
      </ul>

      <button
        type='button'
        className={classnames(
          'pagination-switcher',
          'pagination-switcher--edge'
        )}
        onClick={() => {changePaginationPage(pagintationPage + 1)}}
        disabled={pagintationPage >= totalCount / itemsPerPage}
      >
        <RightOutlined />
      </button>
    </div>
  )
}