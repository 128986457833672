import React, { useEffect, useState } from 'react';
import Download from '../../../assets/pop-up/table-download.svg';
import InfoIcon from '../../../assets/UI/info_icon.svg';
import CheckBox from '../../../Components/common/Checkbox/Checkbox';
import { nanoid } from 'nanoid';
import Tooltip from '../../../Components/common/Tooltip/Tooltip';
import { downloadBusinessFiles, downloadFiles } from '../../../store/actions/fileActions';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from 'lodash';
import { sliceString } from '../../../utils/functions';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { Tooltip as TooltipAntd } from 'antd';
import './styles.scss';

const Row = ({ subRow, type, id, name, skeleton, results, totalHits, totalRecord, allChecked, index, setAllFilesId, setParentChecked, expired, status }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const width = useResizeWindows();

  useEffect(() => {
    if (checked && !skeleton) {
      setAllFilesId((prev) => _.unionWith([...prev, id], _.isEqual));
    } else if (!checked) {
      setAllFilesId((prev) => prev.filter((item) => item !== id));
    }
  }, [checked, id, setAllFilesId, skeleton, setParentChecked]);

  useEffect(() => {
    if (allChecked) setChecked(true);
    else if (!allChecked) setChecked(false);
  }, [allChecked]);

  const downloadFile = () => {
    if (type) dispatch(downloadBusinessFiles(id, name));
    else dispatch(downloadFiles(id, name));
  };

  return (
    <>
      {skeleton ? (
        <div className="results-row skeleton">
          <CheckBox className="checkbox" setChecked={setChecked} checked={checked} id={nanoid()} disabled={skeleton} />
          <div className="results-tr skeleton-tr" />
        </div>
      ) : (
        <div className="results-row" style={{ position: index ? 'relative' : 'initial' }}>
          <CheckBox className="checkbox" setChecked={setChecked} checked={checked} id={nanoid()} />
          <div className="results-tr">
            <TooltipAntd placement="topLeft" title={name} style={{width:"200px"}}>
              <p className="results-td">
                {sliceString(width, name, 50, 35, 25)}
                {subRow ? (
                  <span>
                    <b>Expiration Date</b> {expired}{' '}
                  </span>
                ) : null}
              </p>
            </TooltipAntd>

            <p className="results-td">{results} Results</p>
            <button className="results-td" onClick={() => setTooltip(!tooltip)}>
              <LazyLoadImage src={InfoIcon} alt="icon" className="action__icon" />
            </button>
            <button className="results-td" onClick={downloadFile}>
              <LazyLoadImage src={Download} alt="icon" className="action__icon" />
            </button>
          </div>
          <Tooltip show={tooltip} setShow={setTooltip} totalHits={totalHits} totalRecord={totalRecord} index={index} status={status} />
        </div>
      )}
    </>
  );
};

export default Row;
