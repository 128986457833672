import React from 'react';

const PricingBg = ({ className }) => {
  return (
    <div className={`svg ${className}`}>
      <svg className="svg" width="2188" height="728" viewBox="0 0 2188 728" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_113_46)">
          <path
            d="M231.631 203.05C409.491 126.87 648.731 70.69 894.081 44.87C1376.08 -5.82996 1919.75 135.56 2103.08 348.14C2189.34 448.14 2169.41 564.97 2007.82 638.29C1888.24 692.55 1710.09 702.9 1537.82 684.59C1392.51 669.14 1260.82 633.81 1118.74 613.5C901.511 582.44 693.001 600.35 475.511 586.91C356.271 579.54 217.431 557.12 135.781 513.4C58.2509 471.91 25.4109 416.21 37.2609 365.85C52.2109 302.29 130.411 246.4 231.631 203.05Z"
            fill="yellow"
          />
          <path
            d="M231.631 203.05C409.491 126.87 648.731 70.69 894.081 44.87C1376.08 -5.82996 1919.75 135.56 2103.08 348.14C2189.34 448.14 2169.41 564.97 2007.82 638.29C1888.24 692.55 1710.09 702.9 1537.82 684.59C1392.51 669.14 1260.82 633.81 1118.74 613.5C901.511 582.44 693.001 600.35 475.511 586.91C356.271 579.54 217.431 557.12 135.781 513.4C58.2509 471.91 25.4109 416.21 37.2609 365.85C52.2109 302.29 130.411 246.4 231.631 203.05Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_113_46"
            x="0.779297"
            y="0.411987"
            width="2186.82"
            height="726.634"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="17" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.501961 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_46" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_46" result="shape" />
          </filter>
          <linearGradient id="SKIPCLOUD" x1="2465.57" y1="544.301" x2="246.508" y2="-792.676" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-1)" />
            <stop offset="1" stopColor="var(--color-2)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default PricingBg;
