import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePickers from '../Statistics/DatePickers/DatePickers';
import { getSummaryStatistics } from '../../../store/actions/whiteLabelActions';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { AppContext } from '../../../appContext';

const columns = [
  {
    title: 'Account Launch Date and Time',
    width: 200,
    dataIndex: 'launched_at_datetime',
    key: 'launched_at_datetime',
    fixed: 'left',
  },
  {
    title: 'White Label Company Name',
    width: 200,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'White Label Parent ID',
    width: 150,
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'White Label Contract Rate',
    width: 150,
    dataIndex: 'contract_rate',
    key: 'contract_rate',
  },
  {
    title: 'Total Number of Users',
    width: 150,
    dataIndex: 'total_number_of_users',
    key: 'total_number_of_users',
  },
  {
    title: 'Total Number of Active Users',
    width: 150,
    dataIndex: 'total_number_of_active_users',
    key: 'total_number_of_active_users',
  },
  {
    title: 'New Users Signed Up',
    width: 150,
    dataIndex: 'new_users_signed_up',
    key: 'new_users_signed_up',
  },
  {
    title: 'Total Skips Purchased',
    width: 150,
    dataIndex: 'total_skips_purchased',
    key: 'total_skips_purchased',
  },
  {
    title: 'Average Cost/Skip',
    width: 150,
    dataIndex: 'avg_cost_skip',
    key: 'avg_cost_skip',
  },
  {
    title: 'Total Revenue',
    width: 150,
    dataIndex: 'total_revenue',
    key: 'total_revenue',
  },
  {
    title: 'Total Revenue For White Label Company',
    width: 150,
    dataIndex: 'total_revenue_for_wl_company',
    key: 'total_revenue_for_wl_company',
  },
  {
    title: 'Company Status',
    width: 150,
    dataIndex: 'status',
    key: 'status',
  },
];

const SummarySuperAdmin = () => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const summary = useSelector((state) => state.whiteLabel.summary);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { collapsed } = useContext(AppContext);
  const id = 'all';

  const startDateHandler = (date, dateString) => {
    setStartDate(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setEndDate(dateString);
  };

  useEffect(() => {
    dispatch(getSummaryStatistics(id, startDate, endDate));
  }, [dispatch, startDate, endDate, id]);

  const tableWidth = useMemo(() => {
    return width < 1279 ? '1000px' : collapsed ? `${width - 250}px` : `${width - 420}px`;
  }, [width, collapsed]);

  return (
    <div className="white-label-table">
      <DatePickers
        startDateHandler={startDateHandler}
        endDateHandler={endDateHandler}
        request={getSummaryStatistics}
        id={id}
        startDate={startDate}
        endDate={endDate}
        dispatch={dispatch}
      />
      <Table
        loading={loading}
        pagination={false}
        style={{
          width: tableWidth,
          margin: '0 auto',
          height: '430px',
          overflowY: 'scroll',
          borderTop: '1px solid var(--main-color)',
        }}
        columns={columns}
        dataSource={summary}
        scroll={{
          x: '1000px',
          y: '400px',
        }}
      />
    </div>
  );
};

export default SummarySuperAdmin;
