import {
  EDIT_BUSINESS_FILE_HEADER_FAILURE,
  EDIT_BUSINESS_FILE_HEADER_REQUEST,
  EDIT_BUSINESS_FILE_HEADER_SUCCESS,
  EDIT_FILE_HEADER_FAILURE,
  EDIT_FILE_HEADER_REQUEST,
  EDIT_FILE_HEADER_SUCCESS,
  LOGOUT_USER,
  POST_BUSINESS_FILES_FAILURE,
  POST_BUSINESS_FILES_REQUEST,
  POST_BUSINESS_FILES_SUCCESS,
  POST_BUSINESS_PROCESS_FAILURE,
  POST_BUSINESS_PROCESS_REQUEST,
  POST_BUSINESS_PROCESS_SUCCESS,
  POST_BUSINESS_SHEET_FAILURE,
  POST_BUSINESS_SHEET_REQUEST,
  POST_BUSINESS_SHEET_SUCCESS,
  POST_FILES_FAILURE,
  POST_FILES_REQUEST,
  POST_FILES_SUCCESS,
  POST_PROCESS_FAILURE,
  POST_PROCESS_REQUEST,
  POST_PROCESS_SUCCESS,
  POST_SHEET_FAILURE,
  POST_SHEET_REQUEST,
  POST_SHEET_SUCCESS,
  REMOVE_BUSINESS_FILES,
  REMOVE_FILES,
} from '../actionsTypes';

const initialState = {
  files: [],
  businessFiles: [],
  pages: 0,
  rows: 0,
  processId: null,
  current_page: 0,
  postLoading: false,
  getLoading: false,
  sheetsLoading: false,
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FILES_REQUEST:
      return { ...state, postLoading: true, error: null, files: [] };
    case POST_FILES_SUCCESS:
      return { ...state, postLoading: false, error: null, files: action.files, rows: action.rows, processId: action.id };
    case POST_FILES_FAILURE:
      return { ...state, postLoading: false, error: action.error };
    case POST_SHEET_REQUEST:
      return { ...state, sheetsLoading: true, error: null };
    case POST_SHEET_SUCCESS:
      return {
        ...state,
        sheetsLoading: false,
        error: null,
        rows: action.rows,
        files: state.files.map((file) => {
          file.sheets.reduce((acc, sheet) => {
            if (sheet.sheet_id === action.sheet.sheet_id) {
              sheet.skip_header = action.sheet.skip_header;
              sheet.status = action.sheet.status;
              sheet.data_matching = action.sheet.data_matching;
            }
            return [...acc, sheet];
          }, []);
          return file;
        }),
      };
    case POST_SHEET_FAILURE:
      return { ...state, sheetsLoading: false, error: action.error };
    case POST_PROCESS_REQUEST:
      return { ...state, postLoading: true, error: null };
    case POST_PROCESS_SUCCESS:
      return { ...state, postLoading: false, error: null };
    case POST_PROCESS_FAILURE:
      return { ...state, postLoading: false, error: action.error };
    case EDIT_FILE_HEADER_REQUEST:
      return { ...state, postLoading: true, error: null };
    case EDIT_FILE_HEADER_SUCCESS:
      return {
        ...state,
        postLoading: false,
        files: state.files.map((file) => {
          file.sheets.reduce((acc, sheet) => {
            if (sheet.sheet_id === action.sheet.sheet_id) {
              sheet.skip_header = action.sheet.skip_header;
            }
            return [...acc, sheet];
          }, []);
          return file;
        }),
        rows: action.rows,
        error: null,
      };
    case EDIT_FILE_HEADER_FAILURE:
      return { ...state, postLoading: false, error: action.error };
    case REMOVE_FILES:
      return { ...state, files: [] };
    //BUSINESS FILES
    case POST_BUSINESS_FILES_REQUEST:
      return { ...state, postLoading: true, error: null, businessFiles: [] };
    case POST_BUSINESS_FILES_SUCCESS:
      return { ...state, postLoading: false, error: null, businessFiles: action.files, rows: action.rows, processId: action.id };
    case POST_BUSINESS_FILES_FAILURE:
      return { ...state, postLoading: false, error: action.error };
    case POST_BUSINESS_SHEET_REQUEST:
      return { ...state, sheetsLoading: true, error: null };
    case POST_BUSINESS_SHEET_SUCCESS:
      return {
        ...state,
        sheetsLoading: false,
        error: null,
        rows: action.rows,
        files: state.businessFiles.map((file) => {
          file?.business_sheets.reduce((acc, sheet) => {
            if (sheet.business_sheet_id === action.sheet.business_sheet_id) {
              sheet.skip_header = action.sheet.skip_header;
              sheet.status = action.sheet.status;
              sheet.data_matching = action.sheet.data_matching;
            }
            return [...acc, sheet];
          }, []);
          return file;
        }),
      };
    case POST_BUSINESS_SHEET_FAILURE:
      return { ...state, sheetsLoading: false, error: action.error };
    case POST_BUSINESS_PROCESS_REQUEST:
      return { ...state, postLoading: true, error: null };
    case POST_BUSINESS_PROCESS_SUCCESS:
      return { ...state, postLoading: false, error: null };
    case POST_BUSINESS_PROCESS_FAILURE:
      return { ...state, postLoading: false, error: action.error };

    case EDIT_BUSINESS_FILE_HEADER_REQUEST:
      return { ...state, postLoading: true, error: null };
    case EDIT_BUSINESS_FILE_HEADER_SUCCESS:
      return {
        ...state,
        postLoading: false,
        files: state.businessFiles.map((file) => {
          file.business_sheets.reduce((acc, sheet) => {
            if (sheet.business_sheet_id === action.sheet.business_sheet_id) {
              sheet.skip_header = action.sheet.skip_header;
            }
            return [...acc, sheet];
          }, []);
          return file;
        }),
        rows: action.rows,
        error: null,
      };
    case EDIT_BUSINESS_FILE_HEADER_FAILURE:
      return { ...state, postLoading: false, error: action.error };

    case REMOVE_BUSINESS_FILES:
      return { ...state, businessFiles: [] };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default fileReducer;
