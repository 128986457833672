import React from 'react';

const FileIcon = () => {
  return (
    <div className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 45.86 54.54">
        <defs>
          <linearGradient id="SKIPCLOUD-ICON" y1="27.83" x2="36.81" y2="27.83" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-1)" />
            <stop offset="1" stopColor="var(--color-2)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
          <linearGradient id="SKIPCLOUD-2" x1="23.54" y1="32.11" x2="44.33" y2="32.11" xlinkHref="#SKIPCLOUD" />
          <linearGradient id="SKIPCLOUD-3" x1="6.26" y1="14.04" x2="38.45" y2="14.04" xlinkHref="#SKIPCLOUD" />
          <linearGradient id="SKIPCLOUD-4" x1="6.26" y1="19.33" x2="38.45" y2="19.33" xlinkHref="#SKIPCLOUD" />
          <linearGradient id="SKIPCLOUD-5" x1="6.26" y1="24.62" x2="38.45" y2="24.62" xlinkHref="#SKIPCLOUD" />
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="SKIPCLOUD-ICON" data-name="SkipCloud">
            <polygon className="cls-1" fill="var(--sub-color)" points="45.86 0 35.24 0 45.86 13.19 45.86 0" />
            <path
              className="cls-2"
              d="M25,36.41l5.81,5.8a1.61,1.61,0,0,0,2.28-2.28L23.15,30l-9.9,9.91a1.61,1.61,0,0,0,0,2.28,1.59,1.59,0,0,0,2.28,0l5.7-5.7v11S21.23,51,18.4,51H2.76V3.8H36.81L34.72,1.12H0V54.54H18.63S25,54.76,25,47.61Z"
            />
            <path className="cls-3" d="M41.43,9.69V51.93H25.93s-1,2.61-2.39,2.61c.08-.07,20.79,0,20.79,0V13.19Z" />
            <path className="cls-4" d="M6.26,14a.49.49,0,0,0,.48.49H38a.49.49,0,1,0,0-1H6.74A.49.49,0,0,0,6.26,14Z" />
            <path className="cls-5" d="M38,18.85H6.74a.49.49,0,0,0,0,1H38a.49.49,0,1,0,0-1Z" />
            <path className="cls-6" d="M38,24.14H6.74a.49.49,0,0,0,0,1H38a.49.49,0,1,0,0-1Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default FileIcon;
