import axiosApi from '../../utils/axios';

import {
  ACTIVE_DISCOUNT_FAILURE,
  ACTIVE_DISCOUNT_REQUEST,
  ACTIVE_DISCOUNT_SUCCESS,
  CHANGE_PRIMARY_CARD_FAILURE,
  CHANGE_PRIMARY_CARD_REQUEST,
  CHANGE_PRIMARY_CARD_SUCCESS,
  CREATE_CARD_FAILURE,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  EDIT_CARD_BILLING_DETAILS_FAILURE,
  EDIT_CARD_BILLING_DETAILS_REQUEST,
  EDIT_CARD_BILLING_DETAILS_SUCCESS,
  GET_CARD_BILLING_DETAILS_FAILURE,
  GET_CARD_BILLING_DETAILS_REQUEST,
  GET_CARD_BILLING_DETAILS_SUCCESS,
  GET_CARDS_FAILURE,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  GET_PAYMENT_FAILURE,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  GET_PRICES_FAILURE,
  GET_PRICES_REQUEST,
  GET_PRICES_SUCCESS,
  GET_PRIMARY_CARD_FAILURE,
  GET_PRIMARY_CARD_REQUEST,
  GET_PRIMARY_CARD_SUCCESS,
} from '../actionsTypes';
import { showNotification } from '../../App';
import history from '../../utils/history';

const getPricesRequest = () => ({ type: GET_PRICES_REQUEST });
const getPricesSuccess = (prices) => ({ type: GET_PRICES_SUCCESS, prices });
const getPricesFailure = (error) => ({ type: GET_PRICES_FAILURE, error });

const getPaymentRequest = () => ({ type: GET_PAYMENT_REQUEST });
const getPaymentSuccess = (payments) => ({ type: GET_PAYMENT_SUCCESS, payments });
const getPaymentFailure = (error) => ({ type: GET_PAYMENT_FAILURE, error });

const getCardsRequest = () => ({ type: GET_CARDS_REQUEST });
const getCardsSuccess = (cards) => ({ type: GET_CARDS_SUCCESS, cards });
const getCardsFailure = (error) => ({ type: GET_CARDS_FAILURE, error });

const changePrimaryCardRequest = () => ({ type: CHANGE_PRIMARY_CARD_REQUEST });
const changePrimaryCardSuccess = (card) => ({ type: CHANGE_PRIMARY_CARD_SUCCESS, card });
const changePrimaryCardFailure = (error) => ({ type: CHANGE_PRIMARY_CARD_FAILURE, error });

const createPaymentMethodRequest = () => ({ type: CREATE_CARD_REQUEST });
const createPaymentMethodSuccess = (card) => ({ type: CREATE_CARD_SUCCESS, card });
const createPaymentMethodFailure = (error) => ({ type: CREATE_CARD_FAILURE, error });

const getPrimaryCardRequest = () => ({ type: GET_PRIMARY_CARD_REQUEST });
const getPrimaryCardSuccess = (card) => ({ type: GET_PRIMARY_CARD_SUCCESS, card });
const getPrimaryCardFailure = (error) => ({ type: GET_PRIMARY_CARD_FAILURE, error });

const getCardBillingDetailsRequest = () => ({ type: GET_CARD_BILLING_DETAILS_REQUEST });
const getCardBillingDetailsSuccess = (billing) => ({ type: GET_CARD_BILLING_DETAILS_SUCCESS, billing });
const getCardBillingDetailsFailure = (error) => ({ type: GET_CARD_BILLING_DETAILS_FAILURE, error });

const editCardBillingDetailsRequest = () => ({ type: EDIT_CARD_BILLING_DETAILS_REQUEST });
const editCardBillingDetailsSuccess = (billing) => ({ type: EDIT_CARD_BILLING_DETAILS_SUCCESS, billing });
const editCardBillingDetailsFailure = (error) => ({ type: EDIT_CARD_BILLING_DETAILS_FAILURE, error });

const activeDiscountRequest = () => ({ type: ACTIVE_DISCOUNT_REQUEST });
const activeDiscountSuccess = () => ({ type: ACTIVE_DISCOUNT_SUCCESS });
const activeDiscountFailure = () => ({ type: ACTIVE_DISCOUNT_FAILURE });

const deleteCardRequest = () => ({ type: DELETE_CARD_REQUEST });
const deleteCardSuccess = () => ({ type: DELETE_CARD_SUCCESS });
const deleteCardFailure = () => ({ type: DELETE_CARD_FAILURE });

export const getPrices = () => {
  return async (dispatch) => {
    try {
      dispatch(getPricesRequest());
      const response = await axiosApi.get('/payment/get-prices');
      dispatch(getPricesSuccess(response?.data?.data));
    } catch (error) {
      dispatch(getPricesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getCards = (loading) => {
  return async (dispatch) => {
    try {
      loading && dispatch(getCardsRequest());
      const response = await axiosApi.get('/payment-details');
      dispatch(getCardsSuccess(response.data.data));
    } catch (error) {
      dispatch(getCardsFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const changePrimaryCard = (id, loading = true) => {
  return async (dispatch) => {
    try {
      dispatch(changePrimaryCardRequest());
      const response = await axiosApi.post(`/payment-details/change-primary/${id}`);
      dispatch(changePrimaryCardSuccess(response.data.data));
      dispatch(getCards(loading));
    } catch (error) {
      dispatch(changePrimaryCardFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const changePrimaryCardStripe = (id) => {
  return async (dispatch) => {
    try {
      dispatch(changePrimaryCardRequest());
      const response = await axiosApi.post(`/payment-details/change-primary/${id}`);
      dispatch(changePrimaryCardSuccess(response.data.data));
    } catch (error) {
      dispatch(changePrimaryCardFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const deleteCard = (id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteCardRequest());
      const response = await axiosApi.delete(`/payment-details/delete/${id}`);
      showNotification('success', response.data.message, 'Delete Card');
      dispatch(deleteCardSuccess());
      dispatch(getCards());
    } catch (error) {
      dispatch(deleteCardFailure());
    }
  };
};

export const createPaymentMethod = (cardData) => {
  return async (dispatch) => {
    try {
      dispatch(createPaymentMethodRequest());
      const response = await axiosApi.post(`/payment-details/new`, cardData);
      dispatch(createPaymentMethodSuccess(response.data.data));
      showNotification('success', 'Created payment methods', 'Created Card');
      return response.data.data.id;
    } catch (error) {
      dispatch(createPaymentMethodFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getPrimaryCard = () => {
  return async (dispatch) => {
    try {
      dispatch(getPrimaryCardRequest());
      const response = await axiosApi.get('/payment-details/primary');
      dispatch(getPrimaryCardSuccess(response.data.data));
      dispatch(getCards());
    } catch (error) {
      dispatch(getPrimaryCardFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const updateBillingAddressCard = (cardData, id) => {
  return async (dispatch) => {
    try {
      dispatch(editCardBillingDetailsRequest());
      const response = await axiosApi.put(`/billing-address/edit/${id}`, cardData);
      dispatch(editCardBillingDetailsSuccess(response.data.data));
      showNotification('success', response.data.message, 'Billing Details');
    } catch (error) {
      dispatch(editCardBillingDetailsFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getBillingAddressCard = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getCardBillingDetailsRequest());
      const response = await axiosApi.get(`/billing-address/${id}`);
      dispatch(getCardBillingDetailsSuccess(response.data.data));
    } catch (error) {
      history.push('/profile/billing');
      dispatch(getCardBillingDetailsFailure(error?.response?.data?.errors?.message || true));
      showNotification('warning', error?.response?.data?.errors?.message || true, 'Warning');
    }
  };
};

export const activeDiscount = (data) => {
  return async (dispatch) => {
    try {
      dispatch(activeDiscountRequest());
      const response = await axiosApi.put(`/payment/activate-discount-calculate`, data);
      dispatch(activeDiscountSuccess());
      dispatch(getPricesRequest());
      const prices = await axiosApi.get('/payment/get-prices');
      dispatch(getPricesSuccess(prices.data.data));
      showNotification('success', response.data.message, 'Promo-code');
      return response.data.data;
    } catch (error) {
      dispatch(activeDiscountFailure(error?.response?.data?.errors?.message || true));
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
      return false;
    }
  };
};

export const getPayment = (wlID, userId, page) => {
  return async (dispatch) => {
    try {
      dispatch(getPaymentRequest());
      const response = await axiosApi.get(`/admin/white-label/${wlID}/users/show-payment/${userId}?page=${page}`);
      const info = response.data.data;
      const pages = response.data.pages;
      dispatch(
        getPaymentSuccess({
          info,
          pages,
        })
      );
    } catch (error) {
      dispatch(getPaymentFailure());
    }
  };
};
