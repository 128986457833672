import React, { useContext } from 'react';
import NavigationSection from '../../Components/common/LandingHeader/NavigationSection';
import PeopleCard from './PeopleCard';
import './styles.scss';
import money from '../../assets/landing/money.png';
import search from '../../assets/landing/search.png';
import time from '../../assets/landing/time.png';
import phone from '../../assets/landing/phone.png';
import { Link } from 'react-router-dom';
import { SignUpLink } from '../Landing/Landing';
import { ReactComponent as ReactAheadLogoSvg } from '../../assets/white-label/icons/icon-landing-ahead.svg';
import FooterSection from '../../Components/common/LandingFooter/footerSection';
import coryPhoto from '../../assets/landing/cory-photo.png';
import omarPhoto from '../../assets/landing/omar-photo.png';
import jtPhoto from '../../assets/landing/jt-photo.png';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';

const aboutUsContent = [
  {
    id: 1,
    photoLink: omarPhoto,
    name: 'omar',
    followName: 'Omar',
    surName: 'De Santos',
    role: 'founder',
    description: `Being an Ex-NASA engineer, Omar has always had big
    aspirations from a young age. Choosing to leave NASA to go full-time in his Real Estate business was a pivotal point in his life. After building an 8-Figure wholesaling operation, Omar always stressed to others who’d ask for advice the importance of good,
    accurate, and updated data to ensure success. This led to Omar to the birth of SkipCloud - The smart way to find people.`,
    socialLink: 'https://www.instagram.com/',
  },
  {
    id: 2,
    photoLink: coryPhoto,
    name: 'cory',
    followName: 'Cory',
    surName: 'reed',
    role: 'founder',
    description: `After serving in the military, Cory cofounded The Moirai Group and scaled the company to 8 figures and one of the nation’s largest residential real estate wholesaling operations. This success and the realization of the importance of quality data gave birth to SkipCloud, the nation’s premier data provider. Cory launched SkipCloud to supply others with the same quality of data that provided him the opportunity to do over 1,000 wholesale real estate transactions and acquire 1,000 units of multifamily and MHP’s.`,
    socialLink: 'https://www.instagram.com/',
  },
  {
    id: 3,
    photoLink: jtPhoto,
    name: 'jt',
    followName: 'joseph',
    surName: 'martin',
    role: 'founder',
    description: `After helping multiple businesess owners build Multi-Million dollar brands, JT partnered with Cory and Omar to bring SkipCloud to life. With over 10 years of brand design experience, JT has poured his creativity into creating an amazing user experience for those who are looking for a new and refreshing skip tracing solution. After months of research, JT believes SkipCloud is the best of the best when it comes to finding accurate data.`,
    socialLink: 'https://www.instagram.com/',
  },
];

const optionsContent = [
  {
    id: 1,
    desciption: `We were tired of getting our data searches back and finding
  nothing but `,
    underlineDescription: 'innacurate phone numbers.',
    class: 'phone',
    iconUrl: phone,
  },
  {
    id: 2,
    desciption: `Other Skip Tracing softwares were confusing, difficult to
  navigate around, and just `,
    underlineDescription: 'didn’t feel right. ',
    class: 'search',
    iconUrl: search,
  },
  {
    id: 3,
    desciption: ` in our business, so we found a way to
  get extremely accurate results, instantly.`,
    underlineDescription: 'Time is of the essence',
    class: 'time',
    iconUrl: time,
  },
  {
    id: 4,
    desciption: `With the amount of data we’re pulling in our company on a monthly basis, we wanted better pricing but couldn’t find it... `,
    underlineDescription: 'so we created it.',
    class: 'money',
    iconUrl: money,
  },
];

const AboutUs = () => {
  const { brand } = useContext(AppContext);

  return (
    <div className="about-us">
      <NavigationSection
        headHeader="MEET THE TEAM"
        headText="Helpful tools created from over a century of combined "
        isSubHeader={true}
        subHeader="experience from the SkipCloud Team!"
      />
      <section className="about-us__section">
        <div className="people-cards">
          {aboutUsContent.map((person) => (
            <PeopleCard
              key={person.id}
              desciption={person.description}
              name={person.name}
              role={person.role}
              surname={person.surName}
              imgUrl={person.photoLink}
              instaUrl={person.socialLink}
              followName={person.followName}
            />
          ))}
        </div>
      </section>
      <div className="block"></div>
      <section className="why__skipcloud__container">
        <h1>WHY {brand.toUpperCase()}?</h1>
        <p>
          Building an <b>8-Figure Wholesaling Operation</b> & Dealing With The Inefficiencies Of 99% OF Skip Tracing Softwares Led To Building This...
        </p>
      </section>
      <section className="options">
        {optionsContent.map((item) => (
          <div className="options__item">
            <div className="options__item__img">
              <img src={item.iconUrl} alt="img" className={item.class} />
            </div>
            {item.id === 3 ? (
              <>
                <span className="underline__text">{item.underlineDescription}</span>
                <span>{item.desciption}</span>
              </>
            ) : (
              <>
                <span>{item.desciption}</span>
                <span className="underline__text">{item.underlineDescription}</span>
              </>
            )}
            <p></p>
          </div>
        ))}
      </section>
      <div className="about__account-container">
        <div className="about__account-svg-container">
          <ReactAheadLogoSvg />
        </div>
        <h4 className="contact__account-header">
          <span>Haven't used</span>
          <b>{toCapitalize(brand)}</b>
          <span>yet?</span>
        </h4>
        <SignUpLink text={`Show me what i'm missing out on!`} className="about__login-button navigation-section__login-link-button" />
        <span>
          We'll
          <Link to={'/sign-up'} className="about__login-link navigation-section__login-link">
            make you an account
          </Link>
          for FREE so you can check it out first!
        </span>
      </div>
      <FooterSection />
    </div>
  );
};

export default AboutUs;
