import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editWLPriceCard } from '../../../../store/actions/adminActions';
import { Select } from 'antd';
import './styles.scss';
const { Option } = Select;

const SingleCard = ({ whiteLabelId, card, haveAccess }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [price, setPrice] = useState(card.price / 100 || '');
  const [skips, setSkips] = useState(card.skips || '');
  let [cardType, setCardType] = useState(card.type || '');

  const cardTypeMock = [
    {
      type: 0,
      name: 'default',
    },
    {
      type: 1,
      name: 'Popular Option',
    },
    {
      type: 2,
      name: 'Best Deal',
    },
  ];
  let cardTypeText = '';

  if (card.type === 'default') {
    cardTypeText = 'default';
  } else if (card.type === 'popular_option') {
    cardTypeText = 'Popular Option';
  } else if (card.type === 'best_deal') {
    cardTypeText = 'Best Deal';
  }

  const updateCard = async (e) => {
    e.stopPropagation();

    if (cardType === 'default') cardType = '0';
    else if (cardType === 'popular_option') cardType = '1';
    else if (cardType === 'best_deal') cardType = '2';
    const response = await dispatch(editWLPriceCard(whiteLabelId, card.id, { price: price * 100, skips, type: cardType }));
    if (response) {
      setEdit(false);
      setPrice(card.price / 100);
      setSkips(card.skips);
      setCardType(card.type);
    }
  };

  const editForm = (
    <>
      <span>
        <input className="edit__input" placeholder="Price" value={price} type="number" onChange={(e) => setPrice(e.target.value)} />
      </span>
      <span className="edit__item">
        <input className="edit__input" placeholder="Skips" value={skips} type="number" onChange={(e) => setSkips(e.target.value)} />
      </span>
    </>
  );

  return (
    <>
      {haveAccess ? (
        <li className="prices__cards__item" style={{ padding: '5px 0 5px 0' }}>
          {!edit ? (
            <>
              <span>$ {card?.price / 100}</span>
              <span>{card?.skips}</span>
            </>
          ) : (
            editForm
          )}
          <span>{card?.per_hit}</span>
          <span>
            {!edit ? (
              cardTypeText
            ) : (
              <Select
                defaultValue={cardTypeText}
                value={cardType}
                style={{
                  width: '120px',
                }}
                onChange={(e) => setCardType(e)}
              >
                {cardTypeMock.map((item) => (
                  <Option key={item.type}>{item.name}</Option>
                ))}
              </Select>
            )}
          </span>
          <span>
            <button onClick={() => setEdit(!edit)} className="updatecard_button">
              {edit ? 'cancel' : 'edit'}
            </button>
            {edit && (
              <button onClick={updateCard} className="updatecard_button">
                save
              </button>
            )}
          </span>
        </li>
      ) : (
        <li className="prices__cards__item" style={{ padding: '5px 0 5px 0' }}>
          <span>$ {card?.price / 100}</span>
          <span>{card?.skips}</span>
          <span>{card?.per_hit}</span>
          <span>{cardTypeText}</span>
        </li>
      )}
    </>
  );
};

export default SingleCard;
