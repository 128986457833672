import React, { useContext } from 'react';
import { DatePicker } from 'antd';
import './styles.scss';
import { AppContext } from '../../../../appContext';

const DatePickers = ({ dispatch, startDate, startDateHandler, endDateHandler, endDate, id, request }) => {
  const { isUserBasic } = useContext(AppContext);

  return (
    <div className="white-label-table__header">
      <div className="white-label-table__dates">
        <DatePicker onChange={startDateHandler} />
        <DatePicker onChange={endDateHandler} />
      </div>
      {!isUserBasic && (
        <button className="white-label-table__csv" onClick={() => dispatch(request(id, startDate, endDate, true))}>
          Download CSV
        </button>
      )}
    </div>
  );
};

export default DatePickers;
