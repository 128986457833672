import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import WarningIcon from '../../../assets/pop-up/warningIcon.svg';
import Card from '../Card/Card';
import StripeModal from '../StripeModal/StripeModal';
import { useDispatch, useSelector } from 'react-redux';
import { getPrices } from '../../../store/actions/paymentActions';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.scss';

const InsufficientPopUp = ({ show, setShow }) => {
  const dispatch = useDispatch();
  const prices = useSelector((state) => state?.payment.prices);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalSkips, setTotalSkips] = useState(0);
  const [visible, setVisible] = useState(false);
  const [cart, setCart] = useState([]);
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';
  const [defaultCart, setDefaultCart] = useState([]);

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  const checkoutHandler = () => {
    if (cart.length) {
      setShow(false);
      setVisible(true);
    }
  };

  return (
    <>
      <Modal
        bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px' }}
        className="insufficient__modal"
        visible={show}
        onCancel={() => setShow(false)}
        footer={null}
        width={modalWidth}
      >
        <div className="insufficient">
          <div className="insufficient__header">
            <h3>
              <LazyLoadImage src={WarningIcon} alt="warning" className="warning" />
              Insufficient <span>Skips</span>
            </h3>
            <h5>
              <span>Add more skips to continue, or </span>
              <Link to="/dashboard" onClick={() => setShow(false)}>
                save as a draft
              </Link>
              <span> and come back later</span>
            </h5>
          </div>

          <div className="insufficient__body">
            {prices.map(({ id, price, per_hit, skips, type, discount_id }, index) => (
              <Card
                cartActive={cart.length}
                id={id}
                key={index}
                price={price}
                perHit={per_hit}
                skips={skips}
                discount_id={discount_id}
                type={type}
                setTotalOrder={setTotalOrder}
                setTotalSkips={setTotalSkips}
                setArray={setCart}
                cart={cart}
                setDefaultCart={setDefaultCart}
              />
            ))}
          </div>
        </div>

        <div className="insufficient__footer">
          <div className="insufficient__footer-total">
            <p>{totalSkips} Skips</p>
            <p>Total ${totalOrder}.00</p>
          </div>
          <p className="insufficient__footer-checkout" onClick={checkoutHandler}>
            Go To Checkout
          </p>
        </div>
      </Modal>
      <StripeModal
        defaultCart={defaultCart}
        show={visible}
        setShow={setVisible}
        cart={cart}
        setCart={setCart}
        insufficientPopUp={show}
        setInsufficientPopUp={setShow}
        setDefaultCart={setDefaultCart}
      />
    </>
  );
};

export default InsufficientPopUp;
