import React from 'react';
import Helper from '../Helper/Helper';
import './styles.scss';

const SslField = ({ placeholder, value, sslText, onChange, disabled = false, showSSLText }) => {
  return (
    <label className="white-label-label">
      <p>{placeholder}</p>
      <div className="white-label-label__inner">
        <input placeholder={placeholder} type="text" value={value} onChange={onChange} className="white-label-field" disabled={disabled} />
        <Helper title={placeholder} tooltipWidth="100%" sslText={sslText} showSSLText={showSSLText} />
      </div>
    </label>
  );
};

export default SslField;
