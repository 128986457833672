import React from 'react';
import { postBusinessFilesRequest, postBusinessFilesSuccess, postFilesRequest, postFilesSuccess } from '../../../store/actions/fileActions';
import { useDispatch } from 'react-redux';
import history from '../../../utils/history';
import './styles.scss';
import { Tooltip } from 'antd';

const Status = ({ id, status, total_count_rows, files, error_description, type }) => {
  const dispatch = useDispatch();

  const continueDataMatching = () => {
    if (type) {
      dispatch(postBusinessFilesRequest());
      dispatch(postBusinessFilesSuccess(files, total_count_rows, id));
      history.push(`/data-matching/business/${id}`);
    } else {
      dispatch(postFilesRequest());
      dispatch(postFilesSuccess(files, total_count_rows, id));
      history.push(`/data-matching/people/${id}`);
    }
  };

  return (
    <>
      {status === 'error' && error_description !== 'Did not have skips for operations' ? (
        <Tooltip title={error_description} placement="top">
          <div className={`status status-${status}`}>
            <p>error</p>
          </div>
        </Tooltip>
      ) : status === 'completed' ? (
        <div className={`status status-${status}`}>
          <p>complete</p>
        </div>
      ) : status === 'draft' || (status === 'error' && error_description === 'Did not have skips for operations') ? (
        <div className={`status status-${status === 'error' ? 'draft' : status}`} onClick={continueDataMatching}>
          <p>draft</p>
        </div>
      ) : status === 'processing' ? (
        <div className={`status status-${status}`}>
          <p>processing</p>
        </div>
      ) : (
        <div className={`status status-${status}`}>
          <p>{status}</p>
        </div>
      )}
    </>
  );
};

export default Status;
