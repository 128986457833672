import React, { useEffect, useState } from 'react';
import StarIcon from '../../../assets/pop-up/star.svg';
import DollarIcon from '../../../assets/landing/dollar-price.svg';

import './styles.scss';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PopularOption = () => {
  return (
    <div className={`addmore-skips-card-option addmore-skips-card-option--popular`}>
      <LazyLoadImage src={StarIcon} alt="star" style={{ width: '20px', height: '19px', marginRight: '10px' }} />
      <p>Popular option</p>
    </div>
  );
};

const BestOption = () => {
  return (
    <div className={`addmore-skips-card-option addmore-skips-card-option--best`}>
      <LazyLoadImage src={DollarIcon} alt="star" style={{ width: '20px', height: '19px', marginRight: '10px' }} />
      <p>Best deal</p>
    </div>
  );
};

const PriceCard = ({ discount_id, skips, price, perHit, type, setTotalOrder, setTotalSkips, setArray, id, cartActive, setDefaultCart }) => {
  const [quantity, setQuantity] = useState(1);
  const [active, setActive] = useState(false);

  const addToCart = () => {
    setArray((prev) => [...prev, { skips, id, perHit, quantity, price, discount_id }]);
    setDefaultCart((prev) => [...prev, { skips, id, perHit, quantity, price, discount_id }]);
    setActive(true);
    setTotalOrder((prev) => prev + (quantity * price) / 100);
    setTotalSkips((prev) => prev + skips * quantity);
  };

  const removeToCart = () => {
    setArray((prev) => prev.filter((item) => item.id !== id));
    setDefaultCart((prev) => prev.filter((item) => item.id !== id));
    setActive(false);
    setTotalOrder((prev) => prev - (quantity * price) / 100);
    setTotalSkips((prev) => prev - skips * quantity);
  };

  useEffect(() => {
    if (!cartActive) {
      setActive(false);
      setTotalOrder(0);
      setTotalSkips(0);
    }
  }, [cartActive, setTotalOrder, setTotalSkips]);

  const TYPE_OPTION = {
    best_deal: <BestOption />,
    popular_option: <PopularOption />,
    default: null,
  };

  return (
    <div className="addmore-skips-card">
      {TYPE_OPTION[type]}
      <header className={classnames('addmore-skips-card__header', { special__header: type !== 'default' })}>
        <h3 className="addmore-skips-card__header-heading">
          <b>{new Intl.NumberFormat('en').format(skips)}</b>
          {` SKIPS`}
        </h3>
      </header>
      <div className="addmore-skips-card__content-container">
        <p className={`${type !== 'default' ? '' : 'addmore-skips-card__price-default'} addmore-skips-card__price-value`}>
          ${price / 100}
          <sup>{price.toString().slice(-2)}</sup>
        </p>
        <p className="addmore-skips-card__price-skip">${perHit} PER HIT</p>
        <div className="addmore-skips-card__button-container">
          <div className="addmore-skips-card__count-container">
            <button className={`price-card__count-inner-btn ${active ? 'active' : ''}`} onClick={active ? removeToCart : addToCart}>
              ADD TO CART
            </button>
            <div className="addmore-skips-card__count-inner">
              <button onClick={() => setQuantity((prev) => (prev - 1 <= 0 ? prev : prev - 1))} disabled={active}>
                -
              </button>
              <p>{quantity}</p>
              <button onClick={() => setQuantity((prev) => (prev < 10 ? prev + 1 : prev))} disabled={active}>
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PriceCard;

/* <div className="price-card__footer">
          <p className="m-t-10 m-b-30">${perHit} PER HIT</p>
          <div className="price-card__count">
            <div className="price-card__count-inner">
              <button className={`price-card__count-inner-btn ${active ? 'active' : ''}`} onClick={active ? removeToCart : addToCart}>
                ADD TO CART
              </button>
              <div className="price-card__count-inner-buttons">
                <button onClick={() => setQuantity((prev) => (prev - 1 <= 0 ? prev : prev - 1))} disabled={active}>
                  -
                </button>
                <p>{quantity}</p>
                <button onClick={() => setQuantity((prev) => (prev < 10 ? prev + 1 : prev))} disabled={active}>
                  +
                </button>
              </div>  
            </div>
          </div>
        </div> */
