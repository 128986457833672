import {
  ADD_IMAGE_WL_FAILURE,
  ADD_IMAGE_WL_REQUEST,
  ADD_IMAGE_WL_SUCCESS,
  CREATE_WL_FAILURE,
  CREATE_WL_REQUEST,
  CREATE_WL_SUCCESS,
  EDIT_WL_FAILURE,
  EDIT_WL_REQUEST,
  EDIT_WL_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_PAYMENT_STATISTICS_FAILURE,
  GET_PAYMENT_STATISTICS_REQUEST,
  GET_PAYMENT_STATISTICS_SUCCESS,
  GET_SKIPTRACE_STATISTICS_FAILURE,
  GET_SKIPTRACE_STATISTICS_REQUEST,
  GET_SKIPTRACE_STATISTICS_SUCCESS,
  GET_SOLO_WL_FAILURE,
  GET_SOLO_WL_REQUEST,
  GET_SOLO_WL_SUCCESS,
  GET_SUMMARY_STATISTICS_FAILURE,
  GET_SUMMARY_STATISTICS_REQUEST,
  GET_SUMMARY_STATISTICS_SUCCESS,
  GET_WL_FAILURE,
  GET_WL_REQUEST,
  GET_WL_SUCCESS,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  whiteLabels: [],
  payments: [],
  payments_current_page: 0,
  payments_pages: 0,
  summary: [],
  summary_current_page: 0,
  summary_pages: 0,
  skiptrace: [],
  skiptrace_current_page: 0,
  skiptrace_pages: 0,
  whiteLabel: null,
  employees: [],
  employees_pages: 1,
};

const whiteLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WL_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_WL_SUCCESS:
      return { ...state, loading: false, error: null, whiteLabels: action.whiteLabels };
    case GET_WL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_SOLO_WL_REQUEST:
      return { ...state, loading: true, error: null, whiteLabel: null };
    case GET_SOLO_WL_SUCCESS:
      return { ...state, loading: false, error: null, whiteLabel: action.whiteLabel };
    case GET_SOLO_WL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CREATE_WL_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_WL_SUCCESS:
      return { ...state, loading: false, error: null };
    case CREATE_WL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case ADD_IMAGE_WL_REQUEST:
      return { ...state, loading: true, error: null };
    case ADD_IMAGE_WL_SUCCESS:
      return { ...state, loading: false, error: null };
    case ADD_IMAGE_WL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case EDIT_WL_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_WL_SUCCESS:
      return { ...state, loading: false, error: null };
    case EDIT_WL_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_PAYMENT_STATISTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PAYMENT_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payments: action.payments.data,
        payments_pages: action.payments.pages,
        payments_current_page: action.payments.current_page,
      };
    case GET_PAYMENT_STATISTICS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_SUMMARY_STATISTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SUMMARY_STATISTICS_SUCCESS:
      return { ...state, loading: false, error: null, summary: action.summary };
    case GET_SUMMARY_STATISTICS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_SKIPTRACE_STATISTICS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SKIPTRACE_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        skiptrace: action.skiptrace.data,
        skiptrace_pages: action.skiptrace.pages,
        skiptrace_current_page: action.skiptrace.current_page,
      };
    case GET_SKIPTRACE_STATISTICS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_EMPLOYEES_REQUEST:
      return { ...state, loading: true, error: null, employees: [], employees_pages: 1 };
    case GET_EMPLOYEES_SUCCESS:
      return { ...state, loading: false, error: null, employees: action.employees.data, employees_pages: action.employees.pages };
    case GET_EMPLOYEES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
};

export default whiteLabelReducer;
