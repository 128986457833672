import React, { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import './styles.scss';

const Helper = ({ text, tooltipWidth, title, images = [], sslText, showSSLText = false }) => {
  const [active, setActive] = useState(false);
  const [visible, setVisible] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => {
    setActive(false);
    setVisible(false);
  });

  return (
    <>
      <span className="helper" onClick={() => setActive(!active)}>
        <QuestionCircleOutlined className="helper__icon" />
      </span>

      {active && (
        <div className="helper__modal" style={{ width: tooltipWidth }} ref={ref}>
          <h4>{title}</h4>
          <p> {text}</p>
          {images.length ? <Image preview={{ visible: false }} width={200} src={images[0]} onClick={() => setVisible(true)} /> : null}
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
              {images.length ? images.map((item, index) => <Image key={index} src={item} />) : null}
            </Image.PreviewGroup>
          </div>
          {showSSLText && (
            <div className="ssl-helper__container">
              <div>----- BEGIN {sslText} -----</div>
              <div> YOUR {sslText} </div>
              <div>----- END {sslText} -----</div>
            </div>
          )}

          <button className="helper__modal-button" onClick={() => setActive(false)}>
            <CloseOutlined />
          </button>
        </div>
      )}
    </>
  );
};

export default Helper;
