import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentStatistics } from '../../../../store/actions/whiteLabelActions';
import { Spin, Table } from 'antd';
import Navigation from '../Navigation/Navigation';
import DatePickers from '../DatePickers/DatePickers';
import { AppContext } from '../../../../appContext';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { PaginationPanelComponent } from '../../../common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE } from '../../../../utils/constants';
import _ from 'lodash';
import NoResult from '../../../common/NoResult/NoResult';

const columns = [
  {
    title: 'Transaction ID',
    width: 70,
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    fixed: 'left',
  },
  {
    title: 'Date and Time',
    width: 200,
    dataIndex: 'date_and_time',
    key: 'date_and_time',
    fixed: 'left',
  },
  {
    title: 'User ID',
    width: 70,
    dataIndex: 'user_id',
    key: 'user_id',
  },

  {
    title: 'User',
    width: 100,
    dataIndex: 'user_first_name',
    key: 'user_first_name',
  },
  {
    title: 'User Email',
    width: 200,
    dataIndex: 'user_email',
    key: 'user_email',
  },
  {
    title: 'User Type',
    width: 200,
    dataIndex: 'wl_domain',
    key: 'wl_domain',
  },

  {
    title: 'White Label Company Name',
    width: 100,
    dataIndex: 'wl_name',
    key: 'wl_name',
  },

  {
    title: 'White Label Parent ID',
    width: 70,
    dataIndex: 'wl_id',
    key: 'wl_id',
  },

  {
    title: 'White Label Contract Rate',
    width: 100,
    dataIndex: 'wl_contract_rate',
    key: 'wl_contract_rate',
  },

  {
    title: 'Package Description',
    width: 100,
    dataIndex: 'package_id',
    key: 'package_id',
  },
  {
    title: 'Promo Code Used?',
    width: 100,
    dataIndex: 'promocode_used',
    key: 'promocode_used',
  },

  {
    title: 'Promo Code',
    width: 100,
    dataIndex: 'promocode',
    key: 'promocode',
  },

  {
    title: 'Normal Price per Skip',
    width: 100,
    dataIndex: 'normal_price_per_skip',
    key: 'normal_price_per_skip',
  },

  {
    title: 'Actual Price per Skip Charged',
    width: 100,
    dataIndex: 'actual_price_per_skip_charged',
    key: 'actual_price_per_skip_charged',
  },
  {
    title: 'Number of Skips in each Package',
    width: 100,
    dataIndex: 'number_of_skips_in_each_package',
    key: 'number_of_skips_in_each_package',
  },
  {
    title: 'Package Units Purchased',
    width: 100,
    dataIndex: 'number_of_packages_purchased',
    key: 'number_of_packages_purchased',
  },

  {
    title: 'Total Skips Purchased',
    width: 100,
    dataIndex: 'total_skips_puchased',
    key: 'total_skips_puchased',
  },

  {
    title: 'Sub Total Amount Paid',
    width: 100,
    dataIndex: 'sub_total_amount_paid',
    key: 'sub_total_amount_paid',
  },

  {
    title: 'Total Tax Amount Paid',
    width: 100,
    dataIndex: 'total_tax_amount_paid',
    key: 'total_tax_amount_paid',
  },
  {
    title: 'Total Amount Paid',
    width: 100,
    dataIndex: 'total_amount_paid',
    key: 'total_amount_paid',
  },
  {
    title: 'Card Last 4 Used',
    width: 100,
    dataIndex: 'card_last4_used',
    key: 'card_last4_used',
  },
];

const Transaction = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.whiteLabel.payments);

  const pages = useSelector((state) => state.whiteLabel.payments_pages);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const [startDate, setStartDate] = useState('2022-08-01');
  const [endDate, setEndDate] = useState('2022-10-01');
  const [page, setPage] = useState(1);
  const { collapsed } = useContext(AppContext);
  const width = useResizeWindows();

  const startDateHandler = (date, dateString) => {
    setStartDate(dateString);
  };

  const endDateHandler = (date, dateString) => {
    setEndDate(dateString);
  };

  useEffect(() => {
    if (id) dispatch(getPaymentStatistics(id, startDate, endDate, false, page));
  }, [dispatch, startDate, endDate, id, page]);

  // fix bug
  const tableWidth = useMemo(() => {
    return width < 1279 ? '1200px' : collapsed ? `${width - 250}px` : `${width - 420}px`;
  }, [width, collapsed]);

  return (
    <Spin spinning={loading}>
      <div className="white-label-table">
        <Navigation id={id} />
        {!loading && (
          <>
            {_.isEmpty(payments.payments) ? (
              <NoResult text="transaction history is empty" />
            ) : (
              <>
                <DatePickers
                  startDateHandler={startDateHandler}
                  endDateHandler={endDateHandler}
                  request={getPaymentStatistics}
                  id={id}
                  startDate={startDate}
                  endDate={endDate}
                  dispatch={dispatch}
                />
                <Table
                  loading={loading}
                  pagination={false}
                  columns={columns}
                  dataSource={payments.payments}
                  style={{
                    width: tableWidth,
                    margin: '0 auto',
                    height: '400px',
                    borderTop: '1px solid var(--main-color)',
                  }}
                  scroll={{
                    x: '1000px',
                    y: '370px',
                  }}
                  tableLayout="fixed"
                  rowKey="transaction_id"
                />
              </>
            )}
          </>
        )}

        <div className="m-t-auto m-l-auto m-r-10">
          <PaginationPanelComponent pagintationPage={page} setPaginationPage={setPage} totalCount={pages * ITEMS_PER_PAGE} itemsPerPage={ITEMS_PER_PAGE} />
        </div>
      </div>
    </Spin>
  );
};

export default Transaction;
