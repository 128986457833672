import React, { useState } from 'react';
import Visa from '../../../assets/payment/visa.svg';
import Amex from '../../../assets/payment/amex.svg';
import MasterCard from '../../../assets/payment/master-card.svg';
import Discover from '../../../assets/payment/discover.svg';
import { activeDiscount, changePrimaryCardStripe } from '../../../store/actions/paymentActions';
import { useDispatch } from 'react-redux';
import DiscountHelper from './DiscountHelper/DiscountHelper';

const ChoseCards = ({ cards = [], setStripeModal, setCurrentCard, cart, setCart, setCodeActive, currentCard }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [code, setCode] = useState('');
  const [codeArray, setCodeArray] = useState([]);

  const activeDiscountHandler = async () => {
    const priceIds = cart.map((item) => item.id);
    const response = await dispatch(activeDiscount({ code, priceIds }));

    if (response) {
      const array = [];

      for (let i = 0; i < cart.length; i++) {
        for (let j = 0; j < response.length; j++) {
          if (cart[i].id === response[j].id) {
            cart[i].price = response[j].price;
          }
        }
        array.push(cart[i]);
      }

      setCart(array);
      setCodeArray(response);

      setError(false);
      setCodeActive(true);
    } else {
      setError(true);
      setCodeActive(false);
    }
  };

  return (
    <div className="chose-cards">
      <h2>
        Please enter your <span>Payment Details</span>
      </h2>
      <ul className="chose-cards__list">
        <li className="chose-cards__list-item">
          <h3>Default Card</h3>
          <h3>Credit Card Number</h3>
          <h3>Cardholder Name</h3>
          <h3>Exp.Date</h3>
        </li>
        {cards.map(({ last4, card_holder_name, exp_month, exp_year, brand, id }) => (
          <li className="chose-cards__list-item" key={id}>
            <input
              type="radio"
              name="options"
              value={last4}
              checked={currentCard === id}
              onChange={() => {
                setCurrentCard(id);
                dispatch(changePrimaryCardStripe(id));
              }}
            />
            <p>
              <img
                src={
                  brand.toLowerCase() === 'visa' ? Visa : brand.toLowerCase() === 'amex' ? Amex : brand.toLowerCase() === 'mastercard' ? MasterCard : Discover
                }
                alt="brand"
              />
              {last4}
            </p>
            <p>{card_holder_name}</p>
            <p>
              {exp_month.toString().length === 1 ? '0' + exp_month : exp_month}/{exp_year}
            </p>
          </li>
        ))}
      </ul>

      <div className="discount-code">
        <div className="discount-code__field">
          <input value={code} onChange={(e) => setCode(e.target.value)} type="text" placeholder="Enter coupon/promotional code here" />
        </div>
        {codeArray.length ? <DiscountHelper cards={codeArray} /> : null}

        <button type="button" onClick={activeDiscountHandler}>
          Apply code
        </button>

        {error && <p>*Error: Code expired</p>}
      </div>
      <button className="chose-cards-button" onClick={() => setStripeModal(true)}>
        Pay with a new card
      </button>
    </div>
  );
};

export default ChoseCards;
