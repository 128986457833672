import React from 'react';

const SearchIcon = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width={15} height={20} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 15.6 20.86">
        <defs>
          <linearGradient id="SKIPCLOUD-ICON" x1="2.96" y1="6.32" x2="15.6" y2="6.32" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-1)" />
            <stop offset="1" stopColor="var(--color-2)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
          <linearGradient id="SKIPCLOUD-ICON" x1="0.8" y1="15.58" x2="6.5" y2="15.58" xlinkHref="#SKIPCLOUD_2" />
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <circle className="cls-1" cx="9.28" cy="6.32" r="5.32" fill="transparent" stroke="var(--main-color)" strokeWidth={2} />
            <path className="cls-2" d="M5.21,10.85.79,18.13l-.64,1a1.15,1.15,0,0,0,.4,1.54,1.14,1.14,0,0,0,1.54-.4L6.52,13l.63-1a1.13,1.13,0,1,0-1.94-1.14Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SearchIcon;
