import React from 'react';
import './styles.scss';

const CheckBox = ({ id = 0, checked, setChecked, text, className, disabled = false }) => {
  return (
    <span className={`form-group ${className}`}>
      <input type="checkbox" id={id} checked={checked} onChange={() => setChecked(!checked)} disabled={disabled} />
      <label className="checkbox-label" htmlFor={id}>
        {text}
      </label>
    </span>
  );
};

export default CheckBox;
