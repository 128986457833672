export const domainText = `
This is the URL of your site, that is, these are the criteria by which the user can find your site
`;
export const youtubeText = 'Link to youtube for authentication pages Youtube icon was displayed when clicking on it, the user will visit your YouTube channel';

export const instagramText =
  'Link to youtube for authentication pages Instagram icon was displayed when clicking on it, the user will visit your Instagram channel';

export const facebookText =
  'Link to youtube for authentication pages Facebook icon was displayed when clicking on it, the user will visit your Facebook channel';

export const jivoChatText =
  'Link to Jivochat is an access link on the Jivochat website, you must find this link and paste it. It is needed so that users can contact your JivoChat';

export const facebookKeyText =
  'Facebook Key is needed so that users can register and log in using Facebook You must provide this key. It\'s at the top of the page called :"App Id" in the "Panel" section. And so you need to add your domain in the section "Facebook login" => Allowed domains for SDK JavaScript. Link: https://developers.facebook.com/';

export const whiteLabelNameText = 'It is necessary in order for what you write here to be written in the browser tab\n';

export const adminEmailText = `
This is the account that is the main one for this site.
  It is also an Administrator account`;

export const contractRate = `This is the minimum price per hit per pass (local currency on the site)
`;

export const googleKeyText = `Google Key is required for users to register and sign in with Google. You must provide this key. It is located in the "Credentials" section in the "OAuth 2.0 Client IDs" tab. There you will see the name of your project (The one you thought of and registered with Google in advance) by clicking on it you will be redirected to the page "Authorized JavaScript origins"
 And so you need to add your domain to the section
"Authorized redirect URIs
 and Authorized JavaScript origins
" And in our field, enter "Client ID" which is located on the same page a little higher and to the right Link: https://console.cloud.google.com/`;

export const phoneText = `A phone number is required so that you can be contacted on the pages : "Add more skips" and "Pricing"`;

export const sslCertificate = `Ssl Certificate`;
export const sslCertificateKey = `Ssl Certificate Key`;
