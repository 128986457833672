import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.scss';

const PhoneField = ({ value, setState, label, parentClassName, error }) => {
  return (
    <div className={`label ${parentClassName}`}>
      {label}
      <PhoneInput
        country="us"
        inputClass="field"
        containerClass="phone__field"
        placeholder="+1 (302) 246 1037"
        buttonClass="phone__field__btn"
        value={value ? value : ''}
        onChange={(phone) => setState(phone)}
        error={error}
      />
    </div>
  );
};

export default PhoneField;
