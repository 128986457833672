import React, { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Modal } from 'antd';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';

const Callendly = ({ user, setParentModal }) => {
  const [show, setShow] = useState(false);
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  return (
    <div className="callendly-card">
      <div className="callendly-card__header">
        <img src="https://findeefi.deva.sibers.com//uploads/avatars/62cfe81f62523.svg" width={100} height={100} alt="" />
        <h4>{user.name}</h4>
      </div>
      <div className="callendly-card__body">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab alias beatae corporis cupiditate deleniti, deserunt dolores dolorum fuga, harum hic
          itaque obcaecati placeat quas ratione recusandae temporibus voluptatem voluptatum. Quaerat?
        </p>
      </div>
      <div className="callendly-card__footer">
        <button
          onClick={() => {
            setShow(!show);
            setParentModal(false);
          }}
        >
          Make appointment
        </button>
      </div>

      {show && (
        <Modal
          bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px', background: 'none' }}
          className="callendly__modal"
          visible={show}
          onCancel={() => {
            setShow(false);
            setParentModal(true);
          }}
          footer={null}
          width={modalWidth}
        >
          <InlineWidget url={user.url} rootElement={document.getElementById('callendly')} text="Click here to schedule!" textColor="#ffffff" color="#00a2ff" />
        </Modal>
      )}
    </div>
  );
};

export default Callendly;
