import React, { useEffect, useState } from 'react';
import Field from '../../Components/common/DataMatchingField/DataMatchingField';
import CheckBox from '../../Components/common/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { editBusinessFilesHeader, postSheetBusiness } from '../../store/actions/fileActions';
import { Select, Spin, Steps } from 'antd';
import { data_columns_business, dataMatchingInitialBusiness, stateBusiness, stateSelectBusiness } from '../../utils/constants';
import ResultsModal from '../../Components/common/ResultsModal/ResultsModal';
import history from '../../utils/history';
import DataMatchingNoFiles from '../../Components/common/DataMatchingNoFiles/DataMatchingNoFiles';
import './styles.scss';

const DataMatchingBusiness = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state?.files.businessFiles);
  const loading = useSelector((state) => state?.files.sheetsLoading);
  const rows = useSelector((state) => state?.files.rows);
  const [totalRows, setTotalRows] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentFile, setCurrentFile] = useState(0);
  const [checkbox, setCheckbox] = useState(files.length ? files[currentFile]?.business_sheets[currentStep].skip_header : false);
  const [selectState, setSelectState] = useState(stateSelectBusiness);
  const [dataMatching, setDataMatching] = useState(dataMatchingInitialBusiness);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTotalRows(rows);
  }, [rows]);

  useEffect(() => {
    if (files.length) {
      if (currentStep < files[currentFile]?.business_sheets.length) {
        if (checkbox) {
          if (
            files[currentFile]?.business_sheets[currentStep].skip_header === null ||
            !!files[currentFile]?.business_sheets[currentStep].skip_header !== checkbox
          ) {
            setDataMatching(dataMatchingInitialBusiness);
            setSelectState(
              files[currentFile]?.business_sheets[currentStep]?.first_row_values.map((item) => ({
                ...item,
                disabled: false,
              }))
            );
          } else {
            setDataMatching(
              Object.keys(files[currentFile]?.business_sheets[currentStep].data_matching).length
                ? files[currentFile]?.business_sheets[currentStep].data_matching
                : dataMatchingInitialBusiness
            );
            setSelectState(
              files[currentFile]?.business_sheets[currentStep].first_row_values.map((items) => {
                Object.keys(files[currentFile]?.business_sheets[currentStep].data_matching).forEach((item) => {
                  if (items.value === files[currentFile]?.business_sheets[currentStep].data_matching[item]) items.disabled = true;
                });
                return items;
              })
            );
          }
        } else if (!checkbox) {
          if (
            files[currentFile]?.business_sheets[currentStep].skip_header === null ||
            !!files[currentFile]?.business_sheets[currentStep].skip_header !== checkbox
          ) {
            setDataMatching(dataMatchingInitialBusiness);
            setSelectState(
              files[currentFile]?.business_sheets[currentStep].first_row_keys.map((item) => ({
                ...item,
                disabled: false,
              }))
            );
          } else {
            setDataMatching(
              Object.keys(files[currentFile]?.business_sheets[currentStep].data_matching).length
                ? files[currentFile]?.business_sheets[currentStep].data_matching
                : dataMatchingInitialBusiness
            );
            setSelectState(
              files[currentFile]?.business_sheets[currentStep].first_row_keys.map((items) => {
                Object.keys(files[currentFile]?.business_sheets[currentStep].data_matching).forEach((item) => {
                  if (items.value === files[currentFile]?.business_sheets[currentStep].data_matching[item]) items.disabled = true;
                });
                return items;
              })
            );
          }
        }
      }
    } else {
      setSelectState(stateSelectBusiness);
      setDataMatching(dataMatchingInitialBusiness);
    }
  }, [checkbox, currentFile, currentStep, files]);

  const stepsNavigation = (value, file) => {
    setCurrentStep(value);
    if (files[file]?.business_sheets[value]?.data_matching && Object.keys(files[file]?.business_sheets[value]?.data_matching).length) {
      setCheckbox(!!files[file]?.business_sheets[value].skip_header);
      setDataMatching(files[file]?.business_sheets[value].data_matching);
    } else {
      setCheckbox(!!files[file]?.business_sheets[value].skip_header);
      setDataMatching(dataMatchingInitialBusiness);
    }
  };

  const nextFile = async () => {
    if (files[currentFile]?.business_sheets.length - 1 > currentStep) {
      const response = await dispatch(
        postSheetBusiness({
          id: files[currentFile]?.business_sheets[currentStep]?.business_sheet_id,
          dataMatching,
          skipHeader: checkbox,
        })
      );
      if (response) stepsNavigation(currentStep + 1, currentFile);
    } else {
      if (files.length - 1 > currentFile) {
        const response = await dispatch(
          postSheetBusiness({
            id: files[currentFile]?.business_sheets[currentStep]?.business_sheet_id,
            dataMatching,
            skipHeader: checkbox,
          })
        );
        if (response) {
          setCurrentFile((prev) => (prev + 1 <= files.length ? prev + 1 : prev));
          stepsNavigation(0, currentFile + 1);
          setCheckbox(false);
        }
      } else if (files[currentFile]?.business_sheets.length - 1 === currentStep && files.length - 1 === currentFile) {
        const response = await dispatch(
          postSheetBusiness({
            id: files[currentFile]?.business_sheets[currentStep]?.business_sheet_id,
            dataMatching,
            skipHeader: checkbox,
          })
        );
        if (response) {
          setShow(true);
          setCurrentFile(0);
          setCurrentStep(0);
        }
      }
    }
  };

  const prevFile = () => {
    if (!currentStep && !currentFile) {
      history.push('/dashboard');
    }

    if (currentStep > 0) {
      stepsNavigation(currentStep - 1, currentFile);
    } else if (currentFile > 0) {
      setCurrentFile((prev) => prev - 1);
      stepsNavigation(files[currentFile - 1]?.business_sheets.length - 1, currentFile - 1);
    }
  };

  if (!files.length) {
    return <DataMatchingNoFiles />;
  }

  return (
    <Spin spinning={!!loading} size="large">
      <div className="container">
        <div className="m-l-50 m-r-50">
          <h1 className="data-matching-business__article">
            Let's Match <span>Your data</span>
          </h1>
          <h6 className="data-matching-business__sub">At minimum you must make a selection for ‘Business Name’, and ‘Mailing State’. Note that the more columns you are able to match, the more accurate your results will be. Don’t forget to click the checkbox at the bottom if your file contains a header row</h6>
          {files.length ? (
            <div className="steps">
              <Steps current={currentStep} onChange={(value) => stepsNavigation(value, currentFile)}>
                {files[currentFile]?.business_sheets.map((sheet, index) => (
                  <Steps.Step title={sheet.name} key={index} status={currentStep === index ? 'process' : sheet.status} />
                ))}
              </Steps>
            </div>
          ) : null}
        </div>

        <div className="data-matching-business">
          <div className="data-matching-business__item">
            <h4>DATA FILES</h4>
            <div className="data-matching-business__item-inner">
              {files.map((file, index) => (
                <div className={`${currentFile === index ? 'data-matching-business__item-file' : ''}`} key={file.id}>
                  <Field
                    width="90%"
                    disabled
                    value={file.original_name}
                    shortValue={file.original_name.length > 25 ? file.original_name.slice(0, 25) + '...' : file.original_name}
                    label=""
                    placeholder=""
                    onClick={() => {
                      setCurrentFile(index);
                      stepsNavigation(0, index);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="total-rows">
              <p>Total count rows: {totalRows}</p>
              <p className="m-t-5">Maximum skips required is {totalRows}</p>
            </div>
          </div>
          <div className="data-matching-business__item">
            <div className="data-matching-business__form">
              <Field disabled value={stateBusiness[0]} />
              <Field disabled value={stateBusiness[1]} />
              <Field disabled value={stateBusiness[2]} />
              <CheckBox
                checked={checkbox}
                setChecked={(value) => {
                  setCheckbox(value);
                  dispatch(editBusinessFilesHeader(files[currentFile]?.business_sheets[currentStep]?.business_sheet_id, value));
                }}
                className="data-matching__checkbox m-t-auto m-b-50"
                id={1}
                text="The first row in my file is a column header, do not import it."
              />
            </div>
          </div>
          <div className="data-matching-business__item">
            <div className="data-matching-business__form-user ">
              {data_columns_business.map((name, index) => (
                <Select
                  key={index + name}
                  value={dataMatching[name]}
                  onChange={(value) => {
                    setDataMatching((prev) => ({
                      ...prev,
                      [name]: value,
                    }));
                    setSelectState((prev) =>
                      prev.map((item) =>
                        item.value === dataMatching[name]
                          ? { ...item, disabled: false }
                          : item.value === value
                          ? {
                              ...item,
                              disabled: true,
                            }
                          : item
                      )
                    );
                  }}
                >
                  <Select.Option value={''} key="333">
                    Select row
                  </Select.Option>
                  {selectState.map(({ value, key, id, disabled }) => (
                    <Select.Option value={value} key={id} disabled={disabled}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              ))}

              <button className="data-matching-business__button data-matching-business__continue" onClick={nextFile}>
                {files[currentFile]?.business_sheets.length - 1 === currentStep && files.length - 1 === currentFile
                  ? 'Submit'
                  : files[currentFile]?.business_sheets.length === currentStep
                  ? 'NEXT'
                  : 'CONTINUE'}
              </button>
              <button className="data-matching-business__button" onClick={prevFile}>
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
      <ResultsModal setShow={setShow} show={show} files={files} type="business" />
    </Spin>
  );
};

export default DataMatchingBusiness;
