import {
  DELETE_INVITES_FAILURE,
  DELETE_INVITES_REQUEST,
  DELETE_INVITES_SUCCESS,
  GET_INVITES_FAILURE,
  GET_INVITES_REQUEST,
  GET_INVITES_SUCCESS,
  POST_INVITES_FAILURE,
  POST_INVITES_REQUEST,
  POST_INVITES_SUCCESS,
} from '../actionsTypes';
import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';

const getInvitesRequest = () => ({ type: GET_INVITES_REQUEST });
const getInvitesSuccess = (invites) => ({ type: GET_INVITES_SUCCESS, invites });
const getInvitesFailure = (error) => ({ type: GET_INVITES_FAILURE, error });

const postInvitesRequest = () => ({ type: POST_INVITES_REQUEST });
const postInvitesSuccess = () => ({ type: POST_INVITES_SUCCESS });
const postInvitesFailure = (error) => ({ type: POST_INVITES_FAILURE, error });

const deleteInvitesRequest = () => ({ type: DELETE_INVITES_REQUEST });
const deleteInvitesSuccess = () => ({ type: DELETE_INVITES_SUCCESS });
const deleteInvitesFailure = (error) => ({ type: DELETE_INVITES_FAILURE, error });

export const getInvites = (id, page) => {
  return async (dispatch) => {
    try {
      dispatch(getInvitesRequest());
      const response = await axiosApi.get(`/admin/white-label/${id}/invites?page=${page}`);
      dispatch(getInvitesSuccess(response.data));
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
      dispatch(getInvitesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const postInvites = (id, userData) => {
  return async (dispatch) => {
    try {
      dispatch(postInvitesRequest());
      const response = await axiosApi.post(`/admin/white-label/${id}/invites/new`, userData);
      dispatch(postInvitesSuccess());
      dispatch(getInvites(id, 1));
      showNotification('success', response.data.message, 'Success');
      return true;
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
      dispatch(postInvitesFailure(error?.response?.data?.errors?.message || true));
      return false;
    }
  };
};

export const deleteInvites = (whiteId, id) => {
  return async (dispatch) => {
    try {
      dispatch(deleteInvitesRequest());
      const response = await axiosApi.delete(`/admin/white-label/${whiteId}/invites/delete/${id}`);
      dispatch(deleteInvitesSuccess(response.data.data));
      dispatch(getInvites(whiteId, 1));
      showNotification('success', response.data, 'Success');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || " ", 'Error');
      dispatch(deleteInvitesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};
