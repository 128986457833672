import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFaqs } from '../../store/actions/landingActions';
import { Spin } from 'antd';
import Question from '../../Components/common/Question/QuestionUser';
import Fuse from 'fuse.js';
import './styles.scss';

const FAQS = () => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.landing.faqs);
  const loading = useSelector((state) => state.landing.loading);
  const [search, setSearch] = useState('');
  const [faqs, setFaqs] = useState(questions);
  useEffect(() => {
    dispatch(getAllFaqs());
  }, [dispatch]);

  useEffect(() => {
    const options = {
      findAllMatches: true,
      threshold: 0.5,
      keys: ['question', 'answer'],
    };

    const fuse = new Fuse(questions, options);

    const result = fuse.search(search).map((item) => item.item);

    setFaqs(result);

    if (!search) setFaqs(questions);
  }, [search, questions]);

  return (
    <div className="faqs-section">
      {!loading ? (
        <>
          <h1>FAQ</h1>
          <div className="faqs-section__body">
            <input placeholder="Search..." className="faqs-search" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />

            <ul className="faqs-section__list">
              {faqs.map((faq) => (
                <Question key={faq.id} faq={faq} search={search} />
              ))}
            </ul>
          </div>
        </>
      ) : (
        <div className="spinner-container">
          <Spin spinning={loading} size={'large'} className="m-auto" />
        </div>
      )}
    </div>
  );
};

export default FAQS;
