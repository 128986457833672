import React from 'react';
import { Route } from 'react-router-dom';
import EditWl from '../../../../Components/admin/EditWL/EditWl';
import { useSelector } from 'react-redux';

const Settings = () => {
  const config = useSelector((state) => state?.config?.config);

  return (
    <div className="white-label">
      <div className="white-label__content">
        <Route path="/admin/settings" exact>
          <EditWl id={config.white_label_id} />
        </Route>
      </div>
    </div>
  );
};

export default Settings;