import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { SignUpLink } from '../../../Pages/Landing/Landing';

import { ReactComponent as ReactColorLogoSvg } from '../../../assets/white-label/color-logo.svg';
import { ReactComponent as ReactCloudSvg } from '../../../assets/white-label/icons/icon-landing-header.svg';
import './style.scss';

const NavigationSection = ({ headText = '', headHeader = '', subHeader = '', isSubHeader }) => {
  const navigationRoutes = [
    {
      to: '/',
      title: 'Home',
    },
    {
      to: '/about-us',
      title: 'About us',
    },
    {
      to: '/pricing',
      title: 'Pricing',
    },
    {
      to: '/faq',
      title: 'faq',
    },
    {
      to: '/contact',
      title: 'contact us',
    },
  ];

  return (
    <section className="navigation-section">
      <div className="navigation-section__welcome-decoraion" />
      <header className="navigation-section__header">
        <div className="navigation-section__header-logo-container">
          <ReactColorLogoSvg />
        </div>
        <span className="navigation-section__header-decoration" />
        <nav className="navigation-section__nav">
          <ul className="navigation-section__nav-list">
            {navigationRoutes.map((route, index) => {
              return (
                <li className="navigation-section__nav-item" key={index}>
                  <NavLink exact className="navigation-section__nav-link" activeClassName="navigation-section__nav-link--active" to={route.to}>
                    {route.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <span className="navigation-section__header-decoration" />
        <div className="navigation-section__login-container">
          <SignUpLink text="Try skipcloud now!" className="navigation-section__login-link-button" />
          <span>
            Already a member?
            <Link to={'/login'} className="navigation-section__login-link">
              Log in
            </Link>
            now
          </span>
        </div>
      </header>

      <div className="navigation-section__head-container">
        <ReactCloudSvg />
        <h1>{headHeader}</h1>
        <p>{headText}</p>
        {isSubHeader && <p style={{ marginTop: '3px' }}>{subHeader}</p>}
      </div>
    </section>
  );
};

export default NavigationSection;
