import React from 'react';

const CameraIcon = () => {
  return (
    <div className="svg-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width={36} height={38} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36.03 36.03">
        <defs>
          <linearGradient id="SKIPCLOUD-ICON" y1="18.01" x2="36.03" y2="18.01" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-1)" />
            <stop offset="1" stopColor="var(--color-2)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
          <g id="Layer_1" data-name="Layer 1">
            <circle className="cls-1" cx="18.01" cy="18.01" r="18.01" />
            <path
              stroke="#fff"
              className="cls-2"
              strokeWidth={1}
              d="M8.13,15.36v8.58a2.2,2.2,0,0,0,2.2,2.21H25.7a2.21,2.21,0,0,0,2.2-2.21V15.36a2.2,2.2,0,0,0-2.2-2.2H23.28a.91.91,0,0,1-.88-.7l-.24-1a2.06,2.06,0,0,0-2-1.57h-4a2,2,0,0,0-2,1.57l-.24,1a.92.92,0,0,1-.88.7H10.33A2.19,2.19,0,0,0,8.13,15.36Z"
            />
            <circle stroke="#fff" strokeWidth={1} className="cls-2" cx="18.01" cy="19.06" r="4.47" />
            <line className="cls-2" x1="24.68" y1="15.99" x2="25.21" y2="15.99" stroke="#fff" strokeWidth={1} />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CameraIcon;
