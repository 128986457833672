import { Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import BusinessFiles from './BusinessFiles';
import PersonFiles from './PersonFiles';
import classnames from 'classnames';
import './styles.scss';
import Navigation from '../../Navigation';

const FilesStatistic = () => {
  const [content, setContent] = useState(0);
  const { url } = useRouteMatch();
  const { id, userId } = useParams();
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;
  const filesLoading = useSelector((state) => state.admin?.filesLoading);

  const renderFilesContent = (step) => {
    switch (step) {
      case 0:
        return <PersonFiles userID={userId} wlID={currentWLId} />;
      case 1:
        return <BusinessFiles userID={userId} wlID={currentWLId} />;
      default:
        return null;
    }
  };

  return (
    <Spin spinning={filesLoading}>
      <div className="file-type__container">
        <div className={classnames('btn', { 'active-type': content === 0 })} onClick={() => setContent(0)}>
          Person
        </div>
        <div className={classnames('btn', { 'active-type': content === 1 })} onClick={() => setContent(1)}>
          Business
        </div>
      </div>
      <div className="file-statistic__container">
        <Navigation id={currentWLId} userId={userId} />
        {renderFilesContent(content)}
      </div>
    </Spin>
  );
};

export default FilesStatistic;
