import React, { useContext, useEffect, useMemo } from 'react';
import { NavLink, Route, useHistory } from 'react-router-dom';
import EditCards from '../../../Pages/Admin panel/components/EditCards/EditCards';
import UsersListComponent from '../../../Pages/Admin panel/components/UserList/UserList';
import Faqs from '../../../Pages/Admin panel/components/Faqs/Faqs';
import { WHITE_LABEL_MAIN_ADMIN_ROUTE_PATHS, WHITE_LABEL_ROUTE_PATHS } from '../../../utils/constants';
import Transaction from '../Statistics/Transaction/Transaction';
import Summary from '../Statistics/Summary/Summary';
import Skiptrace from '../Statistics/SkiptraceHistory/Skiptrace';
import RoleNavigation from './RoleNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWl } from '../../../store/actions/whiteLabelActions';
import EditWl from '../EditWL/EditWl';
import Employees from '../../../Pages/Admin panel/components/Employees/Employees';
import DisabledEmployees from '../../../Pages/Admin panel/components/Employees/DisabledEmployees';
import Invites from '../../../Pages/Admin panel/components/Employees/Invites';
import UserStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/UserStatistic';
import PaymentStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/PaymentStatistic';
import FilesStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/FilesStatistic/FilesStatistic';
import { AppContext } from '../../../appContext';

const WhiteLabelNavigation = (props) => {
  const id = props?.match?.params?.id;
  const pathname = useHistory().location.pathname;
  const dispatch = useDispatch();
  const whiteLabels = useSelector((state) => state.whiteLabel.whiteLabels);
  const { isUserSuperAdmin } = useContext(AppContext);

  useEffect(() => {
    dispatch(getAllWl());
  }, [dispatch]);

  const whiteLabelName = useMemo(() => {
    return whiteLabels.filter((item) => item.id.toString() === id.toString())[0]?.name || '';
  }, [whiteLabels, id]);

  const navigation = useMemo(() => {
    return isUserSuperAdmin ? WHITE_LABEL_ROUTE_PATHS : WHITE_LABEL_MAIN_ADMIN_ROUTE_PATHS;
  }, [isUserSuperAdmin]);

  return (
    <section className="admin-panel">
      <h3 className="white-label-title">{whiteLabelName}</h3>
      <RoleNavigation />

      <div className="admin-panel__main-container">
        <nav className="admin-panel__navigation">
          {navigation.map((route, index) => {
            return (
              <NavLink
                className={`admin-panel__navigation-link ${
                  route.end.includes('reports') && (pathname.includes('summary') || pathname.includes('skiptrace') || pathname.includes('user-activity'))
                    ? 'admin-panel__navigation-link--active'
                    : ''
                } `}
                activeClassName="admin-panel__navigation-link--active"
                to={route.url + id + route.end}
                style={{ zIndex: navigation.length - index }}
                key={index}
              >
                {route.label}
              </NavLink>
            );
          })}
        </nav>

        <div className="admin-panel__content-container">
          {isUserSuperAdmin && (
            <>
              <Route path={`/white-label/:id/edit-card`} component={EditCards} />
              <Route path={`/white-label/:id/faq`} component={Faqs} />
              <Route path={`/white-label/:id/settings`} component={EditWl} />
              <Route path={`/white-label/:id/employees`} exact component={Employees} />
              <Route path={`/white-label/:id/employees/disabled`} component={DisabledEmployees} />
              <Route path={`/white-label/:id/employees/invites`} component={Invites} />
            </>
          )}
          <Route path={`/white-label/:id/users`} exact component={UsersListComponent} />
          <Route path={`/white-label/:id/users/statistic/:userId`} exact component={UserStatistic} />
          <Route path={`/white-label/:id/users/statistic/payment/:userId`} component={PaymentStatistic} />
          <Route path={`/white-label/:id/users/statistic/file/:userId`} component={FilesStatistic} />
          <Route path="/white-label/:id/reports" component={Transaction} />
          <Route path="/white-label/:id/summary" component={Summary} />
          <Route path="/white-label/:id/skiptrace" component={Skiptrace} />
          {/*<Route path="/white-label/:id/user-activity" component={UserActivity} />*/}
        </div>
      </div>
    </section>
  );
};

export default WhiteLabelNavigation;
