import React, { useState } from 'react';
import './styles.scss';

const StripeCard = ({ item, setTotalOrder, setTotalSkips, setDefaultCart, request }) => {
  const [count, setCount] = useState(item.quantity);

  const decrease = () => {
    if (count > 0) {
      setCount((prev) => prev - 1);
      setTotalOrder((prev) => prev - item.price);
      setTotalSkips((prev) => prev - item.skips);

      setDefaultCart((prev) => {
        if (prev.length) {
          for (let i = 0; i < prev.length; i++) {
            if (prev[i].id === item.id) {
              prev[i].quantity = count - 1;
            }
          }
        }

        return prev;
      });
      request();
    }
  };

  const increase = () => {
    if (count < 100) {
      setCount((prev) => prev + 1);
      setTotalOrder((prev) => prev + item.price);
      setTotalSkips((prev) => prev + item.skips);

      setDefaultCart((prev) => {
        if (prev.length) {
          for (let i = 0; i < prev.length; i++) {
            if (prev[i].id === item.id) {
              prev[i].quantity = count + 1;
            }
          }
        }

        return prev;
      });
      request();
    }
  };

  return (
    <div className="stripe__card" style={{ display: !count ? 'none' : 'flex' }}>
      <div className="stripe__card-info">
        <p>{item.skips} Skips</p>
        <span>(${item.perHit} per hit)</span>
      </div>
      <div className="stripe__card-buttons">
        <button onClick={decrease} />
        <p>{count}</p>
        <button onClick={increase} />
      </div>
    </div>
  );
};

export default StripeCard;
