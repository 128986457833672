import React from 'react';
import './styles.scss';

const NoResult = ({ text, style }) => {
  return (
    <div className="no-result" style={style}>
      {text}
    </div>
  );
};

export default NoResult;
