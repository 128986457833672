import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-coverflow';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import DefaultPriceCard from '../../../../Components/common/PriceCard/defaultPriceCard';

const PriceSwiper = ({ cart, setTotalOrder, setTotalSkips, setCart, prices }) => {
  const width = useResizeWindows();

  return (
    <Swiper
      className="priceSwipper"
      style={{
        width: '100%',
        height: '450px',
      }}
      effect={'coverflow'}
      slidesPerView={width < 800 ? 1 : 3}
      initialSlide={1}
      slideToClickedSlide
      centeredSlidesBounds={true}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 0,
        slideShadows: false,
      }}
      modules={[EffectCoverflow, Navigation, Pagination]}
      rewind
      scrollbar
      pagination
      grabCursor
      centeredSlides
    >
      {prices.map(({ id, price, per_hit, skips, type }, index) => (
        <SwiperSlide key={id}>
          <DefaultPriceCard
            cartActive={cart.length}
            id={id}
            key={index}
            price={price}
            perHit={per_hit}
            skips={skips}
            type={type}
            setTotalOrder={setTotalOrder}
            setTotalSkips={setTotalSkips}
            setArray={setCart}
            cart={cart}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PriceSwiper;
