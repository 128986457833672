import axiosApi from '../../utils/axios';
import { showNotification } from '../../App';
import {
  DEFAULT_LOADING,
  GET_CONFIG_FAILURE,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_STATE_FAILURE,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
} from '../actionsTypes';
import { apiURL } from '../../utils/config';

const getConfigRequest = () => ({ type: GET_CONFIG_REQUEST });
const getConfigSuccess = (config) => ({ type: GET_CONFIG_SUCCESS, config });
const getConfigFailure = (error) => ({ type: GET_CONFIG_FAILURE, error });

const getStatesRequest = () => ({ type: GET_STATE_REQUEST });
const getStatesSuccess = (states) => ({ type: GET_STATE_SUCCESS, states });
const getStatesFailure = (error) => ({ type: GET_STATE_FAILURE, error });

export const getConfig = () => {
  return async (dispatch) => {
    dispatch({ type: DEFAULT_LOADING });
    try {
      dispatch(getConfigRequest());
      const response = await axiosApi.get('/config');
      if (response?.data?.data?.name) document.title = response?.data?.data?.name;
      else document.title = 'Loading...';
      const favicon = document.getElementById('favicon');
      if (response?.data?.data?.images?.domain_logo) {
        favicon.setAttribute('href', apiURL + response?.data.data.images?.domain_logo);
        favicon.setAttribute('type', 'image/png');
      } else favicon.setAttribute('href', '/favicon.svg');
      dispatch(getConfigSuccess(response?.data?.data));
      const colors = response?.data?.data?.colors || false;
      if (colors) {
        for (const key in colors) {
          const color = colors[key];
          document.documentElement.style.setProperty(key, color);
        }
      } else document.documentElement.removeAttribute('style');
    } catch (error) {
      showNotification('error', error?.response?.data?.errors?.message || 'The server is temporarily unavailable', 'Error');
      dispatch(getConfigFailure(!error?.response?.data?.errors?.message || 'The server is temporarily unavailable'));
    }
  };
};

export const getStateList = () => {
  return async (dispatch) => {
    try {
      dispatch(getStatesRequest());
      const response = await axiosApi.get('/profile/states-list');
      dispatch(getStatesSuccess(response.data.states));
    } catch (error) {
      dispatch(getStatesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};
