import React, { useContext, useEffect } from 'react';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import FigureHeader from '../../SVG-Icon/FugureHeader';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { apiURL } from '../../../utils/config';
import { AppContext } from '../../../appContext';
import './styles.scss';

const Layout = ({ children }) => {
  const width = useResizeWindows();
  const config = useSelector((state) => state?.config?.config);
  const loading = useSelector((state) => state?.config?.loading);
  const { pathname } = useLocation();
  const collapsedWidth = 'calc(100% - 80px)';
  const sidebarWidth = width <= 1350 ? 'calc(100% - 305px)' : 'calc(100% - 355px)';
  const { collapsed, setCollapsed } = useContext(AppContext);

  const collapseSideBard = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (pathname.includes('/admin') || pathname.includes('/super-admin')) setCollapsed(true);
  }, [pathname, setCollapsed]);

  return (
    <>
      <div className={loading ? 'main__spinner' : ''}>
        <Spin spinning={loading} size="large" />
      </div>
      <div
        className={`layout ${loading ? 'layout__hidden' : ''}`}
        style={{ backgroundRepeat: 'no-repeat', backgroundImage: !loading && `url(${apiURL + config?.images?.background})` }}
      >
        <Sidebar collapsed={collapsed} setCollapsed={collapseSideBard} />
        <div className="layout__figure" />
        <FigureHeader className="layout__figure" />
        <Header />
        <div className="main-block" style={{ width: collapsed ? collapsedWidth : sidebarWidth }}>
          <main className="content">{children}</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
