import React, { useEffect, useState } from 'react';
import Cloud from '../../../../assets/landing/icons/cloud-icon-prices.svg';
import PriceSwiper from './PriceSwipper';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import history from '../../../../utils/history';
import DefaultPriceCard from '../../../../Components/common/PriceCard/defaultPriceCard';
import './styles.scss';
import { getLandingPrices } from '../../../../store/actions/landingActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

const Price = () => {
  const dispatch = useDispatch();
  const landingPrices = useSelector((state) => state?.landing?.landingPrices);
  const loading = useSelector((state) => state?.landing?.landingPricesLoading);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalSkips, setTotalSkips] = useState(0);
  const [cart, setCart] = useState([]);

  const width = useResizeWindows();

  useEffect(() => {
    dispatch(getLandingPrices());
  }, [dispatch]);

  return (
    <Spin spinning={loading}>
      <section className="landing__price-section">
        <img className="icon" src={Cloud} alt="cloud" />
        <h2 className="title">
          Prices that you were <b>Searching</b> for...
        </h2>
        <p className="sub-title">You'll never pay for a result you didn't get. No more wasting money on inaccurate data. Only pay for what you use!</p>

        {width >= 1280 ? (
          <div className="landing__price-list">
            {landingPrices.map(({ id, price, per_hit, skips, type }, index) => (
              <DefaultPriceCard
                cartActive={cart.length}
                id={id}
                key={index}
                price={price}
                perHit={per_hit}
                skips={skips}
                type={type}
                setTotalOrder={setTotalOrder}
                setTotalSkips={setTotalSkips}
                setArray={setCart}
                cart={cart}
              />
            ))}
          </div>
        ) : (
          <div className="landing__price-list">
            <PriceSwiper prices={landingPrices} setTotalOrder={setTotalOrder} setTotalSkips={setTotalSkips} setCart={setCart} cart={cart} />
          </div>
        )}

        <div className="landing__price-section-footer">
          <div className="landing__price-section-footer-total">
            <p>{totalSkips} Skips</p>
            <p>Total ${totalOrder && 0}.00</p>
          </div>
          <p className="landing__price-section-footer-checkout" onClick={() => history.push('/login')}>
            Go To Checkout
          </p>
        </div>
      </section>
    </Spin>
  );
};

export default Price;
