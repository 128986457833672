import React, { useEffect, useState } from 'react';
import { apiURL } from '../../../utils/config';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import './styles.scss';

const AdminFileInput = ({ text, onChange, className, type, white_label_id, value, disabled }) => {
  const inputRef = React.useRef();
  const [file, setFile] = useState('');

  useEffect(() => {
    if (value.type !== 'ssl' && value.type !== 'key') {
      if (value && _.isString(value)) setFile(apiURL + value);
      else if (value && _.isObject(value)) setFile(URL.createObjectURL(value.file));
    } else setFile('icon');
  }, [value]);

  const activateInput = () => {
    if (!disabled) {
      inputRef.current?.click();
    }
  };

  const onFileChange = (e) => {
    if (e.target.files[0]) {
      onChange({ file: e.target.files[0], type, white_label_id });
    }
  };

  const onDropHandler = (e) => {
    if (!disabled) {
      e.preventDefault();
      onChange({ file: e.dataTransfer.files[0], type, white_label_id });
    }
  };

  const onDragStartHandler = (e) => {
    e.preventDefault();
  };

  const onDragLeaveHandler = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <input type="file" onChange={onFileChange} ref={inputRef} style={{ display: 'none' }} />
      {file ? (
        <Tooltip placement="top" title={text}>
          {file === 'icon' ? (
            <div
              className="certificate__block"
              onDrop={onDropHandler}
              onDragStart={(e) => onDragStartHandler(e)}
              onDragLeave={(e) => onDragLeaveHandler(e)}
              onDragOver={(e) => onDragStartHandler(e)}
            >
              <FileDoneOutlined className="certificate__block-icon" onClick={() => !disabled && setFile(null)} />
              <p>{text}</p>
            </div>
          ) : (
            <img
              onDrop={onDropHandler}
              onDragStart={(e) => onDragStartHandler(e)}
              onDragLeave={(e) => onDragLeaveHandler(e)}
              onDragOver={(e) => onDragStartHandler(e)}
              src={file}
              alt="file"
              width="22%"
              height="150"
              className="m-b-20"
              style={{ objectFit: 'contain', cursor: 'pointer', background: 'radial-gradient(black, transparent)' }}
              onClick={() => !disabled && setFile(null)}
            />
          )}
        </Tooltip>
      ) : (
        <button
          className={`${className}`}
          onClick={activateInput}
          onDrop={onDropHandler}
          onDragStart={(e) => onDragStartHandler(e)}
          onDragLeave={(e) => onDragLeaveHandler(e)}
          onDragOver={(e) => onDragStartHandler(e)}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default AdminFileInput;
