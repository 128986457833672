import React, { useEffect, useState } from 'react';
import DraftIcon from '../../../../assets/dashboard/draft-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { changePrimaryCard, getCards, getPrimaryCard } from '../../../../store/actions/paymentActions';
import history from '../../../../utils/history';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.scss';
import { Link } from 'react-router-dom';

const BillingInformation = () => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.payment.cards);
  const primary = useSelector((state) => state.payment.primary);
  const [card, setCard] = useState('');

  useEffect(() => {
    dispatch(getCards());
    dispatch(getPrimaryCard());
  }, [dispatch]);

  return (
    <div className="billing-info">
      <h3>Primary Payment Details</h3>
      <div className="billing-info__header">
        <div className="billing-info-item">
          <p className="title">Cardholder Name</p>
          <p className="value">{primary.card_holder_name}</p>
        </div>
        <div className="billing-info-item">
          <p className="title">Card Number</p>
          <p className="value">xxxx xxxx xxxx {primary.last4}</p>
        </div>
        <div className="billing-info-item">
          <p className="title">Exp. Date</p>
          <p className="value">
            {primary.exp_month}/{primary.exp_year && primary.exp_year.toString().slice(2)}
          </p>
        </div>
        <div className="billing-info-item ">
          <p className="title">CVC code</p>
          <p className="value">xxx</p>
        </div>
      </div>
      <h3>Billing Address</h3>
      <div className="billing-info__body">
        <div className="billing-info-item">
          <p className="title">Address</p>
          <p className="value">{primary.billing_address?.address_line_1 ? primary.billing_address.address_line_1 : 'Address'}</p>
        </div>
        <div className="billing-info-item">
          <p className="title">City</p>
          <p className="value">{primary.billing_address?.city ? primary.billing_address.city : 'City'}</p>
        </div>
        <div className="billing-info-item">
          <p className="title">State</p>
          <p className="value">{primary.billing_address?.state ? primary.billing_address.state : 'State'}</p>
        </div>
        <div className="billing-info-item">
          <p className="title">ZIP code</p>
          <p className="value">{primary.billing_address?.zip_code ? primary.billing_address.zip_code : 'Zip Code'}</p>
        </div>
      </div>
      {cards.length ? (
        <>
          <div className="billing-info__footer">
            <div className="billing-info__footer-inner">
              <h5>Other Cards </h5>
              <Link to="/profile/other-cards">edit</Link>
            </div>
            <div className="cards">
              {cards.map(({ last4, id, brand, is_primary }, index) => (
                <React.Fragment key={id + index}>
                  {index < 3 ? (
                    <label className="card__label">
                      <input
                        type="radio"
                        name="options"
                        value={last4}
                        checked={is_primary || card === id}
                        onChange={() => {
                          setCard(id);
                          dispatch(changePrimaryCard(id));
                        }}
                      />
                      <span>
                        {brand} ({last4})
                      </span>
                    </label>
                  ) : null}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="payment-edit">
            <button className="payment" onClick={() => history.push('/profile/edit-payment')}>
              <span>Add new payment method </span>
              <LazyLoadImage src={DraftIcon} alt="" />
            </button>
            <button className="edit" onClick={() => history.push(`/profile/billing-edit/${primary.id}`)}>
              Edit
            </button>
          </div>
        </>
      ) : (
        <div className="cards-empty">
          <button className="payment" onClick={() => history.push('/profile/edit-payment')}>
            <span>Add new payment method </span>
            <LazyLoadImage src={DraftIcon} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};

export default BillingInformation;
