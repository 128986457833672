import React, { useContext, useEffect, useState } from 'react';
import TextField from '../../Components/common/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { checkInviteToken, registerUser, registerUserInvites } from '../../store/actions/userActions';
import { Spin } from 'antd';
import PhoneField from '../../Components/common/PhoneField/PhoneField';
import InstagramIcon from '../../assets/auth/social-insta.svg';
import FBIcon from '../../assets/auth/social-fb.svg';
import YouTubeIcon from '../../assets/auth/social-yt.svg';
import PasswordIcon from '../../assets/auth/password.svg';
import UserNameIcon from '../../assets/auth/userName.svg';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import { showNotification } from '../../App';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { apiURL } from '../../utils/config';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';
import moment from 'moment/moment';
import _ from 'lodash';
import { loadFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';
import './styles.scss';
import AuthenticationLayout from '../../Components/UI/AuthenticationLayout/AuthenticationLayout';

const Register = () => {
  const { token } = useParams();
  const emailRequest = useHistory().location.search.slice(7);
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const loading = useSelector((state) => state?.user.loading);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState({
    phone: false,
    email: false,
    password: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
  });
  const [invites, setInvites] = useState(false);
  const { brand } = useContext(AppContext);
  const { facebook, instagram, youtube } = useSelector((state) => state?.config?.config?.social_links);
  const showSocialMediaBlock = Boolean(facebook || instagram || youtube);

  // whitelabel images from config
  const subLogo = useSelector((state) => state.config.config?.images?.logo_2);
  const whiteLabelName = useSelector((state) => state.config?.config?.name);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);

  useEffect(() => {
    const userData = loadFromLocalStorage('register-user');
    if (userData) {
      setEmail(userData.email);
      setFirstName(userData.name);
    }
  }, []);

  useEffect(() => {
    const effect = async () => {
      const response = await dispatch(checkInviteToken(token));
      if (response) setInvites(false);
      else {
        setInvites(true);
        setEmail('');
      }
    };

    if (token) effect();
  }, [dispatch, token]);

  useEffect(() => {
    if (emailRequest) setEmail(emailRequest);
  }, [emailRequest]);

  const register = () => {
    if (
      !(phone?.length > 10) ||
      !(email?.includes('@') && email?.length > 3) ||
      password?.trim().length <= 5 ||
      confirmPassword?.trim().length <= 5 ||
      firstName?.trim().length < 3 ||
      lastName?.trim().length < 3
    ) {
      setErrors({
        phone: !(phone?.length > 10),
        email: !(email?.includes('@') && email?.length > 3),
        password: password?.trim().length <= 5,
        confirmPassword: password?.trim().length <= 5,
        firstName: firstName?.trim().length < 3,
        lastName: lastName?.trim().length < 3,
      });

      showNotification('error', 'Please fill in all required fields correctly', 'Error');
    } else {
      dispatch(
        registerUser({
          firstName,
          lastName,
          email,
          phone,
          password: { first: password, second: confirmPassword },
        })
      );
      removeFromLocalStorage('register-user');
    }
  };

  const registerInvites = () => {
    if (
      !(phone?.length > 10) ||
      !(email?.includes('@') && email?.length > 3) ||
      password?.trim().length <= 5 ||
      confirmPassword?.trim().length <= 5 ||
      firstName?.trim().length < 3 ||
      lastName?.trim().length < 3
    ) {
      setErrors({
        phone: !(phone?.length > 10),
        email: !(email?.includes('@') && email?.length > 3),
        password: password?.trim().length <= 5,
        confirmPassword: password?.trim().length <= 5,
        firstName: firstName?.trim().length < 3,
        lastName: lastName?.trim().length < 3,
      });

      showNotification('error', 'Please fill in all required fields correctly', 'Error');
    } else {
      dispatch(
        registerUserInvites({
          token,
          firstName,
          lastName,
          phone,
          password: { first: password, second: confirmPassword },
        })
      );
      removeFromLocalStorage('register-user');
    }
  };

  let textFieldButtonSize = '430px';
  if (width < 460 && width > 370) {
    textFieldButtonSize = '350px';
  } else if (width <= 370) {
    textFieldButtonSize = '300px';
  }

  const social = (
    <>
      <div className="social-block__adaptive">
        <h4>Follow US ON SOCIAL MEDIA</h4>
        <div className="social-link__items">
          {!_.isEmpty(facebook) && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={FBIcon} alt="facebook" />
            </a>
          )}
          {!_.isEmpty(instagram) && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={InstagramIcon} alt="instagram" />
            </a>
          )}
          {!_.isEmpty(youtube) && (
            <a href={youtube} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={YouTubeIcon} alt="you-tube" />
            </a>
          )}
        </div>
      </div>
    </>
  );

  const registerStyles = {
    margin: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${apiURL + authBackground}) 0 -2px no-repeat`,
    backgroundSize: 'contain',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100vh',
  };

  return (
    <div className="register" style={registerStyles}>
      <div className="register__body">
        {width >= 1200 && <AuthenticationLayout />}
        <div className="register__body-form">
          <LazyLoadImage className="login__logo" draggable={false} src={`${apiURL + subLogo}`} alt="logo" />
          <h3 className="auth__title">
            Sign up to <span>{whiteLabelName}</span>
          </h3>
          <div className="login__border" />
          <Spin spinning={loading} size={'large'}>
            <PhoneField
              value={phone}
              setState={setPhone}
              error={errors.phone}
              parentClassName={classnames(
                'register__input m-t-30',
                { 'register__input--correct': !errors.phone && phone },
                { 'register__input--error': errors.phone }
              )}
              disabled={invites}
            />
            <TextField
              icon={UserNameIcon}
              tooltipText="Minimum length 3 characters"
              value={firstName}
              setState={setFirstName}
              placeholder="First Name"
              width={textFieldButtonSize}
              error={errors.firstName}
              parentClassName={classnames(
                'register__input',
                { 'register__input--correct': !errors.firstName && firstName },
                { 'register__input--error': errors.firstName }
              )}
              disabled={invites}
            />
            <TextField
              icon={UserNameIcon}
              tooltipText="Minimum length 3 characters"
              value={lastName}
              setState={setLastName}
              placeholder="Last Name"
              width={textFieldButtonSize}
              error={errors.lastName}
              parentClassName={classnames(
                'register__input',
                { 'register__input--correct': !errors.lastName && lastName },
                { 'register__input--error': errors.lastName }
              )}
              disabled={invites}
            />
            <TextField
              tooltipText="Format: example@thanks.com"
              icon={UserNameIcon}
              width={textFieldButtonSize}
              value={email}
              setState={setEmail}
              type="text"
              placeholder="Email"
              error={errors.email}
              parentClassName={classnames(
                'register__input',
                { 'register__input--correct': !errors.email && email },
                { 'register__input--error': errors.email }
              )}
              disabled={emailRequest}
            />
            <TextField
              tooltipText="Minimum length 6 characters"
              value={password}
              setState={setPassword}
              type="password"
              width={textFieldButtonSize}
              placeholder="Password"
              error={errors.password}
              icon={PasswordIcon}
              parentClassName={classnames(
                'register__input',
                { 'register__input--correct': !errors.password && password },
                { 'register__input--error': errors.password }
              )}
            />
            <TextField
              tooltipText="Minimum length 6 characters"
              value={confirmPassword}
              setState={setConfirmPassword}
              type="password"
              width={textFieldButtonSize}
              placeholder="Confirm Password"
              error={errors.confirmPassword}
              icon={PasswordIcon}
              parentClassName={classnames(
                'register__input',
                { 'register__input--correct': !errors.password && password },
                { 'register__input--error': errors.password }
              )}
              disabled={invites}
            />
          </Spin>
          <button className="auth__button m-b-10" onClick={token ? registerInvites : register}>
            Sign Up
          </button>
          <div className="login__border m-b-25" />
          <p className="register__login">
            Already have an account?
            <Link className="login__link" to="/login">
              Log In!
            </Link>
          </p>
          <div className="login__border" />
          {width <= 1200 && showSocialMediaBlock && social}
        </div>
      </div>
      <div className="auth__footer">
        <div className="auth__footer__outer">
          <p>
            All rights reserved. {toCapitalize(brand)}, LLC. {moment(new Date()).format('YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
