import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { BgColorsOutlined } from '@ant-design/icons';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';

const GradientItem = ({ color, setColor, className, disabled }) => {
  const [show, setShow] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShow(false));
  const colorLocal = hexRgb(color);

  return (
    <>
      {show ? (
        <div ref={ref} className={className}>
          <SketchPicker
            color={{
              r: colorLocal.red,
              g: colorLocal.green,
              b: colorLocal.blue,
              a: colorLocal.alpha,
            }}
            onChangeComplete={(color) => setColor('#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a))}
            width={220}
          />
        </div>
      ) : (
        <button onClick={() => setShow(true)} className={`${className} gradient-picker-item-btn`} disabled={disabled}>
          <BgColorsOutlined />
        </button>
      )}
    </>
  );
};

export default GradientItem;
