import React, { useEffect, useState } from 'react';
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal, Spin } from 'antd';
import moment from 'moment';
import ArrowIcon from '../../../assets/UI/arrowLeft.svg';
import { compareNumbers, conversion } from '../../../utils/functions';
import { paymentStripe, paymentStripeMethod } from '../../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from '../SuccessModal/SuccessModal';
import StripeCard from './components/StripeCard';
import { showNotification } from '../../../App';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import StripeForm from './StripeForm';
import ChoseCards from './ChoseCards';
import { getCards } from '../../../store/actions/paymentActions';
import './styles.scss';

const Form = ({ close, cart = [], setCart, setInsufficientPopUp, setModal, defaultCart, setDefaultCart }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.payment.cards);
  const loading = useSelector((state) => state.payment.loading);
  const stripe = useStripe();
  const elements = useElements();
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalSkips, setTotalSkips] = useState(0);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const [error, setError] = useState(false);
  const [savePaymentDetails, setSavePaymentDetails] = useState(false);
  const [stripeModal, setStripeModal] = useState(true);
  const [currentCard, setCurrentCard] = useState('');
  const [defaultTotalOrder, setDefaultTotalOrder] = useState(0);
  const [codeActive, setCodeActive] = useState(false);

  useEffect(() => {
    if (cards.length) {
      const defaultCard = cards.filter((item) => item.is_primary)[0]?.id;
      setCurrentCard(defaultCard);
      setStripeModal(false);
    }
  }, [cards]);

  useEffect(() => {
    const sumTotalOrder = cart.reduce((accumulator, currentValue) => accumulator + currentValue.price * currentValue.quantity, 0);
    const sumSkips = cart.reduce((accumulator, currentValue) => accumulator + currentValue.skips * currentValue.quantity, 0);
    setTotalOrder(sumTotalOrder);
    setTotalSkips(sumSkips);
  }, [cart]);

  useEffect(() => {
    if (codeActive) {
      const sumTotalOrder = defaultCart.reduce((accumulator, currentValue) => accumulator + currentValue.price * currentValue.quantity, 0);
      setDefaultTotalOrder(sumTotalOrder);
    }
  }, [defaultCart, codeActive]);

  const reCalculate = () => {
    const sumTotalOrder = defaultCart.reduce((accumulator, currentValue) => accumulator + currentValue.price * currentValue.quantity, 0);
    setDefaultTotalOrder(sumTotalOrder);
  };

  useEffect(() => {
    dispatch(getCards());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    if (cardHolderName.trim().length > 3) {
      setError(false);
      setStripeLoading(true);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(card);
      if (result.error) setStripeLoading(false);
      else {
        const response = await dispatch(
          paymentStripe({
            token: result.token.id,
            amount: totalOrder,
            skips: totalSkips,
            cardHolderName,
            savePaymentDetails,
            paymentInfo: cart.map((card) => ({ price_id: card.id, quantity: card.quantity, discount_id: card.discount_id })),
          })
        );

        if (response) {
          setModal(true);
          close();
          setCart([]);
        }
        setStripeLoading(false);
      }
    } else {
      setError(true);
      showNotification('error', 'Card holder name should not be blank', 'Error');
    }
  };

  const paymentStripeMethodHandler = async () => {
    setStripeLoading(true);

    const response = await dispatch(
      paymentStripeMethod({
        paymentMethodId: currentCard,
        amount: totalOrder,
        skips: totalSkips,
        paymentInfo: cart.map((card) => ({ price_id: card.id, quantity: card.quantity, discount_id: card.discount_id })),
      })
    );

    if (response) {
      setModal(true);
      close();
      setCart([]);
      setStripeLoading(false);
    }
  };

  const goBack = () => {
    close();
    setCart([]);
    setInsufficientPopUp && setInsufficientPopUp(true);
  };

  return (
    <Spin spinning={stripeLoading || loading} size="large">
      <div className="stripe-modal">
        <div className="stripe-modal__body">
          <div className="stripe-modal__body-item">
            {stripeModal ? (
              <StripeForm
                cart={cart}
                setCart={setCart}
                cards={cards}
                error={error}
                cardHolderName={cardHolderName}
                setCardHolderName={setCardHolderName}
                savePaymentDetails={savePaymentDetails}
                setSavePaymentDetails={setSavePaymentDetails}
                setStripeModal={setStripeModal}
                setCodeActive={setCodeActive}
              />
            ) : !stripeModal ? (
              <ChoseCards
                cards={cards}
                setStripeModal={setStripeModal}
                setCurrentCard={setCurrentCard}
                currentCard={currentCard}
                cart={cart}
                setCart={setCart}
                setCodeActive={setCodeActive}
              />
            ) : (
              <div className="spinner-container">
                <Spin spinning={loading} size="large" />
              </div>
            )}
          </div>
          <div className="stripe-modal__body-item">
            <div className="stripe-modal__order">
              <div className="stripe-modal__order-header">
                <h3>Order Summary</h3>
                <p>{moment(new Date()).format('MMMM Do, YYYY')}</p>
              </div>
              <div className="stripe-modal__order-body">
                <div className="m-t-45 m-b-10">
                  {cart.sort(compareNumbers).map((item) => (
                    <StripeCard
                      item={item}
                      key={item.id}
                      setCart={setCart}
                      setTotalSkips={setTotalSkips}
                      setTotalOrder={setTotalOrder}
                      setDefaultCart={setDefaultCart}
                      request={reCalculate}
                    />
                  ))}
                </div>

                <div className="stripe-modal__order-body-total">{conversion(totalSkips)} TOTAL SKIPS</div>
                <div className="m-b-40">
                  {codeActive && (
                    <div className="stripe-modal__order-total-item">
                      <p style={{ textDecoration: 'line-through' }}>SUBTOTAL</p>
                      <p style={{ textDecoration: 'line-through' }}>${conversion(defaultTotalOrder / 100)}</p>
                    </div>
                  )}

                  {codeActive && (
                    <div className="stripe-modal__order-total-item">
                      <p>DISCOUNT</p>
                      <p>${conversion((defaultTotalOrder - totalOrder) / 100)}</p>
                    </div>
                  )}

                  <div className="stripe-modal__order-total-item">
                    <p>{codeActive ? 'NEW  SUBTOTAL' : 'SUBTOTAL'}</p>
                    <p>${conversion(totalOrder / 100)}</p>
                  </div>
                </div>
                <button className="checkout__button" onClick={stripeModal ? totalOrder && handleSubmit : totalOrder && paymentStripeMethodHandler}>
                  Checkout Now
                </button>

                <p className="back" onClick={goBack}>
                  <LazyLoadImage src={ArrowIcon} alt="Go Back" className="arrow" />
                  Go Back
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

const StripeModal = ({ show, setShow, cart, setCart, setInsufficientPopUp, defaultCart, setDefaultCart }) => {
  const [modal, setModal] = useState(false);
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="stripe">
      {show ? (
        <Modal
          bodyStyle={{
            width: width <= 1300 ? '1180px' : '100%',
            padding: '0',
            borderRadius: '50px',
            background: 'var(--content-bg)',
          }}
          visible={show}
          onCancel={() => {
            setShow(false);
            setCart([]);
            setDefaultCart([]);
          }}
          footer={null}
          width={modalWidth}
        >
          <Elements stripe={stripePromise}>
            <Form
              close={() => setShow(false)}
              cart={cart}
              defaultCart={defaultCart}
              setCart={setCart}
              setInsufficientPopUp={setInsufficientPopUp}
              setModal={setModal}
              setDefaultCart={setDefaultCart}
            />
          </Elements>
        </Modal>
      ) : (
        <SuccessModal show={modal} setShow={setModal} />
      )}
    </div>
  );
};
export default StripeModal;
