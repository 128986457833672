import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import Transaction from '../Statistics/Transaction/Transaction';
import Skiptrace from '../Statistics/SkiptraceHistory/Skiptrace';
import { AppContext } from '../../../appContext';
import UsersListComponent from '../../../Pages/Admin panel/components/UserList/UserList';
import UserStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/UserStatistic';
import PaymentStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/PaymentStatistic';
import FilesStatistic from '../../../Pages/Admin panel/components/StatisticPanel/UserStatistic/FilesStatistic/FilesStatistic';

const BasicNavigation = () => {
  const { whiteLabelId } = useContext(AppContext);

  return (
    <div className="admin-panel__content-container">
      <Route path="/admin" exact>
        <Transaction id={whiteLabelId} />
      </Route>
      <Route path="/admin/reports/skiptrace">
        <Skiptrace id={whiteLabelId} />
      </Route>
      <Route exact path={`/admin/users`} component={UsersListComponent} />
      <Route path={`/admin/users/statistic/:userId`} exact component={UserStatistic} />
      <Route path={`/admin/users/statistic/payment/:userId`} component={PaymentStatistic} />
      <Route path={`/admin/users/statistic/file/:userId`} component={FilesStatistic} />
    </div>
  );
};

export default BasicNavigation;
