import { CopyOutlined, MenuOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { deleteDiscount, disableDiscount, restoreDiscount } from '../../../../../store/actions/adminActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotification } from '../../../../../App';
import useResizeWindows from '../../../../../utils/hooks/useResizeWindows';
import { sliceString } from '../../../../../utils/functions';

const CodeItem = ({ item, isActiveCodes, setCodeId, setOpenModal }) => {
  const dispatch = useDispatch();
  const width = useResizeWindows();
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const promoCodesID = anotherWL ? id : whiteLabelId;

  const passProps = () => {
    setCodeId(item.id);
    setOpenModal(true);
  };

  const popOverContent = () => {
    return (
      <>
        {isActiveCodes ? (
          <div className="users-list__popover">
            <button onClick={() => passProps()}>edit</button>
            <button onClick={() => dispatch(disableDiscount(promoCodesID, item.id))}>disable</button>
          </div>
        ) : (
          <div className="users-list__popover">
            <button onClick={() => dispatch(restoreDiscount(promoCodesID, item.id))}>restore</button>
            <button onClick={() => dispatch(deleteDiscount(promoCodesID, item.id))}>delete</button>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <li className="promo__codes__item">
        <>
          <span>
            {isActiveCodes && (
              <CopyToClipboard text={item.code} onCopy={() => showNotification('success', 'Copied')}>
                <CopyOutlined />
              </CopyToClipboard>
            )}
            <Tooltip placement="topLeft" title={item?.code}>
              {sliceString(width, item.code, 45, 40, 30)}
            </Tooltip>
          </span>
          <span>{item.percent} %</span>
        </>
        <Popover placement="topLeft" title={'Actions'} trigger="click" content={popOverContent}>
          <MenuOutlined />
        </Popover>
      </li>
    </>
  );
};

export default CodeItem;
