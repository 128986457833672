import {
  DELETE_INVITES_FAILURE,
  DELETE_INVITES_REQUEST,
  DELETE_INVITES_SUCCESS,
  GET_INVITES_FAILURE,
  GET_INVITES_REQUEST,
  GET_INVITES_SUCCESS,
  POST_INVITES_FAILURE,
  POST_INVITES_REQUEST,
  POST_INVITES_SUCCESS,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  invites: [],
  pages: 1,
};

const invitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITES_REQUEST:
      return { ...state, loading: true, error: null, invites: [] };
    case GET_INVITES_SUCCESS:
      return { ...state, loading: false, error: null, invites: action.invites.data, pages: action.invites.pages };
    case GET_INVITES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case DELETE_INVITES_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_INVITES_SUCCESS:
      return { ...state, loading: false, error: null };
    case DELETE_INVITES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case POST_INVITES_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_INVITES_SUCCESS:
      return { ...state, loading: false, error: null };
    case POST_INVITES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
};

export default invitesReducer;
