import React, { useEffect, useRef, useState } from 'react';
import { CheckCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Tooltip } from 'antd';
import { getBusinessProcessesHistory, getProcessesHistory, removeHistory } from '../../store/actions/processActions';
import { Link } from 'react-router-dom';
import Finder from '../../Components/common/Finder/Finder';
import CustomScrollbar from '../../Components/common/CustomScrollBar/CustomScrollBar';
import Line from '../../assets/dashboard/line-icon.png';
import { downloadDashboardBusinessFiles, downloadDashboardFiles } from '../../store/actions/fileActions';
import { userProfile } from '../../store/actions/userActions';
import Status from '../../Components/common/Status/Status';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import { sliceString } from '../../utils/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from 'lodash';
import NoResult from '../../Components/common/NoResult/NoResult';
import './styles.scss';
import { wsURL } from '../../utils/config';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const processes = useSelector((state) => state?.process.history);
  const loading = useSelector((state) => state?.process.loading);
  const pages = useSelector((state) => state?.process.history_pages);
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [filesPeople, setFilesPeople] = useState('');
  const [filesBusiness, setFilesBusiness] = useState('');
  const ws = useRef(null);
  const ref = useRef();
  const width = useResizeWindows();
  const [state, setState] = useState(true);

  useEffect(() => {
    if (state) dispatch(getProcessesHistory(page));
    else dispatch(getBusinessProcessesHistory(page));
  }, [dispatch, page, state]);

  useEffect(() => {
    setHistory(processes);
  }, [processes]);

  useEffect(() => {
    if (user && ws) {
      ws.current = new WebSocket(wsURL);
      ws.current.onopen = () => {
        ws.current.send(JSON.stringify({ user: user.id && user.id.toString() }));
      };

      ws.current.onmessage = (e) => {
        const message = JSON.parse(e.data);
        const status =
          message.type === 'PROCESS_EXPIRED' || message.type === 'BUSINESS_PROCESS_EXPIRED'
            ? 'expired'
            : message.type === 'PROCESS_COMPLETED' || message.type === 'BUSINESS_PROCESS_COMPLETED'
            ? 'completed'
            : false;
        if (message.skips) dispatch(userProfile());
        if (status) {
          setHistory((prev) =>
            prev.map((process) => {
              if (process.id === message.data.id) {
                process.status = status;
              }
              return process;
            })
          );
        }
      };

      return () => {
        ws.current.close();
      };
    }
  }, [ws, user, dispatch]);

  const downloadFilesHandler = (array) => {
    if (state) dispatch(downloadDashboardFiles(array.map((item) => item.id)));
    else dispatch(downloadDashboardBusinessFiles(array.map((item) => item.id)));
  };

  const navigationHandler = (state) => {
    dispatch(removeHistory());
    setState(state);
    setPage(1);
    ref.current.scrollIntoView({ block: 'start' });
  };

  return (
    <div className="dashboard">
      <div className="dashboard__body">
        <div className="dashboard__body-item">
          <Finder file={filesPeople} setFile={setFilesPeople} title="Import Data for" blueTitle="Person Skiptrace" margin />
          <Finder type="business" file={filesBusiness} setFile={setFilesBusiness} title="Import Data for" blueTitle="LLC Skiptrace" />
        </div>
        <div className="dashboard__body-item">
          <div className="table">
            <div className="table__article">
              <button disabled={state || loading} className={`table-btn ${state ? 'table-btn__active' : ''}`} onClick={() => navigationHandler(true)}>
                People
              </button>
              <h3>Import History</h3>
              <button disabled={!state || loading} className={`table-btn ${!state ? 'table-btn__active' : ''}`} onClick={() => navigationHandler(false)}>
                Business
              </button>
            </div>
            {!loading && (
              <>
                {_.isEmpty(history) ? (
                  <NoResult text="Files History is Empty" style={{ marginTop: '25px' }} />
                ) : (
                  <div className="table__header">
                    <div className="tr">
                      <div className="td">Name</div>
                      <div className="td">Date</div>
                      <div className="td">Status</div>
                      <div className="td">DOWNLOAD</div>
                      <div className="td">VIEW</div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="table__body">
              <Spin spinning={loading} size="large" style={{ minHeight: '400px' }}>
                <CustomScrollbar maxHeight={'400px'}>
                  <div ref={ref} />
                  {history.map(({ name, status, created_at, id, person_files, business_files, total_count_rows, error_description }, index) => (
                    <div className={`tr ${index % 2 ? '' : 'tr-bg'}`} key={index}>
                      <div className="td">
                        <Tooltip placement="top" title={name}>
                          <b>{sliceString(width, name, 35, 20, 12)}</b>
                        </Tooltip>
                      </div>
                      <div className="td">{created_at}</div>
                      <div className="td">
                        <Status
                          type={!!business_files}
                          id={id}
                          status={status}
                          total_count_rows={total_count_rows}
                          files={business_files ? business_files : person_files}
                          error_description={error_description}
                        />
                      </div>
                      <div className="td">
                        {status === 'completed' ? (
                          <Tooltip placement="top" title="Download" onClick={() => downloadFilesHandler(business_files ? business_files : person_files)}>
                            <CheckCircleOutlined style={{ fontSize: '20px', color: 'var(--main-color)' }} />
                          </Tooltip>
                        ) : status === 'expired' ? (
                          <CheckCircleOutlined style={{ fontSize: '20px', color: '#b1b1b1' }} />
                        ) : (
                          <p>
                            <LazyLoadImage src={Line} alt="-" />
                          </p>
                        )}
                      </div>
                      <div className="td">
                        <Link
                          to={status === 'completed' && state ? `/library/${id}` : status === 'completed' && !state ? `/library/business/${id}` : '#'}
                          disabled={status !== 'completed'}
                        >
                          <EyeOutlined style={{ fontSize: '20px', color: 'var(--main-color)' }} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </CustomScrollbar>
              </Spin>
            </div>
            {pages > 1 && (
              <button className="primary-button" onClick={() => setPage((prev) => (prev + 1 <= pages ? prev + 1 : prev))}>
                View More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
