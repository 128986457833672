import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import { changeExpiredDate } from '../../../store/actions/whiteLabelActions';

const DatePickerTable = ({ data }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [date, setDate] = useState(data?.expiration_date || '');

  const updateHandler = () => {
    dispatch(changeExpiredDate({ file_id: data.file_id, type: data.type, expiration_date: date }));
    data.expiration_date = date;
    setState(false);
  };

  const dateHandler = (_, dateString) => {
    setDate(dateString);
  };

  return (
    <div>
      {!state ? (
        <div className="flex flex-align-items-center flex-justify-content-around">
          <p style={{ minWidth: '75px' }}>{data.expiration_date}</p>
          <button className="white-label-table__csv" style={{ height: '25px' }} onClick={() => setState(true)}>
            Update
          </button>
        </div>
      ) : (
        <div className="white-label__update-date">
          <DatePicker onChange={dateHandler} />
          <button className="white-label-table__csv" style={{ height: '25px' }} onClick={() => setState(false)}>
            Cancel
          </button>

          <button className="white-label-table__csv" style={{ height: '25px' }} onClick={updateHandler}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default DatePickerTable;
