import React, { useContext, useEffect, useState } from 'react';
import { addCertificate, addImagesWL, editWL, getSoloWL } from '../../../store/actions/whiteLabelActions';
import { InputNumber, Spin } from 'antd';
import AdminFileInput from '../AdminFileInput/AdminFileInput';
import GradientPicker from '../GradientPicker/GradientPicker';
import ColorPicker from '../ColorPicker/ColorPicker';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import WLField from '../FieldWL/WLField';
import { AppContext } from '../../../appContext';
import Helper from '../Helper/Helper';
import {
  adminEmailText,
  contractRate,
  domainText,
  facebookKeyText,
  facebookText,
  googleKeyText,
  instagramText,
  jivoChatText,
  phoneText,
  whiteLabelNameText,
  youtubeText,
} from '../../../utils/white-label-annotations';

import SslField from '../SSLField/SSLField';
import { showNotification } from '../../../App';

import MainGradient1 from '../../../assets/Example/main-gradient-1.png';
import MainGradient2 from '../../../assets/Example/main-gradient-2.png';
import MainGradient3 from '../../../assets/Example/main-gradient-3.png';

import ActionGradient1 from '../../../assets/Example/action-gradient-1.png';
import ActionGradient2 from '../../../assets/Example/action-gradient-2.png';
import HeaderInner from '../../../assets/Example/Header-inner-1.png';
import HeaderSkips from '../../../assets/Example/header-skips.png';

import MainColor1 from '../../../assets/Example/main-color.png';
import MainColor2 from '../../../assets/Example/main-color-2.png';

import MainColor1Hover from '../../../assets/Example/main-color-hover.png';
import MainColor2Hover from '../../../assets/Example/main-color-hover-2.png';

import TextColor1 from '../../../assets/Example/text-color-1.png';
import TextColor2 from '../../../assets/Example/text-color-2.png';
import TextColor3 from '../../../assets/Example/text-color-3.png';
import TextColor4 from '../../../assets/Example/text-color-4.png';

import ContentBg1 from '../../../assets/Example/content-bg-1.png';
import ContentBg2 from '../../../assets/Example/content-bg-2.png';
import ContentBg3 from '../../../assets/Example/content-bg-3.png';

import TableRow from '../../../assets/Example/table-row.png';
import TableRow2 from '../../../assets/Example/table-row-1.png';

import ActionColor from '../../../assets/Example/action-color-1.png';
import NavColor from '../../../assets/Example/navigation-color.png';

const mainGradientImages = [MainGradient1, MainGradient2, MainGradient3];
const actionGradientImages = [ActionGradient1, ActionGradient2];
const headerInnerImages = [HeaderInner];
const headerSkipsImages = [HeaderSkips];
const mainColorImages = [MainColor1, MainColor2];
const mainHoverImages = [MainColor1Hover, MainColor2Hover];
const contentBgImages = [ContentBg1, ContentBg2, ContentBg3];
const textColorImages = [TextColor1, TextColor2, TextColor3, TextColor4];
const tableRowImages = [TableRow, TableRow2];
const actionColorImages = [ActionColor];
const navColorImages = [NavColor];
const navColorHoverImages = [NavColor];

const EditWl = (props) => {
  const id = props.id || props?.match?.params?.id;
  const dispatch = useDispatch();
  const whiteLabel = useSelector((state) => state.whiteLabel.whiteLabel);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const { isUserSuperAdmin, isUserMainAdmin, isUserAdmin } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [editSSL, setEditSSL] = useState(false);
  const [confirmEditing, setConfirmEditing] = useState(false);
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [contract, setContract] = useState('');
  const [name, setName] = useState('');
  const [facebook, setFacebook] = useState('');
  const [google, setGoogle] = useState('');
  const [jivoChat, setJivoChat] = useState('');

  const [youtube, setYoutube] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [phone, setPhone] = useState('');

  const [gradient_1, setGradient_1] = useState('#000');
  const [gradient_2, setGradient_2] = useState('#000');
  const [gradient_3, setGradient_3] = useState('#000');

  const [header_inner_1, setHeader_inner_1] = useState('#000');
  const [header_inner_2, setHeader_inner_2] = useState('#000');
  const [header_inner_3, setHeader_inner_3] = useState('#000');

  const [header_skips_1, setHeader_skips_1] = useState('#000');
  const [header_skips_2, setHeader_skips_2] = useState('#000');
  const [header_skips_3, setHeader_skips_3] = useState('#000');

  const [action_gradient_1, setAction_gradient_1] = useState('#000');
  const [action_gradient_2, setAction_gradient_2] = useState('#000');
  const [action_gradient_3, setAction_gradient_3] = useState('#000');

  const [mainColor, setMainColor] = useState('#000');
  const [mainHoverColor, setMainHoverColor] = useState('#000');

  const [contentBg, setContentBg] = useState('#000');
  const [textColor, setTextColor] = useState('#000');

  const [tableRow, setTableRow] = useState('#000');
  const [actionColor, setActionColor] = useState('#000');

  const [navColor, setNavColor] = useState('#000');
  const [navColorActive, setNavColorActive] = useState('#000');

  const [logo1, setLogo1] = useState('');
  const [logo2, setLogo2] = useState('');
  const [logo3, setLogo3] = useState('');
  const [background, setBackground] = useState('');
  const [sidebarLogo, setSidebarLogo] = useState('');
  const [sidebarLogoCollapsed, setSidebarLogoCollapsed] = useState('');
  const [authBackground, setAuthBackground] = useState('');
  const [authBackgroundInner, setAuthBackgroundInner] = useState('');

  const [certificateSSL, setCertificateSSL] = useState('');
  const [certificateKey, setCertificateKey] = useState('');

  useEffect(() => {
    if (id) dispatch(getSoloWL(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (whiteLabel && id) {
      setDomain(whiteLabel.domain || '');
      setEmail(whiteLabel.email || '');
      setFacebook(whiteLabel.fb_auth_key || '');
      setGoogle(whiteLabel.google_auth_key || '');
      setName(whiteLabel.name || '');
      setPhone(whiteLabel.company_phone || '');
      setContract(whiteLabel?.contract_rate || '');
      setJivoChat(whiteLabel.jivochat_url || '');
      setInstagram(whiteLabel.instagram || '');
      setYoutube(whiteLabel.youtube || '');
      setFacebookUrl(whiteLabel.facebook || '');
      setCertificateSSL(whiteLabel?.certificates.certificate || '');
      setCertificateKey(whiteLabel?.certificates.cert_key || '');

      setGradient_1(whiteLabel.gradient_1 || '#000');
      setGradient_2(whiteLabel.gradient_2 || '#000');
      setGradient_3(whiteLabel.gradient_3 || '#000');
      setMainColor(whiteLabel.main_color);
      setMainHoverColor(whiteLabel.main_color_hover);
      setTableRow(whiteLabel.tr_bg);
      setTextColor(whiteLabel.text_color);
      setActionColor(whiteLabel.action_color);
      setContentBg(whiteLabel.content_bg);
      setNavColorActive(whiteLabel.navigation_text_active || '#000');
      setNavColor(whiteLabel.navigation_text || '#000');

      setHeader_inner_1(whiteLabel?.header_inner_gradient_1 || '#000');
      setHeader_inner_2(whiteLabel?.header_inner_gradient_2 || '#000');
      setHeader_inner_3(whiteLabel?.header_inner_gradient_3 || '#000');

      setHeader_skips_1(whiteLabel.header_skips_gradient_1 || '#000');
      setHeader_skips_2(whiteLabel.header_skips_gradient_2 || '#000');
      setHeader_skips_3(whiteLabel.header_skips_gradient_3 || '#000');

      setAction_gradient_1(whiteLabel.action_gradient_1 || '#000');
      setAction_gradient_2(whiteLabel.action_gradient_2 || '#000');
      setAction_gradient_3(whiteLabel.action_gradient_3 || '#000');

      if (whiteLabel.images) {
        setLogo1(whiteLabel?.images.filter((item) => item.type === 0).length ? whiteLabel.images.filter((item) => item.type === 0)[0].url : '');
        setLogo2(whiteLabel?.images.filter((item) => item.type === 1).length ? whiteLabel.images.filter((item) => item.type === 1)[0].url : '');
        setLogo3(whiteLabel?.images.filter((item) => item.type === 2).length ? whiteLabel.images.filter((item) => item.type === 2)[0].url : '');
        setBackground(whiteLabel?.images.filter((item) => item.type === 3).length ? whiteLabel.images.filter((item) => item.type === 3)[0].url : '');
        setSidebarLogo(whiteLabel?.images.filter((item) => item.type === 4).length ? whiteLabel.images.filter((item) => item.type === 4)[0].url : '');
        setSidebarLogoCollapsed(whiteLabel?.images.filter((item) => item.type === 5).length ? whiteLabel.images.filter((item) => item.type === 5)[0].url : '');
        setAuthBackground(whiteLabel?.images.filter((item) => item.type === 6).length ? whiteLabel.images.filter((item) => item.type === 6)[0].url : '');
        setAuthBackgroundInner(whiteLabel?.images.filter((item) => item.type === 7).length ? whiteLabel.images.filter((item) => item.type === 7)[0].url : '');
      }
    }
  }, [whiteLabel, id]);

  const closeConfirmModal = () => {
    if (editSSL) {
      setConfirmEditing(false);
    } else {
      setShow(false);
    }
  };

  const editHandler = () => {
    if (!domain.includes('https://')) {
      showNotification('error', 'Your domain does not contain "https://"', 'Error');
    } else if (domain.slice(-1) === '/') {
      showNotification('error', 'Your domain must not end with "/"', 'Error');
    } else {
      dispatch(
        editWL(
          {
            main_color: mainColor,
            main_color_hover: mainHoverColor,
            action_color: actionColor,
            gradient_1,
            gradient_2,
            gradient_3,
            content_bg: contentBg,
            tr_bg: tableRow,
            text_color: textColor,
            header_inner_gradient_1: header_inner_1,
            header_inner_gradient_2: header_inner_2,
            header_inner_gradient_3: header_inner_3,
            header_skips_gradient_1: header_skips_1,
            header_skips_gradient_2: header_skips_2,
            header_skips_gradient_3: header_skips_3,
            action_gradient_1,
            action_gradient_2,
            action_gradient_3,
            navigation_text: navColor,
            navigation_text_active: navColorActive,
          },
          {
            name,
            domain,
            fb_auth_key: facebook,
            google_auth_key: google,
            contract_rate: contract,
            email,
            instagram,
            youtube,
            facebook: facebookUrl,
            jivochat_url: jivoChat,
            company_phone: phone,
          },
          id
        )
      );
      typeof logo1 !== 'string' && dispatch(addImagesWL(logo1, id));
      typeof logo2 !== 'string' && dispatch(addImagesWL(logo2, id));
      typeof logo3 !== 'string' && dispatch(addImagesWL(logo3, id));
      typeof background !== 'string' && dispatch(addImagesWL(background, id));
      typeof sidebarLogo !== 'string' && dispatch(addImagesWL(sidebarLogo, id));
      typeof sidebarLogoCollapsed !== 'string' && dispatch(addImagesWL(sidebarLogoCollapsed, id));
      typeof authBackground !== 'string' && dispatch(addImagesWL(authBackground, id));
      typeof authBackgroundInner !== 'string' && dispatch(addImagesWL(authBackgroundInner, id));
      dispatch(addCertificate({ certificateKey: certificateKey.file, certificateSSL: certificateSSL.file }, id));
      setShow(false);
    }
  };

  const editSSLHandler = async () => {
    await dispatch(addCertificate({ certificate: certificateSSL.trim(), cert_key: certificateKey.trim() }, id));
  };

  const confirmEditHandler = () => {
    if (editSSL) {
      editSSLHandler();
    } else {
      editHandler();
    }
  };

  return (
    <div className="create-white-label">
      <Spin spinning={loading} size="large">
        <div className="white-label__fields m-b-20">
          <WLField
            text={domainText}
            placeholder="White Label Domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            disabled={!isUserSuperAdmin || id === '1'}
          />
          <WLField text={adminEmailText} placeholder="Admin User Email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={whiteLabelNameText} placeholder="White Label Name" value={name} onChange={(e) => setName(e.target.value)} disabled={isUserMainAdmin} />
          <label className="white-label-label">
            <p>Contract Rate</p>
            <div className="white-label-label__inner">
              <InputNumber
                placeholder="Contract Rate"
                onChange={(value) => setContract(value)}
                className="white-label-field"
                value={contract}
                min={isUserSuperAdmin ? '0.03' : '0.07'}
                max="10"
                stringMode
                step="0.01"
                disabled={!isUserSuperAdmin}
              />
              <Helper title="Contract Rate" text={contractRate} tooltipWidth="100%" />
            </div>
          </label>
          <WLField
            text={facebookKeyText}
            placeholder="Facebook Key"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
            disabled={isUserMainAdmin}
          />
          <WLField text={googleKeyText} placeholder="Google Key" value={google} onChange={(e) => setGoogle(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={jivoChatText} placeholder="Jivochat URL" value={jivoChat} onChange={(e) => setJivoChat(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={youtubeText} placeholder="You Tube" value={youtube} onChange={(e) => setYoutube(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={instagramText} placeholder="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={facebookText} placeholder="Facebook" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} disabled={isUserMainAdmin} />
          <WLField text={phoneText} placeholder="Phone Company" value={phone} onChange={(e) => setPhone(e.target.value)} disabled={isUserMainAdmin} />
        </div>
        {!isUserMainAdmin && !isUserAdmin && (
          <div className="white-label-files m-b-20">
            <AdminFileInput
              white_label_id={id}
              type={0}
              value={logo1}
              className="white-label-file"
              text="Main Logo"
              onChange={(value) => setLogo1(value)}
              disabled={isUserMainAdmin}
            />
            <AdminFileInput
              white_label_id={id}
              type={1}
              value={logo2}
              className="white-label-file"
              text="Sub Logo"
              onChange={(value) => setLogo2(value)}
              disabled={isUserMainAdmin}
            />
            <AdminFileInput
              white_label_id={id}
              type={2}
              value={logo3}
              className="white-label-file"
              text="Domain Logo"
              onChange={(value) => setLogo3(value)}
              disabled={isUserMainAdmin}
            />
            <AdminFileInput
              white_label_id={id}
              type={3}
              value={background}
              className="white-label-file"
              text="Background"
              onChange={(value) => setBackground(value)}
              disabled={isUserMainAdmin}
            />

            <AdminFileInput
              white_label_id={id}
              id={4}
              type={4}
              className="white-label-file"
              text="Sidebar Logo"
              onChange={(value) => setSidebarLogo(value)}
              disabled={isUserMainAdmin}
              value={sidebarLogo}
            />
            <AdminFileInput
              white_label_id={id}
              id={5}
              type={5}
              className="white-label-file"
              text="Sidebar Logo Collapsed"
              onChange={(value) => setSidebarLogoCollapsed(value)}
              disabled={isUserMainAdmin}
              value={sidebarLogoCollapsed}
            />
            <AdminFileInput
              white_label_id={id}
              id={6}
              type={6}
              className="white-label-file"
              text="Auth Background"
              onChange={(value) => setAuthBackground(value)}
              disabled={isUserMainAdmin}
              value={authBackground}
            />
            <AdminFileInput
              white_label_id={id}
              id={7}
              type={7}
              className="white-label-file"
              text="Auth Background Inner"
              onChange={(value) => setAuthBackgroundInner(value)}
              disabled={isUserMainAdmin}
              value={authBackgroundInner}
            />
          </div>
        )}
        {!isUserMainAdmin && !isUserAdmin && (
          <div className="create-white-label__body">
            <GradientPicker
              title="Main Gradient"
              gradientFirst={gradient_1}
              gradientLast={gradient_3}
              gradientSecond={gradient_2}
              setGradientFirst={setGradient_1}
              setGradientSecond={setGradient_2}
              setGradientLast={setGradient_3}
              disabled={isUserMainAdmin}
              images={mainGradientImages}
              text={whiteLabelNameText}
            />
            <GradientPicker
              title="Action Gradient"
              gradientFirst={action_gradient_1}
              gradientLast={action_gradient_3}
              gradientSecond={action_gradient_2}
              setGradientFirst={setAction_gradient_1}
              setGradientSecond={setAction_gradient_2}
              setGradientLast={setAction_gradient_3}
              images={actionGradientImages}
              disabled={isUserMainAdmin}
            />
            <GradientPicker
              title="Header Inner"
              gradientFirst={header_inner_1}
              gradientLast={header_inner_3}
              gradientSecond={header_inner_2}
              setGradientFirst={setHeader_inner_1}
              setGradientSecond={setHeader_inner_2}
              setGradientLast={setHeader_inner_3}
              disabled={isUserMainAdmin}
              images={headerInnerImages}
            />
            <GradientPicker
              title="Header Skips"
              gradientFirst={header_skips_1}
              gradientLast={header_skips_3}
              gradientSecond={header_skips_2}
              setGradientFirst={setHeader_skips_1}
              setGradientSecond={setHeader_skips_2}
              setGradientLast={setHeader_skips_3}
              disabled={isUserMainAdmin}
              images={headerSkipsImages}
            />
            <ColorPicker images={mainColorImages} title="Main Color" color={mainColor} setColor={setMainColor} disabled={isUserMainAdmin} />
            <ColorPicker images={mainHoverImages} title="Main Hover Color" color={mainHoverColor} setColor={setMainHoverColor} disabled={isUserMainAdmin} />
            <ColorPicker images={contentBgImages} title="Content Background" color={contentBg} setColor={setContentBg} disabled={isUserMainAdmin} />
            <ColorPicker images={textColorImages} title="Text Color" color={textColor} setColor={setTextColor} disabled={isUserMainAdmin} />
            <ColorPicker images={tableRowImages} title="Table row background" color={tableRow} setColor={setTableRow} disabled={isUserMainAdmin} />
            <ColorPicker images={actionColorImages} title="Action Color" color={actionColor} setColor={setActionColor} disabled={isUserMainAdmin} />
            <ColorPicker images={navColorImages} title="Navigation Color" color={navColor} setColor={setNavColor} disabled={isUserMainAdmin} />
            <ColorPicker
              images={navColorHoverImages}
              title="Navigation Color Active"
              color={navColorActive}
              setColor={setNavColorActive}
              disabled={isUserMainAdmin}
            />
          </div>
        )}

        {id !== '1' && (
          <div className="white-label-label__inner">
            <SslField
              sslText="PRIVATE KEY"
              placeholder="SSL Certificate"
              onChange={(e) => setCertificateSSL(e.target.value)}
              value={certificateSSL}
              showSSLText={true}
              disabled={!editSSL}
            />
            <SslField
              sslText="CERTIFICATE"
              placeholder="Key Certificate"
              onChange={(e) => setCertificateKey(e.target.value)}
              value={certificateKey}
              showSSLText={true}
              disabled={!editSSL}
            />
          </div>
        )}

        <div className="edit-wl-buttons__container">
          <div className="ssl-block">
            {id !== '1' && (
              <>
                {editSSL ? (
                  <>
                    <button className="edit-wl-btn m-r-10" onClick={() => setEditSSL((prev) => !prev)}>
                      cancel
                    </button>
                    <button className="edit-wl-btn" onClick={() => setConfirmEditing((prev) => !prev)}>
                      save
                    </button>
                  </>
                ) : (
                  <button className="edit-wl-btn" onClick={() => setEditSSL((prev) => !prev)}>
                    edit
                  </button>
                )}
              </>
            )}
          </div>

          <div className="confirm-block">
            <button
              className="create-white-label-btn"
              onClick={() => {
                setEditSSL(false);
                setShow(true);
              }}
            >
              Apply changes
            </button>
          </div>
        </div>
      </Spin>

      <ConfirmModal
        text={confirmEditing ? 'Are you sure you want to update SSL-certificate' : 'Are you sure you want to apply the changes'}
        show={show || confirmEditing}
        onCancel={closeConfirmModal}
        editHandler={confirmEditHandler}
      />
    </div>
  );
};

export default EditWl;
