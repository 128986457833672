import React, { useContext, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { AppContext } from '../../../../appContext';
const Navigation = ({ id, userId }) => {
  const { isUserAdmin, isUserMainAdmin, isUserBasic } = useContext(AppContext);
  const pathname = useHistory().location.pathname;

  const navigation = useMemo(() => {
    const SUPER_ADMIN_ROUTE_PATHS = [
      {
        url: `/white-label/${id}/users/statistic/${userId}`,
        label: 'User',
        exact: true,
      },
      {
        url: `/white-label/${id}/users/statistic/payment/${userId}`,
        label: 'Payment',
      },

      {
        url: `/white-label/${id}/users/statistic/file/${userId}`,
        label: 'Files',
      },
    ];

    const STATISTIC_ADMIN_ROUTE_PATHS = [
      {
        url: `/admin/users/statistic/${userId}`,
        label: 'User',
        exact: true,
      },
      {
        url: `/admin/users/statistic/payment/${userId}`,
        label: 'Payment',
      },

      {
        url: `/admin/users/statistic/file/${userId}`,
        label: 'Files',
      },
    ];

    return isUserAdmin || (isUserMainAdmin && pathname.includes('admin')) || isUserBasic ? STATISTIC_ADMIN_ROUTE_PATHS : SUPER_ADMIN_ROUTE_PATHS;
  }, [id, isUserAdmin, isUserMainAdmin, userId, isUserBasic, pathname]);

  return (
    <nav className={`white-label__nav ${!isUserAdmin && !isUserMainAdmin && isUserBasic && pathname.includes('admin') ? 'white-label__nav-basic' : ''}`}>
      {navigation.map((route, index) => {
        return (
          <NavLink
            className={`white-label__nav-link `}
            activeClassName="white-label__nav-link--active"
            to={route.url}
            exact={route.exact}
            style={{ zIndex: navigation.length - index }}
            key={index}
          >
            {route.label}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default Navigation;
