import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getUserStatistic } from '../../../../../store/actions/adminActions';
import { apiURL } from '../../../../../utils/config';
import Navigation from '../Navigation';
import history from '../../../../../utils/history';
import { AppContext } from '../../../../../appContext';

const UserStatistic = () => {
  const { isUserAdmin, isUserSuperAdmin, isUserMainAdmin, isUserBasic } = useContext(AppContext);
  const dispatch = useDispatch();
  const userStatistic = useSelector((state) => state?.admin?.user);
  const userStatisticLoading = useSelector((state) => state?.admin?.userLoading);
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();

  const { id, userId } = useParams();

  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;

  const historyPushHandler = () => {
    if (isUserSuperAdmin || (isUserMainAdmin && anotherWL)) {
      history.push(`/white-label/${currentWLId}/users`);
    } else if (isUserAdmin || isUserMainAdmin || isUserBasic) {
      history.push(`/admin/users`);
    }
  };

  useEffect(() => {
    dispatch(getUserStatistic(currentWLId, userId));
  }, [dispatch, currentWLId, userId]);

  return (
    <Spin spinning={userStatisticLoading}>
      <div className="users-list__container">
        <Navigation id={currentWLId} userId={userId} />
        <button className="back__btn" onClick={historyPushHandler}>
          <ArrowLeftOutlined />
          Back to users list
        </button>
        <ul className="users-list m-t-30">
          <li className="user-info__item user-info__item--heading">
            <h3>Name</h3>
            <h3>Email</h3>
            <h3>Skips</h3>
            <h3>Total Revenue</h3>
            <h3>Company</h3>
          </li>

          <li className="user-info__item">
            <span>
              {userStatistic.avatar ? (
                <Avatar src={userStatistic.avatar.includes('http') ? userStatistic.avatar : apiURL + '/uploads/avatars/' + userStatistic.avatar} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
              {`${userStatistic.first_name} ${userStatistic.last_name}`}
            </span>
            <span>{userStatistic.email}</span>
            <span>{userStatistic.skips}</span>
            <span>{`$ ${userStatistic.total_revenue}`}</span>
            <span>{userStatistic.company || 'No Company.'}</span>
          </li>
        </ul>
      </div>
    </Spin>
  );
};

export default UserStatistic;
