import React from 'react';
import './styles.scss';

const TextArea = ({ label, value, setState, parentClassName, height = '200px', disabled = false }) => {
  return (
    <label className={parentClassName}>
      {label}
      <textarea style={{ height }} className="field-textarea" value={value} onChange={(e) => setState(e.target.value)} disabled={disabled} />
    </label>
  );
};

export default TextArea;
