import React from 'react';
import Helper from '../Helper/Helper';
import './styles.scss';

const WLField = ({ placeholder, value, onChange, disabled = false, text }) => {
  return (
    <label className="white-label-label">
      <p>{placeholder}</p>
      <div className="white-label-label__inner">
        <input placeholder={placeholder} type="text" value={value} onChange={onChange} className="white-label-field" disabled={disabled} />
        <Helper text={text} title={placeholder} tooltipWidth="100%" />
      </div>
    </label>
  );
};

export default WLField;
