import _ from 'lodash';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiURL } from '../../../utils/config';
import FBIcon from '../../../assets/auth/social-fb.svg';
import InstagramIcon from '../../../assets/auth/social-insta.svg';
import YouTubeIcon from '../../../assets/auth/social-yt.svg';
import './styles.scss';

const AuthenticationLayout = () => {
  const loginLogo = useSelector((state) => state.config.config?.images?.logo_1);
  const authBackground = useSelector((state) => state.config.config?.images?.auth_background);
  const { facebook, instagram, youtube } = useSelector((state) => state?.config?.config?.social_links);
  const showSocialMediaBlock = Boolean(facebook || instagram || youtube);
  // const isWhiteLabel = config.white_label_id === 1;

  const socialBlockJSX = (
    <>
      <div className="social-block__auth">
        <h4>Follow US ON SOCIAL MEDIA</h4>
        <div className="social-link__items">
          {!_.isEmpty(instagram) && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={InstagramIcon} alt="instagram" />
            </a>
          )}
          {!_.isEmpty(facebook) && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={FBIcon} alt="facebook" />
            </a>
          )}
          {!_.isEmpty(youtube) && (
            <a href={youtube} target="_blank" rel="noreferrer">
              <LazyLoadImage className="social-block__auth__icon" src={YouTubeIcon} alt="you-tube" />
            </a>
          )}
        </div>
      </div>
    </>
  );

  const authStyles = {
    flex: 1,
    background: `url(${apiURL + loginLogo}) 140px 140px no-repeat`,
    backgroundSize: '405px 185px',
    '@media screen and (maxWidth: 1300px)': {
      background: `url(${apiURL + authBackground}) 15% 10% no-repeat`,
      backgroundSize: '381px 173px',
      marginTop: 0,
    },
  };

  return (
    <div style={authStyles}>
      {true && (
        <Link to="/" draggable={false}>
          <p className="to-main__page"></p>
        </Link>
      )}
      {showSocialMediaBlock && socialBlockJSX}
    </div>
  );
};

export default AuthenticationLayout;
