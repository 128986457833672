import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavigationSection from '../../Components/common/LandingHeader/NavigationSection';
import FooterSection from '../../Components/common/LandingFooter/footerSection';
import DefaultPriceCard from '../../Components/common/PriceCard/defaultPriceCard';
import AdditionalPriceCard from '../../Components/common/PriceCard/additionalPriceCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SignUpLink } from '../Landing/Landing';
import { ReactComponent as ReactAheadLogoSvg } from '../../assets/white-label/icons/icon-landing-ahead.svg';
import StockImage from '../../assets/white-label/pricing-stock.png';
import history from '../../utils/history';
import PricingBg from '../../Components/SVG-Icon/PricingBG';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getLandingPrices } from '../../store/actions/landingActions';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';

const Pricing = () => {
  const dispatch = useDispatch();
  const landingPrices = useSelector((state) => state?.landing?.landingPrices);
  const loading = useSelector((state) => state?.landing?.landingPricesLoading);
  const { brand } = useContext(AppContext);

  useEffect(() => {
    dispatch(getLandingPrices());
  }, [dispatch]);

  return (
    <Spin spinning={loading}>
      <div className="pricing">
        <NavigationSection headHeader={'Need more skips?'} headText={`The more you buy, the more you save.`} />
        <section className="pricing-section">
          <article className="pricing-section__list-article">
            <div className="pricing-section__list-container">
              <div className="pricing-section__price-container">
                <div className="pricing-section__price-list">
                  {landingPrices.map((item, index) => (
                    <DefaultPriceCard key={index} price={item.price} perHit={item.per_hit} skips={item.skips} type={item.type} />
                  ))}
                </div>
                <p className="pricing-section__list-description">
                  Are your searches normally much higher in volume? If you’re purchasing more than 100,000 skips, we have <b>enterprise pricing</b> just for you!
                </p>
              </div>
              <AdditionalPriceCard skips={'100,000'} />
            </div>

            <div className="pricing-section__price-sum">
              <div className="pricing-section__price-sum-total">
                <p>{0} Skips</p>
                <p>Total ${0}.00</p>
              </div>
              <p className="pricing-section__price-sum-checkout" onClick={() => history.push('/sign-up')}>
                Go To Checkout
              </p>
            </div>
          </article>

          <article className="pricing-section__text-article">
            <PricingBg />
            <LazyLoadImage src={StockImage} alt="SkipCloud" />
            <p>
              <b>{toCapitalize(brand)}</b> is the best skip tracing provider in the industry! We have the most comprehensive data available and our prices are
              unbeatable. Skip tracing is essential for a variety of industries including real estate, law enforcement, and even private individuals. Our data
              is constantly updated and accurate, so you can be sure you're getting the most up-to-date information possible. Skipcloud is your one-stop shop
              for all your skip tracing needs!
            </p>

            <div className="pricing-section__account-container">
              <div className="pricing-section__account-svg-container">
                <ReactAheadLogoSvg />
              </div>
              <h4 className="pricing-section__account-header">
                <span>Haven't used</span>
                <b>{toCapitalize(brand)}</b>
                <span>yet?</span>
              </h4>
              <SignUpLink text={`Show me what i'm missing out on!`} className="pricing-section__login-button navigation-section__login-link-button" />
              <span>
                We'll
                <Link to={'/sign-up'} className="pricing-section__login-link navigation-section__login-link">
                  make you an account
                </Link>
                for FREE so you can check it out first!
              </span>
            </div>
          </article>
        </section>

        <FooterSection />
      </div>
    </Spin>
  );
};

export default Pricing;
