import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../assets/account/edit-icon.svg';
import ChangePasswordModal from '../../../../Components/common/ChangePasswordModal/ChangePasswordModal';
import { userProfile } from '../../../../store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sliceString } from '../../../../utils/functions';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CloseIcon from '../../../../Components/SVG-Icon/CloseIcon';
import './styles.scss';
import ChangeEmailModal from '../../../../Components/common/ChangeEmailModal/ChangeEmailModal';
import { Tooltip } from 'antd';

const PersonDetails = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile);
  const [passwordShow, setPasswordShow] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const width = useResizeWindows();

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  return (
    <div className="personal-details">
      <div className="personal-details__header">
        <h3>Personal Details</h3>
      </div>
      <div className="personal-details__body">
        <div className="personal-details__body-item">
          <p className="title">Company</p>
          <p>{sliceString(width, profile?.company || 'Your Company')}</p>
        </div>
        <div className="personal-details__body-item">
          <p className="title">Email</p>
          <Tooltip placement="topLeft" title={profile?.email}>
            <p>{sliceString(width, profile?.email || 'Your Email')}</p>
          </Tooltip>
        </div>
        <div className="personal-details__body-item">
          <p className="title">Phone</p>
          <p>+ {sliceString(width, profile?.phone || 'Your Phone')}</p>
        </div>
        <div className="personal-details__body-item">
          <p className="title">Birthday</p>
          <p>{sliceString(width, profile?.dob || 'Your Birthday')}</p>
        </div>
        <Link className="personal-details-link" to="/profile/account-edit">
          Edit Personal Details
          <LazyLoadImage src={EditIcon} alt="" className="edit" />
        </Link>
      </div>

      <div className="personal-details__footer">
        <div className="personal-details__footer-inner">
          <h4>Log-In info</h4>
          <CloseIcon className="lock" />
          <div className="flex flex-align-center flex-justify-content-between">
            <div className="personal-details__card">
              <p className="title">Email</p>
              <p>{profile?.email}</p>
              <button className="personal-details-link" onClick={() => setEmailModalShow(true)}>
                Change Email
                <LazyLoadImage src={EditIcon} alt="" className="edit" />
              </button>
            </div>
            <div className="personal-details__card">
              <p className="title">Password</p>
              <p>********</p>
              <button className="personal-details-link" onClick={() => setPasswordShow(true)}>
                Change Password
                <LazyLoadImage src={EditIcon} alt="" className="edit" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal show={passwordShow} setShow={setPasswordShow} />
      <ChangeEmailModal show={emailModalShow} setShow={setEmailModalShow} />
    </div>
  );
};

export default PersonDetails;
