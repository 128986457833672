import React, { useContext, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { AppContext } from '../../../../appContext';
import _ from 'lodash';
import { ADMIN_INVITES_ROUTE_PATHS } from '../../../../utils/constants';

const Navigation = ({ id }) => {
  const params = useParams();
  const { isUserAdmin, isUserMainAdmin } = useContext(AppContext);

  const navigation = useMemo(() => {
    const SUPER_ADMIN_ROUTE_PATHS = [
      {
        url: `/white-label/${id}/employees`,
        label: 'Employees',
        exact: true,
      },
      {
        url: `/white-label/${id}/employees/disabled`,
        label: 'Banned Employees',
      },

      {
        url: `/white-label/${id}/employees/invites`,
        label: 'Invites',
      },
    ];

    return _.isEmpty(params) && (isUserAdmin || isUserMainAdmin) ? ADMIN_INVITES_ROUTE_PATHS : SUPER_ADMIN_ROUTE_PATHS;
  }, [params, id, isUserAdmin, isUserMainAdmin]);

  return (
    <nav className={`white-label__nav`}>
      {navigation.map((route, index) => {
        return (
          <NavLink
            className={`white-label__nav-link `}
            activeClassName="white-label__nav-link--active"
            to={route.url}
            exact={route.exact}
            style={{ zIndex: navigation.length - index }}
            key={index}
          >
            {route.label}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default Navigation;
