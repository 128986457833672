import React, { useState } from 'react';
import MasterCard from '../../../../assets/payment/master-card.svg';
import Visa from '../../../../assets/payment/visa.svg';
import Discover from '../../../../assets/payment/discover.svg';
import Amex from '../../../../assets/payment/amex.svg';
import arrowRight from '../../../../assets/account/arrow-button.svg';
import { loadStripe } from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentMethod } from '../../../../store/actions/paymentActions';
import { showNotification } from '../../../../App';
import { Spin } from 'antd';
import history from '../../../../utils/history';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.scss';

const Form = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.payment.error);
  const paymentType = [MasterCard, Visa, Discover, Amex];
  const stripe = useStripe();
  const elements = useElements();
  const [cardHolderName, setCardHolderName] = useState('');

  const option = {
    style: {
      base: {
        fontSize: '14px',
        color: 'var(--text-color)',
        background: 'var(--main-color)',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#f80000',
      },
    },
  };

  const savePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) showNotification('error', 'Error Stripe', 'Error');
    else {
      const id = await dispatch(
        createPaymentMethod({
          token: result.token.id,
          cardHolderName,
          savePaymentDetails: true,
        })
      );

      history.push(`/profile/billing-edit/${id}`);
    }
  };

  return (
    <div className="edit-payment">
      <div className="edit-header">
        <div className="edit-header-nav">
          <button>Payment Detials</button>
          <p>Billing Address</p>
        </div>
      </div>
      <form className="edit-payment-body m-b-25">
        <label className="edit-payment-label" style={{ minWidth: '100%' }}>
          <span>Cardholder Name</span>
          <input
            className="edit-payment-field p-b-0"
            placeholder="John Doe"
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
            style={{ borderColor: error ? '#FF4C4C' : null }}
            required
          />
        </label>
        <div className="stripe-block">
          <label className="edit-payment-label m-r-15">
            <span>Card Number </span>
            <CardNumberElement className="edit-payment-field p-t-18" options={option} placeholder="xxxx xxxx xxxx 4242" />
          </label>
          <label className="edit-payment-label m-r-15">
            <span> Exp. Date </span>
            <CardExpiryElement className="edit-payment-field p-t-18" options={option} placeholder="Custom CVC placeholder" />
          </label>
          <label className="edit-payment-label">
            <span> CVC Code </span>
            <CardCvcElement className="edit-payment-field p-t-18" options={option} />
          </label>
        </div>
        <div className="save-payment">
          <div className="flex flex-align-items-center">
            {paymentType.map((icon, index) => (
              <LazyLoadImage key={index} src={icon} alt="payment" style={{ width: '75px', height: '75px', marginLeft: '10px' }} />
            ))}
          </div>
        </div>
      </form>
      <div className="border" />

      <div className="edit-payment-footer">
        <Link to="billing">Back</Link>
        <button onClick={savePayment}>
          Next <LazyLoadImage src={arrowRight} alt="" />
        </button>
      </div>
    </div>
  );
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CreatePaymentMethods = () => {
  const loading = useSelector((state) => state.payment.loading);

  return (
    <Spin spinning={loading} size={'large'}>
      <Elements stripe={stripePromise}>
        <Form />
      </Elements>
    </Spin>
  );
};

export default CreatePaymentMethods;
