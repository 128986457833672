import classnames from 'classnames';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InstagramIcon from '../../assets/auth/social-insta.svg';

const PeopleCard = ({ name, surname, role, desciption, imgUrl, instaUrl, followName }) => {
  return (
    <div className="people-cards__item">
      <div className="user__avatar__container">
        <p className="user__avatar">
          <img src={imgUrl} alt="user" />
        </p>
      </div>
      <div className="text__information">
        <span className="name__text">
          <b>{name}</b> {surname}
        </span>
        <div className="text__container">
          <p className="role__text">{role}</p>
          <p className="description__text">{desciption}</p>
        </div>
      </div>
      <a href={instaUrl} target="_blank" rel="noreferrer">
        <button className={classnames('instagramm__button', { 'joseph-btn': followName === 'joseph' })} type="">
          <LazyLoadImage className="instagramm__button__icon" src={InstagramIcon} alt="instagram" />
          Follow <b>{followName}</b>
        </button>
      </a>
    </div>
  );
};

export default PeopleCard;
