import React from 'react';
import Callendly from './Callendly';
import { Modal } from 'antd';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import './styles.scss';

const CallendlyList = ({ show, setShow }) => {
  const users = [
    { id: 1, url: 'https://calendly.com/skipcloudweb/30min', name: 'Cory Reed' },
    { id: 2, url: 'https://calendly.com/skipcloudweb/30min', name: 'Cory Reed' },
    { id: 3, url: 'https://calendly.com/skipcloudweb/30min', name: 'Cory Reed' },
  ];

  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';

  return (
    <Modal
      bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px' }}
      visible={show}
      onCancel={() => setShow(false)}
      footer={null}
      width={modalWidth}
      className="modal-callendly-cards"
    >
      <h1>Representatives</h1>
      <div className="modal-callendly-cards__body">
        {users.map((user) => (
          <Callendly user={user} key={user.id} setParentModal={setShow} />
        ))}
      </div>
    </Modal>
  );
};

export default CallendlyList;
