import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover, Spin } from 'antd';
import {
  addAdminRole,
  addBasicRole,
  addMainAdminRole,
  addSkips,
  banUser,
  changeUserPassword,
  getUsersList,
  removeAdminRole,
  removeBasicRole,
  removeMainAdminRole,
  resetUserPassword,
  unbanUser,
  verifyUser,
} from '../../../../store/actions/adminActions';
import { PaginationPanelComponent } from '../../../../Components/common/PaginationPanel/paginationPanel';
import { ITEMS_PER_PAGE, USER_ROLES } from '../../../../utils/constants';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { apiURL } from '../../../../utils/config';
import history from '../../../../utils/history';
import { useParams, useRouteMatch } from 'react-router-dom';
import { roleFiltered } from '../../../../utils/functions';
import { AppContext } from '../../../../appContext';
import { loginAsUser } from '../../../../store/actions/userActions';
import './styles.scss';
import { showNotification } from '../../../../App';
import TextField from '../../../../Components/common/TextField/TextField';
import _ from 'lodash';
import NoResult from '../../../../Components/common/NoResult/NoResult';

const UsersListComponent = () => {
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state?.admin.users);
  const pageCount = useSelector((state) => state?.admin.pages);

  const { whiteLabelId, isUserAdmin, isUserSuperAdmin, isUserMainAdmin } = useContext(AppContext);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;
  const [isPending, setIsPending] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);

  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [firstPassword, setFirstPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');
  const [addSkipsForm, setAddSkipsForm] = useState(false);
  const [skipsCount, setSkipsCount] = useState(0);

  const actionApprove = useMemo(() => {
    return (isUserMainAdmin && !anotherWL) || isUserSuperAdmin || isUserAdmin;
  }, [isUserSuperAdmin, isUserAdmin, isUserMainAdmin, anotherWL]);

  const updatePassword = async (wlID, userId) => {
    const passwordData = {
      first: firstPassword,
      second: secondPassword,
    };
    if (firstPassword === secondPassword) {
      const response = await dispatch(changeUserPassword(wlID, userId, { password: passwordData }));
      if (response) {
        setFirstPassword('');
        setSecondPassword('');
        setShowChangePasswordForm(false);
      }
    } else {
      showNotification('error', 'Passwords do not match', 'Error');
    }
  };

  const addSkipsHandler = async (wlID, userId) => {
    const response = await dispatch(addSkips(wlID, userId, { skips: skipsCount }));
    if (response) {
      setAddSkipsForm(false);
      setSkipsCount(0);

      setIsPending(true);
      if (currentWLId) {
        dispatch(getUsersList(currentWLId, paginationPage)).finally(() => {
          setIsPending(false);
        });
      }
    }
  };

  const content = (userId, userRole, isVerified, wlURL) => {
    return (
      <div className="users-list__popover">
        {!showChangePasswordForm && !addSkipsForm && (
          <>
            {(isUserAdmin || (isUserMainAdmin && !anotherWL) || isUserSuperAdmin) && (
              <>
                <button onClick={() => dispatch(banUser(currentWLId, userId))}>Ban user</button>
                <button onClick={() => dispatch(unbanUser(currentWLId, userId))}>Unban user</button>
                <button onClick={() => dispatch(resetUserPassword(currentWLId, userId))}>Reset password</button>
                {!isVerified && <button onClick={() => dispatch(verifyUser(currentWLId, userId))}>Verify User</button>}
              </>
            )}
            {isUserSuperAdmin && (
              <>
                {!userRole.includes(USER_ROLES.basic) && <button onClick={() => dispatch(addBasicRole(currentWLId, userId))}>set basic admin</button>}
                {userRole.includes(USER_ROLES.basic) && <button onClick={() => dispatch(removeBasicRole(currentWLId, userId))}>remove basic admin</button>}
                {!userRole.includes(USER_ROLES.admin) && <button onClick={() => dispatch(addAdminRole(currentWLId, userId))}>set admin</button>}
                {userRole.includes(USER_ROLES.admin) && <button onClick={() => dispatch(removeAdminRole(currentWLId, userId))}>remove admin</button>}
                <button onClick={() => dispatch(loginAsUser(currentWLId, userId, wlURL))}>Login as User</button>
              </>
            )}
            {isUserSuperAdmin && Number(currentWLId) === 1 && (
              <>
                {!userRole.includes(USER_ROLES.main) && <button onClick={() => dispatch(addMainAdminRole(currentWLId, userId))}>Set Main Admin</button>}
                {userRole.includes(USER_ROLES.main) && <button onClick={() => dispatch(removeMainAdminRole(currentWLId, userId))}>Remove Main Admin</button>}
              </>
            )}
            {isUserSuperAdmin && <button onClick={() => setShowChangePasswordForm((prev) => !prev)}>change password</button>}
            {isUserSuperAdmin && <button onClick={() => setAddSkipsForm((prev) => !prev)}>add skips</button>}
          </>
        )}
        {showChangePasswordForm && (
          <div className="change-password__container">
            <TextField type="password" width={'450px'} placeholder="password" value={firstPassword} setState={setFirstPassword} />
            <TextField type="password" width={'450px'} placeholder="confirm password" value={secondPassword} setState={setSecondPassword} />
            <div className="m-t-15 flex flex-align-items-center">
              <button
                style={{ width: '50%' }}
                className="m-r-15"
                onClick={() => {
                  setShowChangePasswordForm((prev) => !prev);
                  setFirstPassword('');
                  setSecondPassword('');
                }}
              >
                Cancel
              </button>
              <button style={{ width: '50%' }} className="m-r-15" onClick={() => updatePassword(currentWLId, userId)}>
                Update
              </button>
            </div>
          </div>
        )}

        {addSkipsForm && (
          <div className="flex flex-align-items-center">
            <TextField width={'250px'} placeholder="Skips" value={skipsCount} setState={setSkipsCount} className="m-r-10" />
            <button
              className="m-r-5"
              onClick={() => {
                setSkipsCount(0);
                setAddSkipsForm((prev) => !prev);
              }}
            >
              cancel
            </button>
            <button onClick={() => addSkipsHandler(currentWLId, userId)}>save</button>
          </div>
        )}
      </div>
    );
  };

  const historyPushHandler = (id) => {
    history.push(`${url}/statistic/${id}`);
  };

  useEffect(() => {
    setIsPending(true);
    if (currentWLId) {
      dispatch(getUsersList(currentWLId, paginationPage)).finally(() => {
        setIsPending(false);
      });
    }
  }, [dispatch, paginationPage, currentWLId]);
  return (
    <Spin spinning={isPending} size={'large'}>
      <div className="users-list__container">
        {!isPending && (
          <>
            {_.isEmpty(usersList) ? (
              <NoResult text="User list is empty" />
            ) : (
              <ul className="users-list">
                <li className="users-list__item users-list__item--heading">
                  <h3>User</h3>
                  <h3>Email</h3>
                  <h3>Created at</h3>
                  <h3>Skips</h3>
                  <h3>Hits</h3>
                  <h3>Role</h3>
                  <h3>Status</h3>
                  {actionApprove && <h3>Actions</h3>}
                </li>
                {usersList.map((user, index) => {
                  return (
                    <li className="users-list__item" key={index}>
                      <span onClick={() => historyPushHandler(user?.id)} className="historyPush__link">
                        {user?.avatar ? (
                          <Avatar src={user?.avatar.includes('http') ? user?.avatar : apiURL + '/uploads/avatars/' + user?.avatar} />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )}
                        {`${user?.first_name} ${user?.last_name}`}
                      </span>
                      <span>{user.email}</span>
                      <span>{user?.created_at}</span>
                      <span>{user?.skips}</span>
                      <span>{user?.total_hits}</span>
                      <span>{user?.roles.length > 1 ? roleFiltered(user?.roles).join(', ') : roleFiltered(user?.roles).join('')}</span>
                      <span className="users-list__item-status">
                        {user?.deleted_at ? 'Banned' : 'Active'}
                        {user?.deleted_at && <span>{`${user?.deleted_at}`}</span>}
                      </span>
                      {actionApprove && (
                        <span>
                          <Popover
                            placement="bottomLeft"
                            title={showChangePasswordForm ? 'Change Password' : 'Actions'}
                            content={content(user.id, user?.roles, user?.is_verified, user?.user_domain)}
                            trigger="click"
                          >
                            <MenuOutlined />
                          </Popover>
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </div>
      <PaginationPanelComponent
        pagintationPage={paginationPage}
        setPaginationPage={setPaginationPage}
        totalCount={pageCount * ITEMS_PER_PAGE}
        itemsPerPage={ITEMS_PER_PAGE}
      />
    </Spin>
  );
};

export default UsersListComponent;
