import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/localStorage';
import userReducer from './reducers/userReducer';
import axiosApi from '../utils/axios';
import fileReducer from './reducers/fileReducer';
import processReducer from './reducers/processReducer';
import paymentReducer from './reducers/paymentReducer';
import configReducer from './reducers/configReducer';
import adminReducer from './reducers/adminReducer';
import landingReducer from './reducers/landingReducer';
import { logoutUser } from './actions/userActions';
import whiteLabelReducer from './reducers/whiteLabelReducer';
import invitesReducer from './reducers/invitesReducer';

const rootReducer = combineReducers({
  user: userReducer,
  files: fileReducer,
  process: processReducer,
  payment: paymentReducer,
  config: configReducer,
  admin: adminReducer,
  landing: landingReducer,
  whiteLabel: whiteLabelReducer,
  invites: invitesReducer,
});

const preloadedState = loadFromLocalStorage('users') || {};
const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  saveToLocalStorage('users', {
    user: store.getState()?.user,
  });
});

axiosApi.interceptors.request.use((config) => {
  if (config.url !== '/' || config.url !== '/register' || config.url !== '/reset-password' || config.url !== '/sign-up') {
    config.headers['Authorization'] = store.getState()?.user.user && `Bearer ${store.getState()?.user.user.token}`;
  }
  return config;
});

axiosApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    if (
      error?.response?.data?.errors?.message === 'Invalid JWT Token' ||
      error?.response?.data?.errors?.message === 'Expired JWT Token' ||
      (error?.response?.status === 401 && error.config)
    ) {
      store.dispatch(logoutUser());
      window.location.reload();
    }

    throw error;
  }
);

export default store;
