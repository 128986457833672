import React, { useEffect } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, userProfile } from '../../../store/actions/userActions';
import { apiURL } from '../../../utils/config';
import NoAvatar from '../../../assets/UI/no_avatar.png';
import { sliceString } from '../../../utils/functions';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import HeaderSvg from '../../SVG-Icon/HeaderSVG';
import history from '../../../utils/history';
import './styles.scss';

const Header = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile);
  const width = useResizeWindows();

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  return (
    <HeaderSvg className="layout__header">
      <div className="layout__header-inner">
        <div className="layout__header-user">
          <Link to="/profile" draggable={false}>
            <Avatar
              size={55}
              src={profile?.social ? profile?.avatar : profile?.avatar ? apiURL + '/uploads/avatars/' + profile?.avatar : NoAvatar}
              draggable={false}
            />
          </Link>
          <div>
            {profile && (
              <p>
                {sliceString(width, profile?.first_name, 20, 10, 10)} {sliceString(width, profile?.last_name, 20, 10, 10)}
              </p>
            )}
            <p className="layout__header-logout" onClick={() => dispatch(logoutUser())}>
              Log out
            </p>
          </div>
        </div>
        <p className="layout__header-credits" onClick={() => history.push('/add-more-skips')}>
          {new Intl.NumberFormat('en').format(profile?.skips) || 0} SKIPS
        </p>
      </div>
    </HeaderSvg>
  );
};

export default Header;
