import React, { useState } from 'react';
import { addCertificate, addImagesWL, createWL } from '../../../store/actions/whiteLabelActions';
import { useDispatch, useSelector } from 'react-redux';
import { InputNumber, Spin } from 'antd';
import AdminFileInput from '../AdminFileInput/AdminFileInput';
import GradientPicker from '../GradientPicker/GradientPicker';
import ColorPicker from '../ColorPicker/ColorPicker';
import WLField from '../FieldWL/WLField';
import Helper from '../Helper/Helper';
import {
  adminEmailText,
  contractRate,
  domainText,
  facebookKeyText,
  facebookText,
  googleKeyText,
  instagramText,
  jivoChatText,
  phoneText,
  whiteLabelNameText,
  youtubeText,
  sslCertificate,
  sslCertificateKey,
} from '../../../utils/white-label-annotations';
import './styles.scss';
import { showNotification } from '../../../App';

import MainGradient1 from '../../../assets/Example/main-gradient-1.png';
import MainGradient2 from '../../../assets/Example/main-gradient-2.png';
import MainGradient3 from '../../../assets/Example/main-gradient-3.png';

import ActionGradient1 from '../../../assets/Example/action-gradient-1.png';
import ActionGradient2 from '../../../assets/Example/action-gradient-2.png';
import HeaderInner from '../../../assets/Example/Header-inner-1.png';
import HeaderSkips from '../../../assets/Example/header-skips.png';

import MainColor1 from '../../../assets/Example/main-color.png';
import MainColor2 from '../../../assets/Example/main-color-2.png';

import MainColor1Hover from '../../../assets/Example/main-color-hover.png';
import MainColor2Hover from '../../../assets/Example/main-color-hover-2.png';

import TextColor1 from '../../../assets/Example/text-color-1.png';
import TextColor2 from '../../../assets/Example/text-color-2.png';
import TextColor3 from '../../../assets/Example/text-color-3.png';
import TextColor4 from '../../../assets/Example/text-color-4.png';

import ContentBg1 from '../../../assets/Example/content-bg-1.png';
import ContentBg2 from '../../../assets/Example/content-bg-2.png';
import ContentBg3 from '../../../assets/Example/content-bg-3.png';

import TableRow from '../../../assets/Example/table-row.png';
import TableRow2 from '../../../assets/Example/table-row-1.png';

import ActionColor from '../../../assets/Example/action-color-1.png';
import NavColor from '../../../assets/Example/navigation-color.png';
import SslField from '../SSLField/SSLField';
import history from '../../../utils/history';

const mainGradientImages = [MainGradient1, MainGradient2, MainGradient3];
const actionGradientImages = [ActionGradient1, ActionGradient2];
const headerInnerImages = [HeaderInner];
const headerSkipsImages = [HeaderSkips];
const mainColorImages = [MainColor1, MainColor2];
const mainHoverImages = [MainColor1Hover, MainColor2Hover];
const contentBgImages = [ContentBg1, ContentBg2, ContentBg3];
const textColorImages = [TextColor1, TextColor2, TextColor3, TextColor4];
const tableRowImages = [TableRow, TableRow2];
const actionColorImages = [ActionColor];
const navColorImages = [NavColor];
const navColorHoverImages = [NavColor];

const CreateWl = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.whiteLabel.loading);

  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [contract, setContract] = useState('');
  const [name, setName] = useState('');
  const [facebook, setFacebook] = useState('');
  const [google, setGoogle] = useState('');
  const [jivoChat, setJivoChat] = useState('');

  const [youtube, setYoutube] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [phone, setPhone] = useState('');

  const [gradient_1, setGradient_1] = useState('#000');
  const [gradient_2, setGradient_2] = useState('#000');
  const [gradient_3, setGradient_3] = useState('#000');

  const [header_inner_1, setHeader_inner_1] = useState('#000');
  const [header_inner_2, setHeader_inner_2] = useState('#000');
  const [header_inner_3, setHeader_inner_3] = useState('#000');

  const [header_skips_1, setHeader_skips_1] = useState('#000');
  const [header_skips_2, setHeader_skips_2] = useState('#000');
  const [header_skips_3, setHeader_skips_3] = useState('#000');

  const [action_gradient_1, setAction_gradient_1] = useState('#000');
  const [action_gradient_2, setAction_gradient_2] = useState('#000');
  const [action_gradient_3, setAction_gradient_3] = useState('#000');

  const [mainColor, setMainColor] = useState('#000');
  const [mainHoverColor, setMainHoverColor] = useState('#000');

  const [contentBg, setContentBg] = useState('#000');
  const [textColor, setTextColor] = useState('#000');

  const [tableRow, setTableRow] = useState('#000');
  const [actionColor, setActionColor] = useState('#000');

  const [navColor, setNavColor] = useState('#000');
  const [navColorActive, setNavColorActive] = useState('#000');

  const [logo1, setLogo1] = useState('');
  const [logo2, setLogo2] = useState('');
  const [logo3, setLogo3] = useState('');
  const [background, setBackground] = useState('');
  const [sidebarLogo, setSidebarLogo] = useState('');
  const [sidebarLogoCollapsed, setSidebarLogoCollapsed] = useState('');
  const [authBackground, setAuthBackground] = useState('');
  const [authBackgroundInner, setAuthBackgroundInner] = useState('');

  const [certificateSSL, setCertificateSSL] = useState('');
  const [certificateKey, setCertificateKey] = useState('');

  const submitHandler = async () => {
    if (!domain.includes('https://')) {
      showNotification('error', 'Your domain does not contain "https://"', 'Error');
    } else if (domain.slice(-1) === '/') {
      showNotification('error', 'Your domain must not end with "/"', 'Error');
    } else {
      const id = await dispatch(
        createWL(
          {
            name,
            domain,
            contract_rate: contract,
            email,
            fb_auth_key: facebook,
            google_auth_key: google,
            instagram,
            youtube,
            facebook: facebookUrl,
            jivochat_url: jivoChat,
            company_phone: phone,
          },
          {
            main_color: mainColor,
            main_color_hover: mainHoverColor,
            action_color: actionColor,
            gradient_1,
            gradient_2,
            gradient_3,
            content_bg: contentBg,
            tr_bg: tableRow,
            text_color: textColor,
            header_inner_gradient_1: header_inner_1,
            header_inner_gradient_2: header_inner_2,
            header_inner_gradient_3: header_inner_3,
            header_skips_gradient_1: header_skips_1,
            header_skips_gradient_2: header_skips_2,
            header_skips_gradient_3: header_skips_3,
            action_gradient_1,
            action_gradient_2,
            action_gradient_3,
            navigation_text: navColor,
            navigation_text_active: navColorActive,
          }
        )
      );
      await dispatch(addImagesWL(logo1, id));
      await dispatch(addImagesWL(logo2, id));
      await dispatch(addImagesWL(logo3, id));
      await dispatch(addImagesWL(background, id));
      await dispatch(addImagesWL(sidebarLogo, id));
      await dispatch(addImagesWL(sidebarLogoCollapsed, id));
      await dispatch(addImagesWL(authBackground, id));
      await dispatch(addImagesWL(authBackgroundInner, id));

      await dispatch(addCertificate({ certificate: certificateSSL.trim(), cert_key: certificateKey.trim() }, id));
      setDomain('');
      setEmail('');
      setFacebook('');
      setGoogle('');
      setName('');
      setContract('');
      setGradient_1('#000');
      setGradient_2('#000');
      setGradient_3('#000');
      setMainColor('#000');
      setMainHoverColor('#000');
      setTableRow('#000');
      setTextColor('#000');
      setActionColor('#000');
      setContentBg('#000');
      setNavColorActive('#000');
      setNavColor('#000');

      setHeader_inner_1('#000');
      setHeader_inner_2('#000');
      setHeader_inner_3('#000');

      setHeader_skips_1('#000');
      setHeader_skips_2('#000');
      setHeader_skips_3('#000');

      setAction_gradient_1('#000');
      setAction_gradient_2('#000');
      setAction_gradient_3('#000');
      history.goBack();
    }
  };

  return (
    <div className="create-white-label">
      <Spin spinning={loading} size="large">
        <div className="white-label__fields m-b-20">
          <WLField text={domainText} placeholder="White Label Domain" value={domain} onChange={(e) => setDomain(e.target.value)} />
          <WLField text={adminEmailText} placeholder="Admin User Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <WLField text={whiteLabelNameText} placeholder="White Label Name" value={name} onChange={(e) => setName(e.target.value)} />
          <label className="white-label-label">
            <p>Contract Rate</p>
            <div className="white-label-label__inner">
              <InputNumber
                placeholder="Contract Rate"
                onChange={(value) => setContract(value)}
                className="white-label-field"
                value={contract}
                min={'0.03'}
                max="10"
                stringMode
                step="0.01"
              />
              <Helper title="Contract Rate" text={contractRate} tooltipWidth="100%" />
            </div>
          </label>
          <WLField text={facebookKeyText} placeholder="Facebook Key" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
          <WLField text={googleKeyText} placeholder="Google Key" value={google} onChange={(e) => setGoogle(e.target.value)} />
          <WLField text={jivoChatText} placeholder="Jivochat URL" value={jivoChat} onChange={(e) => setJivoChat(e.target.value)} />
          <WLField text={youtubeText} placeholder="You Tube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />
          <WLField text={instagramText} placeholder="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
          <WLField text={facebookText} placeholder="Facebook" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} />
          <WLField text={phoneText} placeholder="Phone Company" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div className="white-label-files m-b-20">
          <AdminFileInput type={0} value={logo1} className="white-label-file" text="Main Logo" onChange={(value) => setLogo1(value)} />
          <AdminFileInput type={1} value={logo2} className="white-label-file" text="Sub Logo" onChange={(value) => setLogo2(value)} />
          <AdminFileInput type={2} value={logo3} className="white-label-file" text="Domain Logo" onChange={(value) => setLogo3(value)} />
          <AdminFileInput type={3} value={background} className="white-label-file" text="Background" onChange={(value) => setBackground(value)} />

          <AdminFileInput id={4} type={4} className="white-label-file" text="Sidebar Logo" onChange={(value) => setSidebarLogo(value)} value={sidebarLogo} />
          <AdminFileInput
            id={5}
            type={5}
            className="white-label-file"
            text="Sidebar Logo Collapsed"
            onChange={(value) => setSidebarLogoCollapsed(value)}
            value={sidebarLogoCollapsed}
          />
          <AdminFileInput
            id={6}
            type={6}
            className="white-label-file"
            text="Auth Background"
            onChange={(value) => setAuthBackground(value)}
            value={authBackground}
          />
          <AdminFileInput
            id={7}
            type={7}
            className="white-label-file"
            text="Auth Background Inner"
            onChange={(value) => setAuthBackgroundInner(value)}
            value={authBackgroundInner}
          />
        </div>
        <div className="create-white-label__body">
          <GradientPicker
            title="Main Gradient"
            gradientFirst={gradient_1}
            gradientLast={gradient_3}
            gradientSecond={gradient_2}
            setGradientFirst={setGradient_1}
            setGradientSecond={setGradient_2}
            setGradientLast={setGradient_3}
            images={mainGradientImages}
            text={whiteLabelNameText}
          />
          <GradientPicker
            title="Action Gradient"
            gradientFirst={action_gradient_1}
            gradientLast={action_gradient_3}
            gradientSecond={action_gradient_2}
            setGradientFirst={setAction_gradient_1}
            setGradientSecond={setAction_gradient_2}
            setGradientLast={setAction_gradient_3}
            images={actionGradientImages}
          />
          <GradientPicker
            title="Header Inner"
            gradientFirst={header_inner_1}
            gradientLast={header_inner_3}
            gradientSecond={header_inner_2}
            setGradientFirst={setHeader_inner_1}
            setGradientSecond={setHeader_inner_2}
            setGradientLast={setHeader_inner_3}
            images={headerInnerImages}
          />
          <GradientPicker
            title="Header Skips"
            gradientFirst={header_skips_1}
            gradientLast={header_skips_3}
            gradientSecond={header_skips_2}
            setGradientFirst={setHeader_skips_1}
            setGradientSecond={setHeader_skips_2}
            setGradientLast={setHeader_skips_3}
            images={headerSkipsImages}
          />
          <ColorPicker images={mainColorImages} title="Main Color" color={mainColor} setColor={setMainColor} />
          <ColorPicker images={mainHoverImages} title="Main Hover Color" color={mainHoverColor} setColor={setMainHoverColor} />
          <ColorPicker images={contentBgImages} title="Content Background" color={contentBg} setColor={setContentBg} />
          <ColorPicker images={textColorImages} title="Text Color" color={textColor} setColor={setTextColor} />
          <ColorPicker images={tableRowImages} title="Table row background" color={tableRow} setColor={setTableRow} />
          <ColorPicker images={actionColorImages} title="Action Color" color={actionColor} setColor={setActionColor} />
          <ColorPicker images={navColorImages} title="Navigation Color" color={navColor} setColor={setNavColor} />
          <ColorPicker images={navColorHoverImages} title="Navigation Color Active" color={navColorActive} setColor={setNavColorActive} />
        </div>
        <div className="flex flex-align-items-center m-b-20 m-t-20 gap-20">
          <SslField
            toolTipText={certificateSSL}
            text={sslCertificate}
            placeholder="SSL Certificate"
            onChange={(e) => setCertificateSSL(e.target.value)}
            value={certificateSSL}
            showSSLText={true}
            sslText="CERTIFICATE"
          />

          <SslField
            toolTipText={sslCertificateKey}
            text={sslCertificateKey}
            placeholder="Key Certificate"
            onChange={(e) => setCertificateKey(e.target.value)}
            value={certificateKey}
            showSSLText={true}
            sslText="PRIVATE KEY"
          />
        </div>
        <button className="create-white-label-btn" onClick={submitHandler}>
          Create White Label
        </button>
      </Spin>
    </div>
  );
};

export default CreateWl;
