import React from 'react';
import { Link } from 'react-router-dom';
import Welcome from './sections/Welcome/Welcome';
import Video from './sections/Video/Video';
import Price from './sections/Price/Price';
import Advantages from './sections/Advantages/Advantages';
import { ReactComponent as ReactArrowSvg } from '../../assets/landing/icons/icon-arrow.svg';
import LogoIcon from '../../assets/landing/LogoFooter.svg';
import Brands from '../../assets/landing/brands.svg';
import useResizeWindows from '../../utils/hooks/useResizeWindows';
import './styles.scss';

export const SignUpLink = ({ text, className = '', onClick = null }) => {
  return (
    <Link to={'/sign-up'} className={`${className}`} onClick={onClick && onClick}>
      <span>{text}</span>
      <span>
        <ReactArrowSvg />
      </span>
    </Link>
  );
};

const LandingPageComponent = () => {
  const widthScreen = useResizeWindows();
  return (
    <div className="landing">
      <Welcome />
      <article className="landing__brand-article">
        <div className="landing__brand-list">
          <img src={Brands} alt="forbes" />
        </div>
      </article>
      <Video />
      <Advantages />
      {/*<Success />*/}
      <Price />
      {/*<AboutCompany />*/}
      {widthScreen > 500 && (
        <div className="landing__footer">
          <img src={LogoIcon} alt="" className="logo__img" />
        </div>
      )}
      {widthScreen < 500 && (
        <div className="landing__mobile__footer">
          <div className="test1"></div>
          <img src={LogoIcon} alt="" className="logo__img" />
          <div className="test2"></div>
        </div>
      )}
    </div>
  );
};

export default LandingPageComponent;
