import React from 'react';
import { Modal } from 'antd';
import './styles.scss'

const ConfirmModal = ({ show, onCancel, editHandler, text='Are you sure you want to apply the changes ?' }) => {
  return (
    <Modal visible={show} onCancel={onCancel} onOk={editHandler} footer={null} width="50vh">
      <div className="wl-modal">
        <h3>{text}</h3>
        <div className="wl-modal__buttons">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={editHandler}>Apply</button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
