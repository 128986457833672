import axiosApi from '../../utils/axios';
import {
  GET_PROCESS_FAILURE,
  GET_PROCESS_HISTORY_FAILURE,
  GET_PROCESS_HISTORY_REQUEST,
  GET_PROCESS_HISTORY_SUCCESS,
  GET_PROCESS_REQUEST,
  GET_PROCESS_SUCCESS,
  GET_SOLO_PROCESS_FAILURE,
  GET_SOLO_PROCESS_REQUEST,
  GET_SOLO_PROCESS_SUCCESS, REMOVE_IMPORT_HISTORY, REMOVE_RESULTS_LIBRARY,
} from '../actionsTypes';

const getProcessesRequest = () => ({ type: GET_PROCESS_REQUEST });
const getProcessesSuccess = (payload) => ({ type: GET_PROCESS_SUCCESS, payload });
const getProcessesFailure = (error) => ({ type: GET_PROCESS_FAILURE, error });

const getProcessesHistoryRequest = () => ({ type: GET_PROCESS_HISTORY_REQUEST });
const getProcessesHistorySuccess = (payload) => ({ type: GET_PROCESS_HISTORY_SUCCESS, payload });
const getProcessesHistoryFailure = (error) => ({ type: GET_PROCESS_HISTORY_FAILURE, error });

const getSoloProcessRequest = () => ({ type: GET_SOLO_PROCESS_REQUEST });
const getSoloProcessSuccess = (process) => ({ type: GET_SOLO_PROCESS_SUCCESS, process });
const getSoloProcessFailure = (error) => ({ type: GET_SOLO_PROCESS_FAILURE, error });

export const removeHistory = () => ({ type: REMOVE_IMPORT_HISTORY });
export const removeLibrary = () => ({ type: REMOVE_RESULTS_LIBRARY });

export const getProcessesHistory = (page) => {
  return async (dispatch) => {
    try {
      dispatch(getProcessesHistoryRequest());
      const { data } = await axiosApi.get(`/process?page=${page}&status=`);
      dispatch(getProcessesHistorySuccess(data));
    } catch (error) {
      dispatch(getProcessesHistoryFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getSoloProcess = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloProcessRequest());
      const { data } = await axiosApi.get(`/process/${id}`);
      dispatch(getSoloProcessSuccess(data.data));
    } catch (error) {
      dispatch(getSoloProcessFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getSoloBusinessProcess = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSoloProcessRequest());
      const { data } = await axiosApi.get(`/business-process/${id}`);
      dispatch(getSoloProcessSuccess(data.data));
    } catch (error) {
      dispatch(getSoloProcessFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const searchFile = (search = '', date = 3, page = 1, order = 'name', sort = false) => {
  return async (dispatch) => {
    try {
      dispatch(getProcessesRequest());
      const { data } = await axiosApi.get(`/process?page=${page}&status=3&date=${date}&name=${search}&order=${order}&sort=${sort ? 'desc' : 'asc'}`);
      dispatch(getProcessesSuccess(data));
    } catch (error) {
      dispatch(getProcessesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};

export const getBusinessProcessesHistory = (page) => {
  return async (dispatch) => {
    try {
      dispatch(getProcessesHistoryRequest());
      const { data } = await axiosApi.get(`/business-process?page=${page}&status=`);
      dispatch(getProcessesHistorySuccess(data));
    } catch (error) {
      dispatch(getProcessesHistoryFailure(error?.response?.data?.errors?.message || true));
    }
  };
};


export const searchBusinessFile = (search = '', date = 3, page = 1, order = 'name', sort = false) => {
  return async (dispatch) => {
    try {
      dispatch(getProcessesRequest());
      const { data } = await axiosApi.get(`/business-process?page=${page}&status=3&date=${date}&name=${search}&order=${order}&sort=${sort ? 'desc' : 'asc'}`);
      dispatch(getProcessesSuccess(data));
    } catch (error) {
      dispatch(getProcessesFailure(error?.response?.data?.errors?.message || true));
    }
  };
};
