import {
  GET_PROCESS_FAILURE,
  GET_PROCESS_HISTORY_FAILURE,
  GET_PROCESS_HISTORY_REQUEST,
  GET_PROCESS_HISTORY_SUCCESS,
  GET_PROCESS_REQUEST,
  GET_PROCESS_SUCCESS,
  GET_SOLO_PROCESS_FAILURE,
  GET_SOLO_PROCESS_REQUEST,
  GET_SOLO_PROCESS_SUCCESS,
  LOGOUT_USER,
  REMOVE_IMPORT_HISTORY,
  REMOVE_RESULTS_LIBRARY,
} from '../actionsTypes';

const initialState = {
  processes: [],
  process: {},
  history: [],
  history_pages: 0,
  history_current_page: 0,
  pages: 0,
  current_page: 0,
  loading: false,
  error: null,
};

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCESS_REQUEST:
      return { ...state, loading: true, error: null, process: [] };
    case GET_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        current_page: action.payload.current_page,
        processes:
          action.payload.current_page === '1'
            ? action.payload.data
            : state.current_page !== action.payload.current_page && state.current_page < action.payload.current_page
            ? [...state.processes, ...action.payload.data]
            : state.processes,
        pages: action.payload.pages,
      };
    case GET_PROCESS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_PROCESS_HISTORY_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PROCESS_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        history_current_page: action.payload.current_page,
        history:
          action.payload.current_page === '1'
            ? action.payload.data
            : state.history_current_page !== action.payload.current_page && state.history_current_page < action.payload.current_page
            ? [...state.history, ...action.payload.data]
            : state.history,
        history_pages: action.payload.pages,
      };
    case GET_PROCESS_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.error };

    case GET_SOLO_PROCESS_REQUEST:
      return { ...state, loading: true, error: null, process: {} };
    case GET_SOLO_PROCESS_SUCCESS:
      return { ...state, loading: false, process: action.process, error: null };
    case GET_SOLO_PROCESS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case REMOVE_IMPORT_HISTORY:
      return { ...state, loading: false, error: null, history_current_page: 1, history: [], history_pages: 0 };
    case REMOVE_RESULTS_LIBRARY:
      return { ...state, loading: false, error: null, current_page: 1, processes: [], pages: 0 };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default processReducer;
