import ActiveCodes from './PromoCodes/ActiveCodes';
import InactiveCodes from './PromoCodes/InactiveCodes';
import React, { useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

const PromoCodes = () => {
  const [content, setContent] = useState(0);
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const promoCodesID = anotherWL ? id : whiteLabelId;

  const renderFilesContent = (step) => {
    switch (step) {
      case 0:
        return <ActiveCodes wlId={promoCodesID} />;
      case 1:
        return <InactiveCodes wlId={promoCodesID} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="promo-type__container">
        <div className={classnames('btn', { 'active-type': content === 0 })} onClick={() => setContent(0)}>
          Active Codes
        </div>
        <div className={classnames('btn', { 'active-type': content === 1 })} onClick={() => setContent(1)}>
          Inctive Codes
        </div>
      </div>
      {renderFilesContent(content)}
    </>
  );
};

export default PromoCodes;
