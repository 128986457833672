import React, { useContext } from 'react';
import advantage from '../../../../assets/landing/icons/advantage.svg';
import { ReactComponent as ReactCheckSvg } from '../../../../assets/landing/icons/icon-check.svg';
import { SignUpLink } from '../../Landing';
import ReactPaySvg from '../../../../assets/landing/icons/icon-pay.svg';
import ReactResultSvg from '../../../../assets/landing/icons/icon-result.svg';
import ReactDataSvg from '../../../../assets/landing/icons/icon-data.svg';
import ReactInterfaceSvg from '../../../../assets/landing/icons/icon-interface.svg';
import ReactInterfaceZxc from '../../../../assets/landing/icons/line.png';
import './styles.scss';
import useResizeWindows from '../../../../utils/hooks/useResizeWindows';
import { AppContext } from '../../../../appContext';
import { toCapitalize } from '../../../../utils/functions';

const Advantages = () => {
  const width = useResizeWindows();
  const { brand } = useContext(AppContext);

  const advantages = [
    {
      title: 'PAY PER',
      subTitle: 'HIT ONLY',
      description: `Why pay for results you’re not getting? With ${toCapitalize(brand)}, you only pay for results. Save more credits and waste less money on faulty data.`,
      icon: ReactPaySvg,
    },
    {
      title: 'INSANELY FAST',
      subTitle: 'RESULTS',
      description:
        'The fastest way to get the data results you need. Within seconds of submitting your list(s), you’ll have a downloadble file with your results ready to go! ',
      icon: ReactResultSvg,
    },
    {
      title: 'HYPER-ACCURATE',
      subTitle: 'DATA SEARCH',
      description: 'With access to data on over 98% of adults in America, you can quickly find people or assets with our comprehensive database!',
      icon: ReactDataSvg,
    },
    {
      title: 'EASY-TO-USE',
      subTitle: 'INTERFACE',
      description: `With ${toCapitalize(brand)}, you can skip trace with a few simple clicks. We made it easy to navigate and quick to use! Try it for yourself!`,
      icon: ReactInterfaceSvg,
    },
  ];

  return (
    <section className="landing__advantages-section">
      <h2>EVERYTHING YOU’VE EVER LOOKED FOR IN SKIP TRACING COMPANY.</h2>
      <ul className="landing__advantages-list">
        {advantages.map((advantage, index) => {
          return (
            <li className={`landing__advantages-item`} key={index}>
              <img src={advantage.icon} alt="icon" className={index === 2 ? 'icon-map' : index === 1 ? 'icon-download' : ''} />
              <h3>
                <b>{advantage.title} </b>
                {advantage.subTitle}
              </h3>
              <p>{advantage.description}</p>
            </li>
          );
        })}
      </ul>

      <article className="landing__advantages-simple-article">
        <img src={advantage} alt="advantage" />

        <div className="landing__advantages-simple-container">
          <h3>
            WE keep YOUR SEARCH <b>simple.</b>
            {width <= 500 && <img src={ReactInterfaceZxc} alt="simple" className="simple__line" />}
          </h3>

          <ul className="landing__advantages-simple-list">
            <li className="landing__advantages-simple-item">
              <ReactCheckSvg />
              <span>Upload your excel or csv file for a Person or LLC search</span>
            </li>
            <li className="landing__advantages-simple-item">
              <ReactCheckSvg />
              <span>Match your data to {toCapitalize(brand)}’s data matching tool</span>
            </li>
            <li className="landing__advantages-simple-item">
              <ReactCheckSvg />
              <span>Submit your search and wait a few seconds</span>
            </li>
            <li className="landing__advantages-simple-item">
              <ReactCheckSvg />
              <span>Your results come back right away with super-accuracy!</span>
            </li>
          </ul>

          <SignUpLink text={'SIGN ME UP NOW!'} className="landing__advantages-link-button landing__signup-link" />
        </div>
      </article>
    </section>
  );
};

export default Advantages;
