import {
  CHANGE_PRIMARY_CARD_FAILURE,
  CHANGE_PRIMARY_CARD_REQUEST,
  CHANGE_PRIMARY_CARD_SUCCESS,
  CREATE_CARD_FAILURE,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  GET_CARD_BILLING_DETAILS_FAILURE,
  GET_CARD_BILLING_DETAILS_REQUEST,
  GET_CARD_BILLING_DETAILS_SUCCESS,
  GET_CARDS_FAILURE,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  GET_PAYMENT_FAILURE,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  GET_PRICES_FAILURE,
  GET_PRICES_REQUEST,
  GET_PRICES_SUCCESS,
  GET_PRIMARY_CARD_FAILURE,
  GET_PRIMARY_CARD_REQUEST,
  GET_PRIMARY_CARD_SUCCESS,
  LOGOUT_USER,
} from '../actionsTypes';

const initialState = {
  loading: false,
  error: null,
  prices: [],
  cards: [],
  primary: {},
  billing: {},
  paymentInfo: [],
  paymentLoading: false,
  paymentError: null,
  cardLoading: false,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PRICES_SUCCESS:
      return { ...state, loading: false, error: null, prices: action.prices };
    case GET_PRICES_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_CARDS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CARDS_SUCCESS:
      return { ...state, loading: false, error: null, cards: action.cards };
    case GET_CARDS_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CREATE_CARD_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_CARD_SUCCESS:
      return { ...state, loading: false, error: null, cards: [...state.cards, action.card] };
    case CREATE_CARD_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_PRIMARY_CARD_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PRIMARY_CARD_SUCCESS:
      return { ...state, loading: false, error: null, primary: action.card };
    case GET_PRIMARY_CARD_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CHANGE_PRIMARY_CARD_REQUEST:
      return { ...state, cardLoading: true, error: null };
    case CHANGE_PRIMARY_CARD_SUCCESS:
      return { ...state, cardLoading: false, primary: action.card, error: null };
    case CHANGE_PRIMARY_CARD_FAILURE:
      return { ...state, cardLoading: false, error: action.error };
    case GET_CARD_BILLING_DETAILS_REQUEST:
      return { ...state, loading: true, error: null, billing: {} };
    case GET_CARD_BILLING_DETAILS_SUCCESS:
      return { ...state, loading: false, billing: action.billing, error: null };
    case GET_CARD_BILLING_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.error, billing: {} };
    case GET_PAYMENT_REQUEST:
      return { ...state, paymentLoading: true };
    case GET_PAYMENT_SUCCESS:
      return { ...state, paymentLoading: false, paymentInfo: action.payments.info, pages: action.payments.pages };
    case GET_PAYMENT_FAILURE:
      return { ...state, paymentLoading: false, paymentError: action.error };
    case LOGOUT_USER:
      return initialState;
    default:
      return { ...state };
  }
};

export default paymentReducer;
