import React, { useContext } from 'react';
import Modal from 'antd/es/modal/Modal';
import './stylees.scss';
import useResizeWindows from '../../../utils/hooks/useResizeWindows';
import IconCheck from '../../SVG-Icon/IconCheck';
import { AppContext } from '../../../appContext';
import { toCapitalize } from '../../../utils/functions';

const SuccessModal = ({ show, setShow }) => {
  const width = useResizeWindows();
  const modalWidth = width <= 1300 ? '1180px' : width <= 1500 ? '95%' : '80%';
  const { brand } = useContext(AppContext);

  return (
    <Modal
      width={modalWidth}
      bodyStyle={{ width: width <= 1300 ? '1180px' : '100%', padding: '0', borderRadius: '50px' }}
      visible={show}
      onCancel={() => setShow(false)}
      className="success-ant-modal"
      footer={<></>}
    >
      <div className="success-modal">
        <div className="success-modal__body">
          <IconCheck/>
          <h1>You're All Set!</h1>
          <p>You are able to continue with your {toCapitalize(brand)} search.</p>
        </div>
        <div className="success-modal__footer">
          <button onClick={() => setShow(false)}>Continue with my Search</button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
