import { useMemo } from 'react';

export const useOptions = () => {
  return useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#898787',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#ccc',
          },
        },
        invalid: {
          color: '#f80000',
        },
      },
    }),
    []
  );
};
