import React, { useContext, useEffect, useState } from 'react';
import { banWhiteLabel, confirmWhiteLabel, deleteWhiteLabel, getAllWl, unBanWhiteLabel } from '../../../store/actions/whiteLabelActions';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../utils/history';
import { Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../appContext';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import CertificateModal from '../CertificateModal/CertificateModal';
import './styles.scss';

const ListWL = () => {
  const dispatch = useDispatch();
  const whiteLabels = useSelector((state) => state.whiteLabel.whiteLabels);
  const loading = useSelector((state) => state.whiteLabel.loading);
  const { isUserSuperAdmin, isUserMainAdmin } = useContext(AppContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState(null);

  useEffect(() => {
    dispatch(getAllWl());
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteWhiteLabel(id));
    setDeleteModal(false);
    setDeleteId(null);
  };

  const certificateHandler = (label) => {
    setLabel(label);
    setShow(true);
  };

  return (
    <Spin spinning={loading} className="p-t-10" style={{ minHeight: '500px' }} size="large">
      <ul className="white-label-list">
        <li className={`white-label-list__item ${isUserMainAdmin ? 'white-label-list__item-admin' : ''}`}>
          <h3>
            <b>Company Name</b>
          </h3>

          <h3>
            <b>Status</b>
          </h3>
          <h3>
            <b>Admin Login Email</b>
          </h3>
          <h3>
            <b>Contract Rate</b>
          </h3>
          <h3>
            <b>Certificate</b>
          </h3>
          {isUserSuperAdmin && (
            <h3>
              <b>Actions</b>
            </h3>
          )}
        </li>
        {whiteLabels.map((label) => (
          <li key={label.id} className={`white-label-list__item ${isUserMainAdmin ? 'white-label-list__item-admin' : ''}`}>
            <h3>
              <Link to={isUserMainAdmin ? `/white-label/${label.id}/users` : `/white-label/${label.id}/edit-card`}>{label.name}</Link>
            </h3>
            <h3>{label.status}</h3>
            <h3>{label.email}</h3>
            <h3>${label.contract_rate}</h3>
            <h3 className="certificate" onClick={() => certificateHandler(label)}>
              {label.cert_key ? (
                <Tooltip placement="top" title={'SSL Certificate'}>
                  <CheckCircleOutlined className="certificate-icon" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={'SSL Certificate'}>
                  <CloseCircleOutlined className="certificate-icon-error" />
                </Tooltip>
              )}
              {label.certificate ? (
                <Tooltip placement="top" title={'Certificate Key'}>
                  <CheckCircleOutlined className="certificate-icon" />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title={'SSL Certificate'}>
                  <CloseCircleOutlined className="certificate-icon-error" />
                </Tooltip>
              )}

              <p className="flex flex-align-items-center flex-justify-content-between">{label.available_until || 'Error'}</p>
            </h3>

            {isUserSuperAdmin && (
              <h3>
                {label.status !== 'done' && label.status !== 'pending' ? <button onClick={() => dispatch(confirmWhiteLabel(label.id))}>Approve</button> : null}

                {label.id !== 1 && (
                  <>
                    {label.deleted_at ? (
                      <button onClick={() => dispatch(unBanWhiteLabel(label.id))}>Enable</button>
                    ) : (
                      <button onClick={() => dispatch(banWhiteLabel(label.id))}>Disable</button>
                    )}
                  </>
                )}

                <button onClick={() => history.push(`/super-admin/white-label/edit/${label.id}`)}>Edit</button>
                {label.id !== 1 && (
                  <button
                    className="white-label__delete"
                    onClick={() => {
                      setDeleteModal(true);
                      setDeleteId(label.id);
                    }}
                  >
                    Delete
                  </button>
                )}
              </h3>
            )}
          </li>
        ))}
      </ul>
      <ConfirmModal
        show={deleteModal}
        text="Are you sure you want to remove this White Label?"
        onCancel={() => setDeleteModal(false)}
        editHandler={() => deleteHandler(deleteId)}
      />

      <CertificateModal show={show} setShow={setShow} label={label} setLabel={setLabel} />
    </Spin>
  );
};

export default ListWL;
