import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside';
import './styles.scss';

const Tooltip = ({ show, setShow, totalRecord, totalHits, index, status = '' }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => setShow(false));

  return (
    <div className={`tooltip ${!index ? 'tooltip__first' : ''}`} style={{ display: show ? 'block' : 'none' }} onClick={() => setShow(false)} ref={ref}>
      <div className="tooltip__body">
        {status === 'expired' ? (
          <div className="tooltip__item">
            <p>File Expired</p>
          </div>
        ) : (
          <>
            <div className="tooltip__item m-b-10">
              <p>Total Records Submitted</p>
              <p className="tooltip__item-total">{totalRecord}</p>
            </div>
            <div className="tooltip__item">
              <p>Total Hits</p>
              <p className="tooltip__item-total">{totalHits}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
