import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import NavigationSection from '../../Components/common/LandingHeader/NavigationSection';
import { ReactComponent as ReactAheadLogoSvg } from '../../assets/white-label/icons/icon-landing-ahead.svg';
import { SignUpLink } from '../Landing/Landing';
import FooterSection from '../../Components/common/LandingFooter/footerSection';
import { showNotification } from '../../App';
import { postContactUs } from '../../store/actions/landingActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import './style.scss';
import { AppContext } from '../../appContext';
import { toCapitalize } from '../../utils/functions';

const ContactUsPage = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.landing.loading);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const { brand } = useContext(AppContext);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    text: false,
  });

  const submitHandler = (e) => {
    e.preventDefault();

    if (name.trim().length < 3 || !(email?.includes('@') && email?.length > 3) || text.trim().length < 3) {
      setErrors({
        email: !(email?.includes('@') && email?.length > 3),
        name: name.trim().length < 3,
        text: text.trim().length < 3,
      });

      showNotification('error', 'Please fill in all required fields correctly', 'Error');
    } else {
      dispatch(postContactUs({ name, email, text }));
      setErrors({
        name: false,
        text: false,
        email: false,
      });
    }
  };

  return (
    <div className="contact">
      <NavigationSection headHeader={'Contact us now!'} />
      <section className="contact-section">
        <Spin spinning={loading} size="large">
          <form className="contact__form">
            <p>Enter your information and ask your question below and our {toCapitalize(brand)} support team will be in touch shortly.</p>
            <fieldset className="contact__form-fieldset">
              <input
                className={errors.name ? 'input-error' : ''}
                type={'text'}
                id="name"
                value={name}
                placeholder="First Name"
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className={errors.email ? 'input-error' : ''}
                type={'email'}
                id="email"
                value={email}
                placeholder="E-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                className={errors.text ? 'input-error' : ''}
                id="text"
                value={text}
                placeholder="Type your question here..."
                onChange={(e) => setText(e.target.value)}
              />
            </fieldset>
            <button className="contact__form-submit-button" type="submit" onClick={submitHandler}>
              Submit
            </button>
          </form>
        </Spin>

        <div className="contact__account-container">
          <div className="contact__account-svg-container">
            <ReactAheadLogoSvg />
          </div>
          <h4 className="contact__account-header">
            <span>Haven't used</span>
            <b>{toCapitalize(brand)}</b>
            <span>yet?</span>
          </h4>
          <SignUpLink text={`Show me what i'm missing out on!`} className="contact__login-button navigation-section__login-link-button" />
          <span>
            We'll
            <Link to={'/sign-up'} className="contact__login-link navigation-section__login-link">
              make you an account
            </Link>
            for FREE so you can check it out first!
          </span>
        </div>
      </section>
      <FooterSection />
    </div>
  );
};

export default ContactUsPage;
