import React, { useRef, useState } from 'react';
import MenuIcon from '../../../../assets/landing/menu-icon.svg';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from '../../../../utils/hooks/useOnClickOutside';
import { navigationRoutes } from '../../../../utils/constants';
import './styles.scss';

const MobileNavigation = () => {
  const [show, setShow] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShow(false));
  return (
    <div>
      {!show ? (
        <img className="mobile-menu-icon" src={MenuIcon} alt="menu" onClick={() => setShow(true)} />
      ) : (
        <div className="landing-mobile__nav" ref={ref}>
          <CloseCircleOutlined onClick={() => setShow(false)} className="mobile-menu-close" />
          <ul className="landing-mobile__nav-list">
            {navigationRoutes.map((route, index) => {
              return (
                <li className="landing-mobile__nav-item" key={index}>
                  <Link to={route.to} className="landing-mobile__nav-link">
                    {route.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileNavigation;
