import React, { useState } from 'react';
import { EyeInvisibleTwoTone, EyeTwoTone } from '@ant-design/icons';

const PasswordField = ({ value, setValue, placeholder, className, error }) => {
  const [state, setState] = useState(false);

  return (
    <label className={`label-password ${className}`}>
      <input
        style={{ border: error ? '1px solid tomato' : '' }}
        type={state ? 'text' : 'password'}
        className={`edit__field`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {state ? (
        <EyeTwoTone className="icon-eye" onClick={() => setState(!state)} />
      ) : (
        <EyeInvisibleTwoTone className="icon-eye" onClick={() => setState(!state)} />
      )}
      <span> Minimum length of 6 characters</span>
    </label>
  );
};

export default PasswordField;
