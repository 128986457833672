import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFaqs, getAllFaqs } from '../../../../store/actions/adminActions';
import { Modal, Spin } from 'antd';
import FaqsTab from './FaqsTab';
import TextField from '../../../../Components/common/TextField/TextField';
import TextArea from '../../../../Components/common/TextArea/TextArea';
import './styles.scss';
import { useParams, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import NoResult from '../../../../Components/common/NoResult/NoResult';

const Faqs = () => {
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.admin.faqs);
  const pages = useSelector((state) => state.admin.faqsPages);
  const loading = useSelector((state) => state.admin.loading);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const currentWLId = anotherWL ? id : whiteLabelId;

  useEffect(() => {
    if (currentWLId) dispatch(getAllFaqs(currentWLId));
  }, [dispatch, currentWLId]);

  const createFaqsHandler = async () => {
    const response = await dispatch(createFaqs(currentWLId, { question, answer }));
    if (response) {
      setAnswer('');
      setQuestion('');
      setShow(false);
    }
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="admin-faqs__container">
        {!loading && (
          <>
            {_.isEmpty(faqs) ? (
              <NoResult text="Faqs is empty" style={{ marginTop: '90px' }} />
            ) : (
              <ul className={`admin-faqs-list ${pages <= 1 ? 'admin-faqs-list-no-pagination' : ''}`}>
                {faqs.map((faq, index) => (
                  <FaqsTab faq={faq} key={index} index={index} wlID={currentWLId} />
                ))}
              </ul>
            )}
          </>
        )}

        <button className="admin-faqs__create" onClick={() => setShow(true)}>
          Create
        </button>
      </div>

      <Modal
        bodyStyle={{ padding: '0', borderRadius: '50px' }}
        className="faqs__modal"
        visible={show}
        onCancel={() => setShow(false)}
        footer={null}
        width={'600px'}
      >
        <TextField parentClassName="label__faqs" value={question} setState={setQuestion} label="Question" />
        <TextArea parentClassName="label__faqs" value={answer} setState={setAnswer} label="Answer" />

        <div className="button-block">
          <button onClick={() => setShow(false)}>Cancel</button>
          <button onClick={createFaqsHandler}>Submit</button>
        </div>
      </Modal>
    </Spin>
  );
};

export default Faqs;
