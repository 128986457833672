import React from 'react';

import { ReactComponent as ReactColorLogoSvg } from '../../../assets/white-label/color-logo.svg';
import './style.scss';

const FooterSection = () => {

  return (
    <section className='footer-section'>
      <footer className='footer'>
        <ReactColorLogoSvg />
      </footer>
    </section>
  );
};

export default FooterSection;
