import React, { useMemo } from 'react';
import GradientItem from './GradientItem';
import Helper from '../Helper/Helper';
import './styles.scss';

const GradientPicker = ({
  text,
  title,
  gradientFirst,
  setGradientFirst,
  gradientSecond,
  setGradientSecond,
  gradientLast,
  setGradientLast,
  disabled,
  images,
}) => {
  const gradient = useMemo(() => {
    return `linear-gradient(to right, ${gradientFirst} 0%, ${gradientSecond} 50%, ${gradientLast} 100%)`;
  }, [gradientFirst, gradientSecond, gradientLast]);

  return (
    <div className="gradient-picker__inner">
      <div className="gradient-picker" style={{ background: gradient }}>
        <h2 className="gradient-article">{title}</h2>
        <GradientItem className="gradient-picker-item gradient-picker-item__first" color={gradientFirst} setColor={setGradientFirst} disabled={disabled} />
        <GradientItem className="gradient-picker-item" color={gradientSecond} setColor={setGradientSecond} disabled={disabled} />
        <GradientItem className="gradient-picker-item" color={gradientLast} setColor={setGradientLast} disabled={disabled} />
      </div>

      <Helper text={text} title={title} tooltipWidth="100%" images={images} />
    </div>
  );
};

export default GradientPicker;
