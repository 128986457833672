import React from 'react';
import { showNotification } from '../../../App';

const FileInput = ({ onChange, className = 'primary-button', text = 'Add New File', multiple = true, accept }) => {
  const inputRef = React.useRef();

  const activateInput = () => {
    inputRef.current?.click();
  };

  const onFileChange = (e) => {
    if (multiple) {
      if (e.target.files.length <= 10) {
        onChange((prev) => [...prev, ...e.target.files]);
      } else showNotification('error', 'Max count is 10 files', 'Error');
    } else {
      if (e.target.files[0]) {
        onChange(e.target.files[0]);
      }
    }
  };

  return (
    <>
      <input 
        type="file"
        onChange={onFileChange}
        ref={inputRef}
        style={{ display: 'none' }}
        multiple={multiple}
        accept={accept}
      />
      <button className={`${className}`} onClick={activateInput}>
        {text}
      </button>
    </>
  );
};

export default FileInput;
