import React from 'react';

const IconCheck = () => {
  return (
    <div className="svg m-b-15">
      <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 40.6 40.6">
        <defs>
          <linearGradient id="SKIPCLOUD" x1="0.38" y1="20.3" x2="40.22" y2="20.3" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="var(--color-3)" />
            <stop offset="1" stopColor="var(--color-3)" />
          </linearGradient>
          <linearGradient id="SKIPCLOUD" x1="10.93" y1="25.02" x2="15.27" y2="25.02" xlinkHref="SKIPCLOUD" />
          <linearGradient id="SKIPCLOUD" x1="15.38" y1="21.08" x2="30.19" y2="21.08" xlinkHref="SKIPCLOUD" />
        </defs>
        <g id="Слой_2" data-name="Слой 2" stroke="#fff" strokeWidth={1}>
          <g id="Layer_1" data-name="Layer 1">
            <circle className="cls-1" cx="20.3" cy="20.3" r="19.92" />
            <line strokeWidth={0.5} fill="#fff" className="cls-2" x1="10.93" y1="21.43" x2="15.27" y2="28.61" />
            <path strokeWidth={0.5} fill="#fff" className="cls-3" d="M10.28,21.81l3.8,6.27.54.9a.75.75,0,0,0,1.3-.75L12.12,22l-.54-.9a.75.75,0,0,0-1.3.75Z" />
            <line strokeWidth={0.5} fill="#fff" className="cls-4" x1="15.38" y1="28.49" x2="30.19" y2="13.67" />
            <path strokeWidth={0.5} fill="#fff" className="cls-3" d="M15.91,29l13-13,1.84-1.84a.75.75,0,0,0-1.06-1.06l-13,13L14.85,28A.75.75,0,0,0,15.91,29Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default IconCheck;
