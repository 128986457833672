import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import useResizeWindows from '../../../../../utils/hooks/useResizeWindows';
import './styles.scss';

const PaymentFullInfo = ({ visibleModal, closeModal, id }) => {
  const paymentInfo = useSelector((state) => state?.payment?.paymentInfo);
  const width = useResizeWindows();
  const modalWidth = width <= 1270 ? '650px' : width <= 1500 ? '80%' : '70%';
  let detail = paymentInfo[id]?.payment_details;
  const summaryInformation = paymentInfo[id];
  return (
    <Modal visible={visibleModal} onCancel={closeModal} width={modalWidth} footer={null} bodyStyle={{ padding: 0 }}>
      <div className="stripe-modal">
        <div className="stripe-modal__body">
          <div className="stripe-modal__body-item">
            <div className="stripe-modal__body-item-stripe">
              <ul className="payment-info-list">
                {detail?.map((info) => {
                  const discountPercent = info?.discount_info?.percent;
                  return (
                    <li className="faq__questions-item" key={info.id}>
                      <label className="payment-info-label" htmlFor={info.id}>
                        <input id={info.id} type="checkbox" />
                        <div className="payment-info-title">Skips {info?.price_info.skips}</div>
                        <div className="payment-info-content">
                          <div className="price__info__item">
                            {discountPercent && <div className="discount__block">discounted {discountPercent}% </div>}
                            <p>Quantity</p>
                            <p className="price__info__skips"> {info?.quantity}</p>
                          </div>
                          <div className="price__info__item">
                            <p>Price per Skip</p>
                            <p className="price__info__skips"> {info?.price_info.per_hit}</p>
                          </div>

                          <div className="price__info__item">
                            <p> {discountPercent ? 'Price with discount' : 'Price'}</p>
                            {discountPercent ? (
                              <p className="price__info__skips">{(info?.price_info.price / 100) * (discountPercent / 100)}$</p>
                            ) : (
                              <p className="price__info__skips">{info?.price_info?.price / 100}$</p>
                            )}
                          </div>
                          {discountPercent && (
                            <div className="price__info__item">
                              <p>Price without discount</p>
                              <p className="price__info__skips">{info?.price_info?.price / 100}$</p>
                            </div>
                          )}
                        </div>
                        <b>+</b>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="stripe-modal__body-item">
            <div className="stripe-modal__order">
              <div className="stripe-modal__order-header">
                <h3>Payment Information </h3>
                <p>{summaryInformation?.created_at}</p>
              </div>
              <div className="stripe-modal__order-body">
                <div className="stripe-modal__order-body-total">{summaryInformation?.skips} SKIPS</div>
                <div className="m-b-40">
                  <div className="stripe-modal__order-total-item">
                    <p>SUBTOTAL</p>
                    <p>{summaryInformation?.amount / 100} $</p>
                  </div>
                </div>
                <p className="back" onClick={closeModal}>
                  Go Back
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentFullInfo;
