import React, { useCallback, useEffect, useRef } from 'react';
import { HighLight } from '../HighLight/HighLight';

const Question = ({ faq, search }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.checked = !!search;
  }, [ref, search]);

  const light = useCallback(
    (str, type) => {
      return <HighLight filter={search} str={str} type={type} />;
    },
    [search]
  );

  return (
    <li className="faqs__questions-item">
      <label className="faqs__questions-label" htmlFor={faq.id}>
        <input id={faq.id} type="checkbox" ref={ref} />
        <div className="faqs__questions-title">
          <h4>{light(faq.question, null)}</h4>
        </div>
        <div className="faqs__questions-content">
          <p>{light(faq.answer, 'answer')}</p>
        </div>
        <b>+</b>
      </label>
    </li>
  );
};

export default Question;
