export const state = {
  0: 'First Name',
  1: 'Middle Name',
  2: 'Last Name',
  3: 'Mailing Address',
  4: 'Mailing City',
  5: 'Mailing State',
  6: 'Mailing Zip Code',
};

export const stateBusiness = {
  0: 'Business Name',
  1: 'Mailing City',
  2: 'Mailing State',
};

export const dataMatchingInitial = {
  first_name: 'Select row',
  middle_name: 'Select row',
  last_name: 'Select row',
  address: 'Select row',
  city: 'Select row',
  state: 'Select row',
};

export const dataMatchingInitialBusiness = {
  business_name: 'Select row',
  city: 'Select row',
  state: 'Select row',
};

export const stateSelect = [
  { id: 0, value: 'A', disabled: false },
  { id: 1, value: 'B', disabled: false },
  { id: 2, value: 'C', disabled: false },
  { id: 3, value: 'D', disabled: false },
  { id: 4, value: 'E', disabled: false },
  { id: 5, value: 'F', disabled: false },
];

export const stateSelectBusiness = [
  { id: 0, value: 'A', disabled: false },
  { id: 1, value: 'B', disabled: false },
  { id: 2, value: 'C', disabled: false },
];

export const data_columns = ['first_name', 'middle_name', 'last_name', 'address', 'city', 'state'];
export const data_columns_business = ['business_name', 'city', 'state'];

export const months = [
  {
    id: 1,
    name: 'Last 3 Months',
    value: '3',
  },
  {
    id: 2,
    name: 'Last 6 Months',
    value: '6',
  },
  {
    id: 3,
    name: 'Last 9 Months',
    value: '9',
  },

  {
    id: 4,
    name: 'Last 12 Months',
    value: '12',
  },
];

export const sort_examples = [
  {
    id: 1,
    name: 'By name',
    value: 'name',
  },
  {
    id: 2,
    name: 'By address',
    value: 'address',
  },
  {
    id: 3,
    name: 'By phone',
    value: 'phone',
  },

  {
    id: 4,
    name: 'By email',
    value: 'email',
  },
];

export const USER_ROLES = {
  super: 'ROLE_SUPER_ADMIN',
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER',
  basic: 'ROLE_BASIC_ADMIN',
  main: 'ROLE_MAIN_ADMIN',
};

export const ERROR_MESSAGES = {
  notEnoughtSkips: 'You do not have enough skips',
};

export const SIBLING_COUNT = 1;
export const ITEMS_PER_PAGE = 20;

export const navigationRoutes = [
  {
    to: '/home',
    title: 'Home',
  },
  {
    to: '/about',
    title: 'About us',
  },
  {
    to: '/pricing',
    title: 'Pricing',
  },
  {
    to: '/faq',
    title: 'faq',
  },
  {
    to: '/login',
    title: 'Login',
  },
  {
    to: '/sign-up',
    title: 'Sign Up',
  },
];

export const ADMIN_ROUTE_PATHS = [
  {
    url: '',
    label: 'Price Cards',
    exact: true,
  },
  {
    url: '/faq',
    label: 'FAQ',
    exact: true,
  },
  {
    url: '/users',
    label: 'Users list',
    exact: true,
  },
  {
    url: '/settings',
    label: 'Settings',
    exact: true,
  },
  {
    url: '/employees',
    label: 'Employees',
    exact: true,
  },
  {
    url: '/reports',
    label: 'Reports',
    exact: true,
  },
];

export const MAIN_ADMIN_ROUTE_PATHS = [
  {
    url: '',
    label: 'Price Cards',
    exact: true,
  },
  {
    url: '/faq',
    label: 'FAQ',
    exact: true,
  },
  {
    url: '/users',
    label: 'Users list',
    exact: true,
  },
  {
    url: '/employees',
    label: 'Employees',
    exact: true,
  },
  {
    url: '/reports',
    label: 'Reports',
    exact: true,
  },
];

export const SUPER_ADMIN_ROUTE_PATHS = [
  {
    url: '',
    label: 'White Label',
    exact: true,
  },
  {
    url: '/new',
    label: 'Create White Label',
    exact: true,
  },
  {
    url: '/summary',
    label: 'Summary',
    exact: true,
  },
];

export const ADMIN_REPORTS_ROUTE_PATHS = [
  {
    url: `/admin/reports`,
    label: 'Transaction History',
    exact: true,
  },
  // {
  //   url: `/admin/reports/user-activity`,
  //   label: 'User Activity History',
  // },
  {
    url: `/admin/reports/skiptrace`,
    label: 'Skiptrace History',
  },
  {
    url: `/admin/reports/summary`,
    label: 'WL Summary',
  },
];

export const BASIC_REPORTS_ROUTE_PATHS = [
  {
    url: `/admin`,
    label: 'Transaction History',
    exact: true,
  },
  {
    url: `/admin/reports/skiptrace`,
    label: 'Skiptrace History',
  },
];

export const WHITE_LABEL_ROUTE_PATHS = [
  {
    url: `/white-label/`,
    label: 'Price Cards',
    end: '/edit-card',
  },
  {
    url: '/white-label/',
    label: 'FAQ',
    end: '/faq',
  },
  {
    url: '/white-label/',
    label: 'Users list',
    end: '/users',
  },
  {
    url: '/white-label/',
    label: 'Settings',
    end: '/settings',
  },

  {
    url: '/white-label/',
    label: 'Employees',
    end: '/employees',
  },
  {
    url: '/white-label/',
    label: 'Reports',
    end: '/reports',
  },
];

export const WHITE_LABEL_MAIN_ADMIN_ROUTE_PATHS = [
  {
    url: '/white-label/',
    label: 'Users list',
    end: '/users',
  },
  {
    url: '/white-label/',
    label: 'Reports',
    end: '/reports',
  },
];

export const ADMIN_INVITES_ROUTE_PATHS = [
  {
    url: `/admin/employees`,
    label: 'Employees',
    exact: true,
  },
  {
    url: `/admin/employees/disabled`,
    label: 'Banned Employees',
  },

  {
    url: `/admin/employees/invites`,
    label: 'Invites',
  },
];
