import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles.scss';

const TextField = ({
  antd = false,
  draggable,
  disabled,
  placeholder,
  label,
  value,
  required,
  setState,
  name,
  type = 'text',
  width = '100%',
  parentClassName,
  className,
  tooltipText = '',
  icon,
}) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className={`label ${parentClassName}`} draggable={draggable}>
      {label}
      <Tooltip placement="left" title={tooltipText} trigger="focus">
        {antd ? icon : icon ? <LazyLoadImage className={`field__icon`} src={icon} alt="icon" /> : null}
        <input
          style={{ width }}
          className={`field ${className} ${type === 'password' && hidden ? 'field__password' : ''}`}
          placeholder={placeholder}
          value={value ? value : ''}
          required={required}
          onChange={(e) => setState(e.target.value)}
          name={name}
          disabled={draggable || disabled}
          type={type === 'password' && hidden ? 'password' : 'text'}
        />
        {type === 'password' && !hidden ? (
          <div className="password__eye" onClick={() => setHidden(!hidden)}>
            <EyeOutlined />
          </div>
        ) : (
          type === 'password' &&
          hidden && (
            <div className="password__eye" onClick={() => setHidden(!hidden)}>
              <EyeInvisibleOutlined />
            </div>
          )
        )}
      </Tooltip>
    </div>
  );
};

export default TextField;
