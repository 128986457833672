import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import CheckBox from '../../../../Components/common/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined } from '@ant-design/icons';
import { editProfile, userProfile } from '../../../../store/actions/userActions';
import AccountManagmentField from '../../Components/Field/AccountManagmentField';
import { DatePicker, Spin } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './styles.scss';
import { AppContext } from '../../../../appContext';
import { toCapitalize } from '../../../../utils/functions';

const EditPersonalDetails = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.user?.profile);
  const loading = useSelector((state) => state?.user?.loading);
  const [phone, setPhone] = useState(profile ? profile.phone : '');
  const [email, setEmail] = useState(profile ? profile.email : '');
  const [firstName, setFirstName] = useState(profile ? profile?.first_name : '');
  const [lastName, setLastName] = useState(profile ? profile?.last_name : '');
  const [company, setCompany] = useState(profile ? profile?.company : '');
  const [mailing, setMailing] = useState(false);
  const [dob, setDob] = useState(profile.dob ? profile.dob : new Date());
  const [dobs, setDobs] = useState('');
  const { brand } = useContext(AppContext);

  useEffect(() => {
    dispatch(userProfile());
  }, [dispatch]);

  const editProfileHandler = () => {
    if (dobs || lastName !== profile.last_name || firstName !== profile.first_name || company !== profile.company || phone !== profile.phone) {
      dispatch(
        editProfile({
          firstName,
          lastName,
          phone,
          company,
          dob: dobs,
        })
      );
    }
  };

  const onChangeDate = (date, dateString) => {
    if (date) {
      setDob(date);
      setDobs(dateString);
    } else setDob(new Date());
  };

  return (
    <>
      <div className="edit-personal">
        <h3>Personal Details</h3>
        <div className="edit-personal__body">
          <Spin spinning={loading} size="large">
            <div className="edit-personal__body-inner">
              <AccountManagmentField placeholder="First Name" value={firstName || ''} setValue={setFirstName} />
              <AccountManagmentField placeholder="Last Name" value={lastName || ''} setValue={setLastName} />
              <div className="datepicker-block">
                <p>Birthday</p>
                <DatePicker className="datepicker" onChange={onChangeDate} value={moment(dob)} placeholder={moment(dob).format('MMMM Do YYYY')} />
              </div>
              <AccountManagmentField placeholder="Company" value={company || ''} setValue={setCompany} />
              <PhoneInput
                country="us"
                inputClass="phone-edit-field edit__field"
                containerClass="phone__field"
                placeholder="+1 (302) 246 1037"
                buttonClass="edit__field__btn"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
              <CheckBox
                checked={mailing}
                setChecked={setMailing}
                className="edit-checkbox"
                text={`I’d like to receive sms texts from ${toCapitalize(brand)} about new features and promotional opportunities!`}
              />
            </div>
          </Spin>
          <div className="edit-info">
            <h4>Log-In info</h4>
            <label>
              <input type="text" className="edit__field" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" disabled />
              <CheckCircleOutlined className="icon-check" />
              <span> If email is unavailable, try another!</span>
            </label>
          </div>
        </div>
        <div className="edit-personal__footer">
          <Link to="/profile">Back</Link>
          <button onClick={editProfileHandler}>Save Changes</button>
        </div>
      </div>
    </>
  );
};

export default EditPersonalDetails;
