import { Modal, Spin } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getWLPrices } from '../../../../store/actions/adminActions';
import DisplayedCards from '../../../../Components/DisplayedCards/DisplayedCards';
import PromoCodes from './PromoCodes';
import CreateDiscount from './PromoCodes/CreateDiscount';
import SingleCard from './SingleCard';
import { AppContext } from '../../../../appContext';
import NoResult from '../../../../Components/common/NoResult/NoResult';
import _ from 'lodash';

const EditCards = () => {
  const dispatch = useDispatch();
  const wlPrices = useSelector((state) => state?.admin.wlPrices);
  const loading = useSelector((state) => state?.admin?.wlPricesLoading);
  const [discountModal, setDiscountModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const whiteLabelId = useSelector((state) => state.config?.config?.white_label_id);
  const { url } = useRouteMatch();
  const { id } = useParams();
  const anotherWL = url.includes('white-label');
  const editPriceId = anotherWL ? id : whiteLabelId;
  const { isUserAdmin, isUserSuperAdmin, isUserMainAdmin } = useContext(AppContext);

  const haveAccess = useMemo(() => {
    return isUserSuperAdmin || isUserAdmin;
  }, [isUserSuperAdmin, isUserAdmin]);

  const haveAccessDiscountActions = useMemo(() => {
    return isUserSuperAdmin || isUserAdmin || isUserMainAdmin;
  }, [isUserSuperAdmin, isUserAdmin, isUserMainAdmin]);

  useEffect(() => {
    if (editPriceId) dispatch(getWLPrices(editPriceId));
  }, [dispatch, editPriceId]);

  return (
    <Spin spinning={loading}>
      <div className="edit__cards__container">
        <div className="action-cards__section">
          <button className="actions__btn" onClick={() => setCardModal(true)}>
            Show Cards
          </button>
          {haveAccessDiscountActions && (
            <button className="actions__btn" onClick={() => setDiscountModal(true)}>
              Create discount code
            </button>
          )}
        </div>

        <div className="pricing__cards__container">
          <div className="edit_pricing__container">
            {!loading && (
              <>
                {_.isEmpty(wlPrices) ? (
                  <NoResult text="Price list is empty" style={{ left: '30%' }} />
                ) : (
                  <>
                    <ul className="prices__cards__table">
                      <li className="prices__cards__item prices__cards__item--heading">
                        <h3>Price</h3>
                        <h3>Skips</h3>
                        <h3>Per Hit</h3>
                        <h3>Tag</h3>
                        {haveAccess && <h3>Edit</h3>}
                      </li>
                      {wlPrices.map((card, index) => {
                        return <SingleCard card={card} key={index} whiteLabelId={editPriceId} haveAccess={haveAccess} />;
                      })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="pricing__codes__container">
          <PromoCodes />
        </div>
      </div>
      <Modal
        visible={discountModal}
        onCancel={() => setDiscountModal(false)}
        footer={null}
        width={'850px'}
        destroyOnClose={true}
        className="create-discount__modal"
      >
        <CreateDiscount closeModal={() => setDiscountModal(false)} />
      </Modal>

      <Modal visible={cardModal} onCancel={() => setCardModal(false)} footer={null} width={'1260px'} destroyOnClose={true} className="show-cards__modal">
        <span className="close__btn" onClick={() => setCardModal(false)}>
          &times;
        </span>
        <div className="preview__cards modal__cards">
          {wlPrices.map((card, index) => {
            return <DisplayedCards key={index} perHit={card.per_hit} price={card.price} skips={card.skips} type={card.type} />;
          })}
        </div>
      </Modal>
    </Spin>
  );
};

export default EditCards;
