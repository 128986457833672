import { Result, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loginByToken } from '../../store/actions/userActions';
import { saveToLocalStorage } from '../../utils/localStorage';
const LoginAsUser = () => {
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    saveToLocalStorage('users', {});
    if (token) {
      dispatch(loginByToken(token));
    }
  }, [dispatch, token]);
  return (
    <div className="forbidden">
      <div className="main__spinner">
        <Spin spinning="large">
          <Result className="m-r-auto m-l-auto  m-t-auto  m-b-auto" status="404" title="Login as user" subTitle="Wait, loggining as user operation" />;
        </Spin>
      </div>
    </div>
  );
};

export default LoginAsUser;
